import { useFormContext } from 'react-hook-form';
import useAppLanguage from 'hooks/useAppLanguage';

interface SlideCheckboxProps {
  data: {
    slideTitle: string;
    title?: string;
    fieldName: string;
  };
  keyIndex?: number;
  fieldName: string;
}

const SlideCheckbox: React.FC<SlideCheckboxProps> = ({ data, keyIndex, fieldName }) => {
  // const [isCheckBoxHover, setIsCheckBoxHover] = useState(false);
  const { register } = useFormContext();
  const { translate } = useAppLanguage();

  return (
    <div className="flex mx-4 px-4 hover:bg-tail-gray hover:rounded-xl py-3" key={keyIndex}>
      <div className="flex-1 overflow-y-auto">
        <div
          className="relative text-tail-blue overflow-hidden cursor-pointer"
          // onMouseEnter={() => setIsCheckBoxHover(true)}
          //  onMouseLeave={() => setIsCheckBoxHover(false)}
        >
          <label className={`absolute ml-8 text-base text-tail-text `} htmlFor={data?.fieldName}>
            {translate(data?.slideTitle || 'Toddler care')}
          </label>
          <input
            id={data?.fieldName}
            type="checkbox"
            {...register(fieldName)}
            className="form-checkbox h-5 w-5 text-tail-blue m-1"
            value={data?.fieldName || data?.slideTitle}
          />
          <div></div>
        </div>
      </div>
      <div className="text-tail-gray-145">{translate(data?.title || '')}</div>
    </div>
  );
};

export default SlideCheckbox;
