import { Box, Center, Divider, Flex, Show } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHook';
import { ICCProvidersData, fetchCCproviders } from 'reduxApp/features/ccProviderSlice';
import { iHit } from 'types/ccProvider';
import LeftLogo from 'components/PageLayout/Header/LeftLogo';
import ChildCareProvidersList from 'components/Card/ChildCareProvidersList';
import Footer from 'components/PageLayout/Footer';
import GeoLocationWithButtons from 'components/WrapperComponents/GeoLocationWithButtons';
import MapC from 'components/Map/Map';
import { converKmToMile } from 'utils/functions';
//import CircleComponent from 'components/Map/CircleComponent';
import { GeoLocationAddress } from 'api/dataObjects/GeoLocationAddress';
import { constant } from 'constants/common';
//import { Filters } from 'api/filters/Filters';
import MarkerComponent from 'components/Map/MarkerComponent';
import providerMarkerIcon from 'assets/images/providerMarker.svg';
import MapInfoProvider from 'components/Modal/SlideFilter/MapInfoProvider';
import { ArrowsPointingInIcon, ArrowsPointingOutIcon } from '@heroicons/react/24/solid';
import LoadingSpinner from 'components/LoadingSpinner';
import { setChildCareAvailabilityFilterAction } from 'reduxApp/features/filterSlice';
import { IDomainConfig } from 'types/domain';
import { InternalLink } from 'components/Links';
import LangaugeSubMenu from 'components/PageLayout/Header/LanguageSubMenu';
import useAppLanguage from 'hooks/useAppLanguage';
//import { useColorScheme } from 'theme/chakra/colors';

const ProvidersList = () => {
  const [providerData, setProvidersData] = useState<iHit[]>();
  const [selectedSearch, setSelectedSearch] = useState<boolean>(false);
  const [providerModalData, setProviderModalData] = useState({});
  const [showProviderModal, setShowProviderModal] = useState<boolean>(false);
  const [showMapMobile, setShowMapMobile] = useState<boolean>(false);

  //const warningBg = useColorScheme('makeBackgroundAndColorsReturn', 'yellowTxtBg');

  const getCCProvidersReduxData: ICCProvidersData = useAppSelector((state) => state?.ccProviders);

  const getAddressrReduxData: GeoLocationAddress = useAppSelector((state) => state?.geo?.geoAddress);
  const getCCFFilterReduxData: any = useAppSelector((state) => state?.childCareFilter);
  const getNetworkId: IDomainConfig = useAppSelector((state) => state?.domain?.config);

  const [showWarningMsg, setShowWarningMsg] = useState<boolean>(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowWarningMsg(false);
    }, 5000);

    return () => clearTimeout(timeout);
  }, [getCCProvidersReduxData]);

  useEffect(() => {
    if (getCCProvidersReduxData.providers) {
      setProvidersData(getCCProvidersReduxData.providers);
      setShowWarningMsg(
        getCCProvidersReduxData?.distance &&
          getCCProvidersReduxData.distance.miles !== getCCProvidersReduxData.distance.submitted_miles
      );
    }
  }, [setProvidersData, getCCProvidersReduxData, selectedSearch]);

  const dispatch = useAppDispatch();
  const { translate } = useAppLanguage();
  useEffect(() => {
    const distanceFilter = { miles: getCCFFilterReduxData?.childCareFinderFilter?.distance?.max_miles };
    const address = getAddressrReduxData?.formattedAddress ? getAddressrReduxData?.formattedAddress : '';
    const filters = Object.create(getCCFFilterReduxData?.childCareFinderFilter);
    //console.log('====', filters);
    delete filters.distance;
    let payload: any = {
      network_id: getNetworkId.networkId,
      ...getCCFFilterReduxData?.childCareFinderFilter,
      distance: distanceFilter,
    };
    if (getAddressrReduxData?.coordinates?.lat && getAddressrReduxData?.coordinates?.lat !== 0) {
      payload['coordinates'] = {
        lat: getAddressrReduxData?.coordinates?.lat,
        lon: getAddressrReduxData?.coordinates?.lon,
      };
    } else {
      payload.address = address;
    }
    const callAPI = async () => {
      dispatch(fetchCCproviders(payload));
      setShowProviderModal(false);
    };
    if (getAddressrReduxData?.searchRefresh || getCCFFilterReduxData.refreshFilter) callAPI();
  }, [dispatch, getCCFFilterReduxData, getNetworkId, selectedSearch, getAddressrReduxData]);

  const center = {
    lat: getAddressrReduxData?.coordinates?.lat || constant.centerLatLng.lat,
    lng: getAddressrReduxData?.coordinates?.lon || constant.centerLatLng.lng,
  };
  const isMobile = window.innerWidth < 640;
  const icon = {
    url: providerMarkerIcon,
    scaledSize: isMobile ? new window.google.maps.Size(54.18, 67.13) : new window.google.maps.Size(54.18, 67.13),
  };

  const handleShowProviderPeekView = (id: string) => {
    document.body.style.overflow = 'hidden'; // Hide body scroll
    setShowProviderModal(!showProviderModal);
    setShowProviderModal(true);
    getCCProvidersReduxData?.providerById && setProviderModalData(getCCProvidersReduxData?.providerById[id as string]);
  };

  const [isFullScreen, setIsFullScreen] = useState<boolean>(false);

  const fullScreenMapView = () => {
    setIsFullScreen(!isFullScreen);
  };

  const [isAvailability, setIsAvailability] = useState<boolean>(
    getCCFFilterReduxData?.childCareFinderFilter?.availability?.availability || false
  );
  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;
    setIsAvailability(isChecked);
    dispatch(setChildCareAvailabilityFilterAction(JSON.stringify({ availability: isChecked })));
  };

  return (
    <>
      <div className="flex h-screen flex-col">
        <Flex p={'12px 16px'} direction={{ base: 'column', md: 'row' }}>
          <Box width={['auto', '408px']} mt={5} pl={[0, 0, 6]} pr={[0, 0, 6]} pb={[2, 2, 0]}>
            <Flex justifyContent={'space-between'}>
              <Box>
                <InternalLink to="/child-care-finder">
                  <LeftLogo />
                </InternalLink>
              </Box>
              <Show below="md">
                <LangaugeSubMenu />
              </Show>
            </Flex>
          </Box>
          <Box width={'100%'}>
            {!getCCProvidersReduxData?.loading &&
              getCCProvidersReduxData?.distance &&
              getCCProvidersReduxData.distance.miles !== getCCProvidersReduxData.distance.submitted_miles &&
              showWarningMsg && (
                <div className="p-2 rounded-xl text-sm bg-tail-yellow-warning text-left transition-all duration-500 ease-in">
                  {translate('Your search radius was expanded because there were fewer than three providers.')}
                </div>
              )}
            <GeoLocationWithButtons setSelectedSearch={setSelectedSearch} />
          </Box>
        </Flex>
        <Divider />
        <div className="flex flex-grow overflow-hidden h-full">
          {(!showMapMobile || !isMobile) && !isFullScreen && (
            /*  <div className="w-full md:w-1/2 max-h-80vh overflow-auto"> */
            <div className="flex-shrink-0 w-full md:w-1/3 sm:max-w-[410px] overflow-y-scroll">
              {getCCProvidersReduxData?.loading && (
                <div className="">
                  <LoadingSpinner />
                </div>
              )}
              {!getCCProvidersReduxData?.loading && providerData && (
                <ChildCareProvidersList providers={providerData} handleInfo={handleShowProviderPeekView} />
              )}
              {!providerData?.length && !getCCProvidersReduxData?.loading && (
                <div className="  top-1/4 relative">
                  <div className=" text-xl font-bold sm:px-8 md:px-16 ">
                    {translate(`We didn't find any programs matching your exact search criteria.`)}
                  </div>
                  <div className=" sm:px-8 md:px-16">
                    {`${translate(
                      `Try removing filters to increase results. If you still can't find any programs meeting your needs, contact`
                    )}`}{' '}
                    <b>{getNetworkId?.network?.name || 'N/A'}</b> {`${translate(`for help.`)}`}
                  </div>
                </div>
              )}
            </div>
          )}
          {(showMapMobile || !isMobile) && (
            <div className={` w-full ${isFullScreen ? 'w-full' : ' md:w-3/4'} flex-grow`}>
              <MapC
                center={center}
                zoom={11}
                markerPosition={center}
                circleCenter={center}
                onBoundsChanged={(bound) => console.log('chnage bound', bound)}
                circleRadius={converKmToMile(getCCFFilterReduxData?.childCareFinderFilter?.distance?.max_miles || 5)}
                height={'100%'}
                draggable={true}
                zoomControl={true}
              >
                {/*  <CircleComponent
                  circleCenter={center}
                  circleRadius={converKmToMile(getCCFFilterReduxData?.childCareFinderFilter?.distance?.max_miles || 5)}
                /> */}
                <MarkerComponent position={center} />
                {providerData?.map((pData) => {
                  return (
                    <MarkerComponent
                      key={pData._id}
                      uniqueKey={pData._id}
                      position={{ lat: pData._source.coordinates.lat, lng: pData._source.coordinates.lon }}
                      icon={icon}
                      handleInfo={() => handleShowProviderPeekView(pData._id)}
                    />
                  );
                })}
                <div className="absolute">
                  <div className="flex flex-col align-top justify-start py-2 px-3 gap-2 bg-white border-solid bottom-2 border-tail-gray rounded-lg shadow-sm m-3">
                    <div className="text-start">
                      {providerData?.length || 0} {translate('Result Found')}
                    </div>
                    <div className="flex justify-center gap-3">
                      <div>
                        <input
                          type="checkbox"
                          className="form-checkbox h-5 w-5 text-tail-blue"
                          onChange={handleCheckboxChange}
                          checked={isAvailability}
                        />
                      </div>
                      <div className="text-sm text-start">{translate('Only show schools with open availability')}</div>
                    </div>
                  </div>
                </div>
                {!isMobile && (
                  <div className="absolute right-0">
                    <div className="flex flex-col align-middle justify-center py-2 px-3 gap-2 bg-white border-solid bottom-2 border-tail-gray rounded-lg shadow-sm m-3">
                      {isFullScreen ? (
                        <ArrowsPointingInIcon onClick={() => fullScreenMapView()} color="black" className="w-6 h-6" />
                      ) : (
                        <ArrowsPointingOutIcon onClick={() => fullScreenMapView()} color="black" className="w-6 h-6" />
                      )}
                    </div>
                  </div>
                )}
              </MapC>
            </div>
          )}
        </div>
        <div className="block h-32 sm:h-20 "></div>
      </div>
      <Center>
        <Footer />
      </Center>
      {showProviderModal && (
        <MapInfoProvider
          isOpen={true}
          onClose={() => {
            setShowProviderModal(false);
            document.body.style.overflow = 'scroll'; // Show body scroll
          }}
          data={providerModalData}
        />
      )}
      {isMobile && (
        <div className="fixed inset-0 flex items-center justify-center pointer-events-none">
          <div className="absolute bottom-28 w-screen flex items-center justify-center pointer-events-auto">
            <div
              className={`${
                !showMapMobile ? 'text-gray-700 font-bold' : 'text-tail-blue-text'
              } bg-white border-solid border-1 w-16 h-11 border-tail-gray rounded-s-3xl p-2 shadow-md`}
              onClick={() => setShowMapMobile(false)}
            >
              List
            </div>
            <div
              className={`${
                showMapMobile ? 'text-gray-700 font-bold' : 'text-tail-blue-text'
              } bg-white border-solid border-1 w-16 h-11 border-tail-gray rounded-e-3xl p-2 shadow-md `}
              onClick={() => setShowMapMobile(true)}
            >
              Map
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ProvidersList;
