import { useAppDispatch, useAppSelector } from 'hooks/reduxHook';
import { useEffect } from 'react';
import { getDomainConfig } from 'reduxApp/features/domainConfigSlice';

const DomainConfig = () => {
  const domainReduxDataNetworkId: string = useAppSelector((state) => state?.domain?.config?.networkId);

  const dispatch = useAppDispatch();
  useEffect(() => {
    (async () => {
      if (domainReduxDataNetworkId === '') {
        await dispatch(getDomainConfig());
      }
    })();
  }, [dispatch, domainReduxDataNetworkId]);
};

export default DomainConfig;
