import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchDomainConfig } from 'api/domainConfigSvc';
import { IDomainConfig, INetwork, INetworkActors } from 'types/domain';

type IinitialState = {
  config: IDomainConfig;
  loading: boolean;
};

const initialState: IinitialState = {
  config: {
    domainId: '',
    networkId: '',
    host: '',
    networkLogo: '',
    network: {} as INetwork,
    networkActors: [] as INetworkActors[],
  },
  loading: false,
};

// function actually call get for domain config data
export const getDomainConfig = createAsyncThunk('get/fetchDomainConfiguration', async (dummy, { rejectWithValue }) => {
  try {
    const response = await fetchDomainConfig();
    return JSON.stringify(response);
  } catch (err: any) {
    return rejectWithValue({ error: err.message });
  }
});

const domainConfigSlice = createSlice({
  name: 'domain',
  initialState: initialState,
  extraReducers: (builder) => {
    builder.addCase(getDomainConfig.pending, (state, action) => {
      state.loading = true;
    });

    builder.addCase(getDomainConfig.fulfilled, (state, action) => {
      const domainConfigData = JSON.parse(action.payload);
      state.config = domainConfigData;
      state.loading = false;
    });

    builder.addCase(getDomainConfig.rejected, (state, action) => {
      state.loading = false;
    });
  },
  reducers: {},
});

// Export reducer
export default domainConfigSlice.reducer;
