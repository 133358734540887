export class Payment {
  constructor() {
    this.weekly = false;
    this.monthly = false;
    this.subsidy = false;
  }
  public weekly: boolean;

  public monthly: boolean;

  public subsidy: boolean;
}
