import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { IProvidersListFromESearch } from 'types/provider';
import { ccProvidersByAddress, ccProvidersByNetwork } from 'api/ccProviderSvc';

// Create Slice combine the actions, initialState, and reducer into 1 function
type IinitialState = {
  address: string;
  providers?: IProvidersListFromESearch[];
  loading: boolean;
  error?: null | string;
  providerById?: {};
};

const initialState: IinitialState = { address: '', providers: [], loading: false, providerById: {} };

export const fetchSearchProvider = createAsyncThunk(
  'get/searchProvider',
  async (data: { network_id: string; address: string; distance: { miles: number } }, { rejectWithValue }) => {
    try {
      //const responses = await axios.get("posts");
      const response = await ccProvidersByAddress(data);
      return JSON.stringify({ providers: response.result.hits, address: response.address });
    } catch (err: any) {
      return rejectWithValue({ error: err.message });
    }
  }
);

export const fetchSearchProviderByNetwork = createAsyncThunk(
  'get/searchProviderByNetwork',
  async (network_id: string, { rejectWithValue }) => {
    try {
      const response = await ccProvidersByNetwork(network_id);
      return JSON.stringify({ providers: response?.hits?.hits });
    } catch (err: any) {
      return rejectWithValue({ error: err.message });
    }
  }
);

const providerSlice = createSlice({
  name: 'providers',
  initialState: initialState,
  reducers: {
    searchProvider: (state) => {
      state.providers = [];
      state.providerById = {};
      state.address = '';
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSearchProvider.fulfilled, (state, action) => {
      // both `state` and `action` are now correctly typed
      // based on the slice state and the `pending` action creator
      const providerData = JSON.parse(action.payload);
      const providerById = {};
      providerData.providers.forEach((p: any) => {
        Object.assign(providerById, { [p._source.id]: { name: p._source.name } });
      });
      state.providerById = providerById;
      state.providers = providerData.providers;
      state.loading = false;
    });
    builder.addCase(fetchSearchProvider.pending, (state, action) => {
      // both `state` and `action` are now correctly typed
      // based on the slice state and the `pending` action creator
      state.providers = [];
      state.loading = true;
    });
    builder.addCase(fetchSearchProvider.rejected, (state, action) => {
      // both `state` and `action` are now correctly typed
      // based on the slice state and the `pending` action creator
      state.error = action.error.message;
      state.loading = false;
    });

    builder.addCase(fetchSearchProviderByNetwork.fulfilled, (state, action) => {
      // both `state` and `action` are now correctly typed
      // based on the slice state and the `pending` action creator
      const providerData = JSON.parse(action.payload);
      const providerById = {};
      providerData.providers.forEach((p: any) => {
        Object.assign(providerById, { [p._source.id]: { name: p._source.name } });
      });
      state.providerById = providerById;
      state.providers = providerData.providers;
      state.address = providerData.address;
      state.loading = false;
    });
    builder.addCase(fetchSearchProviderByNetwork.pending, (state, action) => {
      // both `state` and `action` are now correctly typed
      // based on the slice state and the `pending` action creator
      state.providers = [];
      state.loading = true;
    });
    builder.addCase(fetchSearchProviderByNetwork.rejected, (state, action) => {
      // both `state` and `action` are now correctly typed
      // based on the slice state and the `pending` action creator
      state.error = action.error.message;
      state.loading = false;
    });
  },
});

// Export actions
export const { searchProvider } = providerSlice.actions;

// Export reducer
export default providerSlice.reducer;
