import 'reflect-metadata';

import { lazy } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { paths } from './routePaths';
import PrivateRoute from './PrivateRoute';
import NetworkRoute from './NetworkRoute';
import DomainConfig from 'components/DomainConfig/DomainConfig';
import ScrollToTop from './ScrollToTop';
import Splash from 'pages/childCareFinder/Splash';
import SearchProviders from 'pages/childCareFinder/SearchProviders';
import ProvidersList from 'pages/childCareFinder/ProvidersList';

const AnonymousAuth = lazy(() => import('components/Auth/AnonymousAuth'));
const CustomTokenAuth = lazy(() => import('components/Auth/CustomTokenAuth'));
const NotAuthorized = lazy(() => import('components/Auth/NotAuthorized'));
const MainLayout = lazy(() => import('pages/MainLayout'));
const NotFound = lazy(() => import('components/Auth/NotFound'));

const Welcome = lazy(() => import('pages/parent/Welcome'));
//const Step1 = lazy(() => import('pages/parent/Step1'));
//const Step2 = lazy(() => import('pages/parent/Step2'));
//const Step3 = lazy(() => import('pages/parent/Step3'));
//const Step4 = lazy(() => import('pages/parent/Step4'));
const Status = lazy(() => import('pages/parent/Status'));

const CreateProfile = lazy(() => import('pages/parent/forms/CreateProfile'));
const AddChildInformation = lazy(() => import('pages/parent/forms/AddChildInformation'));
const ChildCareSchedule = lazy(() => import('pages/parent/forms/ChildCareSchedule'));
const Providers = lazy(() => import('pages/parent/forms/Providers'));

const AllApplications = lazy(() => import('pages/families/AllApplications'));
const Application = lazy(() => import('pages/families/Application'));

const One = lazy(() => import('pages/One'));
const Secret = lazy(() => import('pages/Secret'));
//const Two = lazy(() => import('pages/Two'));
const SigninRedirect = lazy(() => import('components/Auth/SigninRedirect'));
const Landing = lazy(() => import('pages/Landing'));

export default function AppRoutes() {
  DomainConfig();
  return (
    <BrowserRouter>
      <ScrollToTop>
        <Routes>
          <Route index element={<Navigate to={paths.landing} />} />

          <Route element={<MainLayout />}>
            {/* Unprotected routes */}
            <Route path={paths.landing} element={<Landing />} />

            <Route path="page-one" element={<One />} />

            <Route
              path="secret-page"
              element={
                <PrivateRoute>
                  <Secret />
                </PrivateRoute>
              }
            />
            {/* CE Anonymous Routes */}
            <Route path={paths.parent.index} element={<Welcome />} />
            <Route path={paths.parent.status} element={<Status />} />
            <Route path={paths.parent.statusWithId} element={<Status />} />

            {/**CE Forms Routes */}
            <Route path={paths.parent.application.adults} element={<CreateProfile />} />
            {/** Protected Application Pages */}
            <Route
              path={paths.parent.application.children}
              element={
                <PrivateRoute>
                  <AddChildInformation />
                </PrivateRoute>
              }
            />
            <Route
              path={paths.parent.application.childSchedules}
              element={
                <PrivateRoute>
                  <ChildCareSchedule />
                </PrivateRoute>
              }
            />
            <Route
              path={paths.parent.application.providers}
              element={
                <PrivateRoute>
                  <Providers />
                </PrivateRoute>
              }
            />

            {/* CE Protected Network Routes */}

            <Route
              path={paths.network.index}
              element={
                <NetworkRoute>
                  <AllApplications />
                </NetworkRoute>
              }
            />
            <Route
              path={paths.network.application}
              element={
                <NetworkRoute>
                  <Application />
                </NetworkRoute>
              }
            />
            <Route
              path={paths.network.applicationWithId}
              element={
                <NetworkRoute>
                  <Application />
                </NetworkRoute>
              }
            />
            {/* below Path is For Child care finder */}
            <Route path={paths.childCareFinder.index} element={<Splash />} />
            <Route path={paths.childCareFinder.search} element={<SearchProviders />} />
            <Route path={paths.childCareFinder.providers} element={<ProvidersList />} />

            {/* SSO Redirect Page */}
            <Route path="sign-in" element={<SigninRedirect />} />

            {/* Custom token sing in page */}
            <Route path="anonymous" element={<AnonymousAuth />} />

            {/* Custom token sing in page */}
            <Route path="auth" element={<CustomTokenAuth />} />

            {/* Error Pages */}
            <Route path="401" element={<NotAuthorized />} />
            <Route path="*" element={<NotFound />} />
          </Route>
        </Routes>
      </ScrollToTop>
    </BrowserRouter>
  );
}
