// users.service.ts
import http from './httpService/http';
import { paths } from './paths';
// import { instanceToPlain, plainToInstance } from 'class-transformer';

// const providerListing = paths.anonymous.providerListing;
// for now used direct path later uncomment above path for providerListing api
const providersPath = paths.anonymous.providerListing;
const networkProviderPath = paths.byNetwork.provider_by_network;

export const ccProvidersByAddress = async (obj: any): Promise<any> => {
  //   const objArr = instanceToPlain(new SearchProviders(searchProvider));
  const response = await http.post<object, any>(providersPath(), obj as object);
  //   return plainToInstance(Any, response.data as {}) as any;
  return response.data;
};

export const ccProvidersByNetwork = async (networkId: string): Promise<any> => {
  const response = await http.get<object, any>(networkProviderPath(networkId));
  return response.data;
};
