import useAuth from 'hooks/useAuth';
import { Navigate, Outlet, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { redirects } from './routePaths';
import { useEffect } from 'react';
import LoadingSpinner from 'components/LoadingSpinner';

interface NetworkRouteProps {
  children: React.ReactElement;
  redirectTo?: string;
}

const DEFAULT_REDIRECT_PATH = redirects.signIn;

export default function NetworkRoute({ children, redirectTo = DEFAULT_REDIRECT_PATH }: NetworkRouteProps) {
  const { currentUser } = useAuth();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const isNamedUser = (): boolean => (currentUser?.uid && !currentUser.isAnonymous ? true : false);
  const isNamed = (): boolean => {
    return isNamedUser();
  };
  const isNetworkUser = isNamed;

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (!currentUser || !currentUser.uid) {
        // Redirect to the specified route if the user is not authenticated
        /*  Navigate({
          to: redirectTo,
          state: { from: `${location.pathname}?${searchParams.toString()}` },
        }); */
        navigate(redirectTo, {
          state: { from: `${location.pathname}?${searchParams.toString()}` },
        });
      }
    }, 3000);

    return () => clearTimeout(timeoutId); // Clean up the timeout when the component unmounts
  }, [currentUser, location.pathname, redirectTo, searchParams, navigate]);

  //  return currentUser?.uid ? (
  return isNetworkUser() ? children : <LoadingSpinner />;
}

export function NetworkOutlet({ redirectTo = DEFAULT_REDIRECT_PATH }: { redirectTo?: string }) {
  const { currentUser } = useAuth();
  const location = useLocation();
  return currentUser?.uid ? <Outlet /> : <Navigate to={redirectTo} state={{ from: location.pathname }} />;
}
