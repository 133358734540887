import { getContext, getRollbarConfig } from './config';
import { getLogger } from './logger';

import type {Level} from 'rollbar';
//import { getUserContext, setUserContext } from './UserContext';



// ErrorLevel enum
export const ErrorLevel = {
  Debug: 'debug' as Level,
  Info: 'info' as Level,
  Warning: 'warning' as Level,
  Error: 'error' as Level,
  Critical: 'critical' as Level

};
// ErrorLevel from case insensitive string
export const getErrorLevel = (errorLevel: string): Level => {
  switch(errorLevel.toLowerCase()) {
    case 'debug':
      return ErrorLevel.Debug;
    case 'info':
      return ErrorLevel.Info;
    case 'warning':
      return ErrorLevel.Warning;
    case 'error':
      return ErrorLevel.Error;
    case 'critical':
      return ErrorLevel.Critical;
    default:
      return ErrorLevel.Debug;
  }
};
// Worker
const logByLevel = (error: string, message: string, errorLevel: Level) => {
  const config = getRollbarConfig(errorLevel.toString())
  const context = getContext(config);
  const logger = getLogger();

  // message, error, context
  logger(message, error, context);

};
// Publicly exported Functions
const logDebug = (error: string, message: string) => {
  return logByLevel(message, error, ErrorLevel.Debug);
};
const logInfo = (error: string, message: string) => {
  return logByLevel(message, error, ErrorLevel.Info);
};
const logWarning = (error: string, message: string) => {
  return logByLevel(message, error, ErrorLevel.Warning);
};
const logError = (error: string, message: string) => {
  return logByLevel(message, error, ErrorLevel.Error);
};
const logCritical = (error: string, message: string) => {
  return logByLevel(message, error, ErrorLevel.Critical);
};

export {
  logDebug, logInfo, logWarning, logError, logCritical 
}