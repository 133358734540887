export class Language {
  constructor() {
    this.english = false;
    this.spanish = false;
    this.chinese = false;
  }

  public english: boolean;

  public spanish: boolean;

  public chinese: boolean;
}
