import { Box, Flex, Hide } from '@chakra-ui/react';
import { ReactComponent as IconAgeGroup } from 'assets/images/ageGroup.svg';
import { ReactComponent as IconSubsidy } from 'assets/images/subsidy.svg';
import { ReactComponent as IconLanguage } from 'assets/images/language.svg';
import { ReactComponent as IconLocation } from 'assets/images/location.svg';
import { useColorScheme } from 'theme/chakra/colors';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHook';
import { Coordinates } from 'api/dataObjects/subObjects/Coodinates';
import { GeoLocationAddress } from 'api/dataObjects/GeoLocationAddress';
import { constant } from 'constants/common';
import { Filters } from 'api/filters/Filters';
import {
  setChildCareAgeFilterAction,
  setChildCareLanguageFilterAction,
  setChildCarePaymentFilterAction,
  clearOneChildCareAgeFilterAction,
  clearOneChildCarePaymentFilterAction,
  clearOneChildCareLanguageFilterAction,
  setChildCareDistanceFilterAction,
} from 'reduxApp/features/filterSlice';
import { Age } from 'api/filters/Age';
import { Payment } from 'api/filters/Payment';
import { Language } from 'api/filters/Language';
//import { getAddressByCoordinates } from 'reduxApp/features/geoSlice';
import FilterCCButton from 'components/Parts/FilterCCButton';
import GeoLocationSearchBar from 'components/Geo/geoLocationSearchBar';
import SlideUpOverlay from 'components/Modal/SlideFilter/Overlay';
import FilterDistance from 'components/Modal/SlideFilter/FilterDistance';
import FilterModal from 'components/Modal/SlideFilter/FilterModal';
import LangaugeSubMenu from 'components/PageLayout/Header/LanguageSubMenu';
import useAppLanguage from 'hooks/useAppLanguage';
import { useLocation } from 'react-router-dom';
import SlideUpButton from 'components/Modal/SlideFilter/SlideOver';

type IWrapperProps = {
  setSelectedSearch?: Function;
};

const GeoLocationWithButtons: React.FC<IWrapperProps> = ({ setSelectedSearch }) => {
  const blueColor = useColorScheme('makeBackgroundAndColorsReturn', 'bluegray');
  const greyText = useColorScheme('makeBackgroundAndColorsReturn', 'lightGrey');

  const geoAddressCordinatesReduxData: Coordinates = useAppSelector((state) => state?.geo?.searchAddressCoordinates);
  const geoAddressReduxData: GeoLocationAddress = useAppSelector((state) => state?.geo?.geoAddress);
  const childCareFinderFilterReduxData: Filters = useAppSelector(
    (state) => state?.childCareFilter?.childCareFinderFilter
  );
  const distanceFilterReduxData: number = useAppSelector(
    (state) => state?.childCareFilter?.childCareFinderFilter?.distance?.max_miles
  );

  const geoReduxData: Coordinates = useAppSelector(
    (state) => state?.geo?.geoAddress?.coordinates || { lat: 0, lon: 0 }
  );
  const [searchValue, setSearchValue] = useState('');
  const [filterDistance, setFilterDistance] = useState<boolean>(true);
  const [filterAgeGroup, setFilterAgeGroup] = useState<boolean>(false);
  const [filterLanguage, setFilterLanguage] = useState<boolean>(false);
  const [filterPayment, setFilterPayment] = useState<boolean>(false);

  // const [removeAllAgeGroupFilter, setremoveAllAgeGroupFilter] = useState<boolean>(false)
  const [overlayShow, setOverlayShow] = useState<boolean>(false);
  const [showDistanceModal, setShowDistanceModal] = useState<boolean>(false);
  const [showAgeModal, setShowAgeModal] = useState<boolean>(false);
  const [showPaymentModal, setShowPaymentModal] = useState<boolean>(false);
  const [showLanguageModal, setShowLanguageModal] = useState<boolean>(false);

  const dispatch = useAppDispatch();
  const { translate } = useAppLanguage();
  const location = useLocation();

  useEffect(() => {
    if (Object.values(childCareFinderFilterReduxData.distance).includes(true) || geoAddressReduxData.coordinates?.lat)
      setFilterDistance(true);
    // else setFilterDistance(false);
    if (Object.values(childCareFinderFilterReduxData.age).includes(true)) setFilterAgeGroup(true);
    else setFilterAgeGroup(false);
    if (Object.values(childCareFinderFilterReduxData.payment).includes(true)) setFilterPayment(true);
    else setFilterPayment(false);
    if (Object.values(childCareFinderFilterReduxData.language).includes(true)) setFilterLanguage(true);
    else setFilterLanguage(false);
  }, [childCareFinderFilterReduxData, geoAddressReduxData]);

  let removeAllAgeGroupFilter = false;
  let removeAllPaymentFilter = false;
  let removeAllLanguages = false;
  let resetDistaceModalFilter = false;

  function removeFilter(fType: string) {
    if (fType === 'age') {
      dispatch(setChildCareAgeFilterAction(JSON.stringify(new Age())));
      removeAllAgeGroupFilter = true;
    }
    if (fType === 'payment') {
      dispatch(setChildCarePaymentFilterAction(JSON.stringify(new Payment())));
      removeAllPaymentFilter = true;
    }
    if (fType === 'language') {
      dispatch(setChildCareLanguageFilterAction(JSON.stringify(new Language())));
      removeAllLanguages = true;
    }
    if (fType === 'distance') {
      dispatch(setChildCareDistanceFilterAction(JSON.stringify({ max_miles: 5, refreshFilter: true })));
      resetDistaceModalFilter = true;
    }
  }

  const removeOneFilter = (filterType: string, filterName: string = 'max_miles') => {
    if (filterType === 'age') {
      dispatch(clearOneChildCareAgeFilterAction(JSON.stringify({ [filterName]: false })));
    } else if (filterType === 'payment') {
      dispatch(clearOneChildCarePaymentFilterAction(JSON.stringify({ [filterName]: false })));
    } else if (filterType === 'language') {
      dispatch(clearOneChildCareLanguageFilterAction(JSON.stringify({ [filterName]: false })));
    } else {
      dispatch(setChildCareDistanceFilterAction(JSON.stringify({ [filterName]: 5, refreshFilter: true })));
    }
  };

  const toggleFilterDistance = () => {
    if (!resetDistaceModalFilter) {
      setShowDistanceModal(!showDistanceModal);
      setOverlayShow(!overlayShow);
      document.body.style.overflow = 'hidden'; // Hide body scroll
    }
    return filterDistance;
  };

  const togglefilterPayment = () => {
    if (!removeAllPaymentFilter) {
      setShowPaymentModal(!showPaymentModal);
      setOverlayShow(!overlayShow);
      document.body.style.overflow = 'hidden'; // Hide body scroll
    }
    return filterPayment;
  };

  const toggleFilterAgeGroup = () => {
    if (!removeAllAgeGroupFilter) {
      setShowAgeModal(!showDistanceModal);
      setOverlayShow(!overlayShow);
      document.body.style.overflow = 'hidden'; // Hide body scroll
    }
    return filterAgeGroup;
  };

  const toggleFilterLanguage = () => {
    if (!removeAllLanguages) {
      setShowLanguageModal(!showLanguageModal);
      setOverlayShow(!overlayShow);
      document.body.style.overflow = 'hidden'; // Hide body scroll
    }
    return filterLanguage;
  };
  useEffect(() => {
    if (geoAddressReduxData.formattedAddress) {
      setSearchValue(geoAddressReduxData.formattedAddress);
    }
  }, [geoAddressReduxData.formattedAddress]);
  useEffect(() => {
    if (geoAddressCordinatesReduxData.lat) {
      // dispatch(getAddressByCoordinates(geoAddressCordinatesReduxData));
      setFilterDistance(true);
    }
  }, [geoAddressCordinatesReduxData, dispatch]);

  return (
    <>
      <Box>
        <Flex direction={'row'}>
          <GeoLocationSearchBar
            placeHolderValue={translate('Enter program name or location')}
            w={'-webkit-fill-available'}
            setSearchValue={setSearchValue}
            value={searchValue}
            searchValue={searchValue}
            setSelectedSearch={setSelectedSearch}
          />
          {location.pathname !== '/child-care-finder/search' && (
            <Hide below={'md'}>
              <Box ml={9} mt={4}>
                <LangaugeSubMenu />
              </Box>
            </Hide>
          )}
        </Flex>
        <Box>
          {window.location.pathname === '/child-care-finder/search/providers' && (
            <Flex mt={2} direction={'row-reverse'} alignItems={'center'} justifyContent={'space-between'}>
              <Box float={'right'}>
                <SlideUpButton isOpen={false} />
              </Box>
              <Box display={'flex'} flexWrap={'wrap'} gap={1}>
                <FilterCCButton
                  removeFilter={() => removeOneFilter('distance')}
                  //isFilter={true}
                  onClick={toggleFilterDistance}
                  xIconRemoveButtonActive={true}
                  leftIcon={<IconLocation stroke={greyText} width={'16px'} height={'16px'} />}
                >
                  <div>
                    {`${distanceFilterReduxData} `} {translate('mile radius')}
                  </div>
                </FilterCCButton>
                {/* {
                // @ts-ignore
                (Object.keys(childCareFinderFilterReduxData.age).filter(key => childCareFinderFilterReduxData.age[key]).map(ageFilter=> <FilterCCButton isFilter={true} leftIcon={<IconAgeGroup  stroke={blueColor} width={'16px'} height={'16px'}/>}>{ageFilter}</FilterCCButton>))
              }{
                // @ts-ignore
                (Object.keys(childCareFinderFilterReduxData.language).filter(key => childCareFinderFilterReduxData.language[key]).map(languageFilter=> <FilterCCButton isFilter={true} leftIcon={<IconLanguage stroke={greyText} width={'16px'} height={'16px'} />} >{languageFilter}</FilterCCButton>))
              }{
                // @ts-ignore
                (Object.keys(childCareFinderFilterReduxData.payment).filter(key => childCareFinderFilterReduxData.payment[key]).map(paymentFilter=> <FilterCCButton isFilter={true} leftIcon={<IconSubsidy stroke={greyText} width={'16px'} height={'16px'} />}>{paymentFilter}</FilterCCButton>))
              } */}

                {/* age group filters */}
                {childCareFinderFilterReduxData.age.infant && (
                  <FilterCCButton
                    isFilter={true}
                    leftIcon={<IconAgeGroup stroke={blueColor} width={'16px'} height={'16px'} />}
                    removeFilter={() => removeOneFilter('age', 'infant')}
                  >
                    {translate('Infant care')}
                  </FilterCCButton>
                )}
                {childCareFinderFilterReduxData.age.toddler && (
                  <FilterCCButton
                    isFilter={true}
                    leftIcon={<IconAgeGroup stroke={blueColor} width={'16px'} height={'16px'} />}
                    removeFilter={() => removeOneFilter('age', 'toddler')}
                  >
                    {translate('Toddler care')}
                  </FilterCCButton>
                )}
                {childCareFinderFilterReduxData.age.preschool && (
                  <FilterCCButton
                    isFilter={true}
                    leftIcon={<IconAgeGroup stroke={blueColor} width={'16px'} height={'16px'} />}
                    removeFilter={() => removeOneFilter('age', 'preschool')}
                  >
                    {translate('Preschool')}
                  </FilterCCButton>
                )}
                {childCareFinderFilterReduxData.age.school_age && (
                  <FilterCCButton
                    isFilter={true}
                    leftIcon={<IconAgeGroup stroke={blueColor} width={'16px'} height={'16px'} />}
                    removeFilter={() => removeOneFilter('age', 'school_age')}
                  >
                    {translate('School age')}
                  </FilterCCButton>
                )}
                {/* Language filters */}
                {childCareFinderFilterReduxData.language.spanish && (
                  <FilterCCButton
                    isFilter={true}
                    leftIcon={<IconLanguage stroke={greyText} width={'16px'} height={'16px'} />}
                    removeFilter={() => removeOneFilter('language', 'spanish')}
                  >
                    {translate('Spanish')}
                  </FilterCCButton>
                )}
                {childCareFinderFilterReduxData.language.english && (
                  <FilterCCButton
                    isFilter={true}
                    leftIcon={<IconLanguage stroke={greyText} width={'16px'} height={'16px'} />}
                    removeFilter={() => removeOneFilter('language', 'english')}
                  >
                    {translate('English')}
                  </FilterCCButton>
                )}
                {childCareFinderFilterReduxData.language.chinese && (
                  <FilterCCButton
                    isFilter={true}
                    leftIcon={<IconLanguage stroke={greyText} width={'16px'} height={'16px'} />}
                    removeFilter={() => removeOneFilter('language', 'chinese')}
                  >
                    {translate('Chinese')}
                  </FilterCCButton>
                )}

                {/* Payment filters */}
                {childCareFinderFilterReduxData.payment.monthly && (
                  <FilterCCButton
                    isFilter={true}
                    leftIcon={<IconSubsidy stroke={greyText} width={'16px'} height={'16px'} />}
                    removeFilter={() => removeOneFilter('payment', 'monthly')}
                  >
                    {translate('I want to pay monthly')}
                  </FilterCCButton>
                )}
                {childCareFinderFilterReduxData.payment.weekly && (
                  <FilterCCButton
                    isFilter={true}
                    leftIcon={<IconSubsidy stroke={greyText} width={'16px'} height={'16px'} />}
                    removeFilter={() => removeOneFilter('payment', 'weekly')}
                  >
                    {translate('I want to pay weekly')}
                  </FilterCCButton>
                )}
                {childCareFinderFilterReduxData.payment.subsidy && (
                  <FilterCCButton
                    isFilter={true}
                    leftIcon={<IconSubsidy stroke={greyText} width={'16px'} height={'16px'} />}
                    removeFilter={() => removeOneFilter('payment', 'subsidy')}
                  >
                    {translate('I want to pay with a subsidy')}
                  </FilterCCButton>
                )}
              </Box>
            </Flex>
          )}

          {window.location.pathname === '/child-care-finder/search' && (
            <Box>
              <Flex gap={4} pt={2} wrap={'wrap'}>
                <FilterCCButton
                  isFilter={filterDistance}
                  onClick={toggleFilterDistance}
                  filterType={'distance'}
                  removeFilter={removeFilter}
                  pt={5}
                  pb={5}
                  pl={4}
                  pr={4}
                  leftIcon={
                    <IconLocation stroke={filterDistance ? greyText : blueColor} width={'16px'} height={'16px'} />
                  }
                >
                  {filterDistance
                    ? `${childCareFinderFilterReduxData.distance.max_miles} ${translate('mile radius')}`
                    : translate('Distance')}
                </FilterCCButton>
                <FilterCCButton
                  isFilter={filterAgeGroup}
                  onClick={toggleFilterAgeGroup}
                  filterType={'age'}
                  removeFilter={removeFilter}
                  pt={5}
                  pb={5}
                  pl={4}
                  pr={4}
                  leftIcon={
                    <IconAgeGroup stroke={filterAgeGroup ? greyText : blueColor} width={'16px'} height={'16px'} />
                  }
                >
                  {translate('Age group')}
                </FilterCCButton>

                <FilterCCButton
                  isFilter={filterPayment}
                  onClick={togglefilterPayment}
                  filterType={'payment'}
                  removeFilter={removeFilter}
                  pt={5}
                  pb={5}
                  pl={4}
                  pr={4}
                  leftIcon={
                    <IconSubsidy stroke={filterPayment ? greyText : blueColor} width={'16px'} height={'16px'} />
                  }
                >
                  {translate('Payment options')}
                </FilterCCButton>
                <FilterCCButton
                  isFilter={filterLanguage}
                  onClick={toggleFilterLanguage}
                  filterType={'language'}
                  removeFilter={removeFilter}
                  pt={5}
                  pb={5}
                  pl={4}
                  pr={4}
                  leftIcon={
                    <IconLanguage stroke={filterLanguage ? greyText : blueColor} width={'16px'} height={'16px'} />
                  }
                >
                  {translate('Languages')}
                </FilterCCButton>
              </Flex>
            </Box>
          )}
        </Box>
      </Box>

      {showDistanceModal && (
        <div className="fixed bottom-0 left-0 w-full sm:w-auto sm:inset-0 sm:flex sm:items-center sm:justify-center z-50">
          <SlideUpOverlay
            isOpen={overlayShow}
            onClose={() => {
              setOverlayShow(false);
              setShowDistanceModal(false);
            }}
            className="fixed inset-0 bg-black opacity-50"
          />{' '}
          <div
            className={`sm:min-w-fit sm:max-w-fit relative z-50 max-h-screen overflow-scroll ${
              showDistanceModal ? 'block' : 'hidden'
            }`}
          >
            <FilterDistance
              center={
                geoReduxData.lon && geoReduxData.lon !== 0
                  ? { lat: geoReduxData.lat, lng: geoReduxData.lon }
                  : constant.centerLatLng
              }
              onClose={() => {
                setOverlayShow(false);
                setShowDistanceModal(false);
                document.body.style.overflow = 'scroll'; // Show body scroll
              }}
            />
          </div>
        </div>
      )}
      {showAgeModal && (
        <div className="fixed bottom-0 left-0 w-full sm:w-auto sm:inset-0 sm:flex sm:items-center sm:justify-center z-50">
          <SlideUpOverlay
            isOpen={overlayShow}
            onClose={() => {
              setOverlayShow(false);
              setShowAgeModal(false);
            }}
            className="fixed inset-0 bg-black opacity-50"
          />

          <div
            className={`sm:min-w-fit sm:max-w-fit relative z-50 max-h-screen overflow-scroll ${
              showAgeModal ? 'block' : 'hidden'
            }`}
          >
            <FilterModal
              filterData={constant.ageFilterData}
              filterTitle={translate('Age group')}
              fieldName="age"
              onClose={() => {
                setOverlayShow(false);
                setShowAgeModal(false);
                document.body.style.overflow = 'scroll'; // Show body scroll
              }}
            />
          </div>
        </div>
      )}
      {showPaymentModal && (
        <div className="fixed bottom-0 left-0 w-full sm:w-auto sm:inset-0 sm:flex sm:items-center sm:justify-center z-50">
          <SlideUpOverlay
            isOpen={overlayShow}
            onClose={() => {
              setOverlayShow(false);
              setShowPaymentModal(false);
            }}
            className="fixed inset-0 bg-black opacity-50"
          />
          <div
            className={`sm:min-w-fit sm:max-w-fit relative z-50 max-h-screen overflow-scroll ${
              showPaymentModal ? 'block' : 'hidden'
            }`}
          >
            <FilterModal
              filterData={constant.paymentFilterData}
              filterTitle={translate('Payment options')}
              fieldName="payment"
              onClose={() => {
                setOverlayShow(false);
                setShowPaymentModal(false);
                document.body.style.overflow = 'scroll'; // Show body scroll
              }}
            />
          </div>
        </div>
      )}
      {showLanguageModal && (
        <div className="fixed bottom-0 left-0 w-full sm:w-auto sm:inset-0 sm:flex sm:items-center sm:justify-center z-50">
          <SlideUpOverlay
            isOpen={overlayShow}
            onClose={() => {
              setOverlayShow(false);
              setShowLanguageModal(false);
            }}
            className="fixed inset-0 bg-black opacity-50"
          />
          <div
            className={`sm:min-w-fit sm:max-w-fit relative z-50 max-h-screen overflow-scroll ${
              showLanguageModal ? 'block' : 'hidden'
            }`}
          >
            <FilterModal
              filterData={constant.languageFilterData}
              filterTitle={translate('Languages spoken')}
              fieldName="language"
              onClose={() => {
                setOverlayShow(false);
                setShowLanguageModal(false);
                document.body.style.overflow = 'scroll'; // Show body scroll
              }}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default GeoLocationWithButtons;
