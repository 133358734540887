import { Button, ButtonProps } from '@chakra-ui/react';
import { forwardRef } from 'react';
import { useColorScheme } from 'theme/chakra/colors';
import { INPUT_RADIUS, PADDING_INPUT } from 'theme/chakra/layout';
import { INPUT_FONT } from 'theme/chakra/typography';

type FormButtonProps = {
  children?: React.ReactChild;
  title?: string;
  isLoading?: boolean;
  isDisabled?: boolean;
  buttonType?: string;
} & ButtonProps;

/**
 * A button useful for submit actions in forms
 * Ref is forwarded for compatibility with react-hook-form
 * https://react-hook-form.com/get-started#Integratinganexistingform
 */
const FormButton = forwardRef<ButtonProps, FormButtonProps>(
  (
    {
      /** @type {React.Children} react element to display in button */
      children,
      /** @type {string} text to display in button, overridden by children */
      title,
      /** @type {boolean} whether to display a loading indicator */
      isLoading,
      /** @type {string} primary and secondary design button show */
      buttonType = 'primary',
      ...props /** rest of props, forwarded to Chakra <Button> */
    },
    ref
  ) => {
    const primaryButtonColors = useColorScheme('makeButtonColors');
    const secondaryButtonColors = useColorScheme('makeSecondaryButtonColors');
    const buttonColors = buttonType === 'primary' ? primaryButtonColors : secondaryButtonColors;
    return (
      <Button
        ref={ref}
        colorScheme="gray"
        variant="solid"
        w="100%"
        borderRadius={INPUT_RADIUS}
        padding={PADDING_INPUT}
        fontSize={INPUT_FONT}
        fontWeight="medium"
        isLoading={isLoading}
        {...buttonColors}
        {...props}
      >
        {children ? children : title}
      </Button>
    );
  }
);

export default FormButton;
