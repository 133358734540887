import { isEmpty } from 'lodash';

// firebaseConfig is configuration just for initialization of firebase app
// wsConfig is wonderschool specific settings
// _meta includes debugging information
import config from './config.json';
const { _meta, wsConfig, firebaseConfig } = config;


const LOCAL = 'local';
const DEV = 'dev';
const PROD = 'prod';

let currentEnv = wsConfig.envName;

const getCurrentEnv = (): string => {
  return currentEnv;
};

/**
 * configuration for specified env, from file copied during build step
 * see package.json
 * @returns {{
 *  _meta: Object,
 *  firebaseConfig: FirebaseOptions,
 *  wsConfig: { functionsDomain: string, ccmsDomain: string, ccmsFunctionsDomain: string, envName: string, cookieSecret: string }}} configuration settings
 */
const getConfig = () => {
  return { _meta, firebaseConfig, wsConfig };
};

/**
 * @param {string} env env name
 * @returns {boolean}
 */
const isLocal = (env = getCurrentEnv()): boolean => env === LOCAL;
/**
 * @param {string} env env name
 * @returns {boolean}
 */
const isDev = (env = getCurrentEnv()): boolean => env === DEV;
/**
 * @param {string} env env name
 * @returns {boolean}
 */
const isProd = (env = getCurrentEnv()): boolean => env === PROD;
/**
 * @param {string} env env name
 * @returns {boolean}
 */
const isTest = (env = getCurrentEnv()): boolean =>
  (isLocal(env) && isEmpty(process.env.REACT_APP_GIT_SHA)) ||
  !!process.env.REACT_APP_IS_TEST;


/**
 * whether the emulation env variable has been set
 * @returns {boolean}
 */
const shouldEmulateFirebase = (): boolean => isLocal();

const getRollbarToken = (): string => wsConfig.rollbarToken;

const getRollbarForced = (): boolean => wsConfig.rollbarForced;

const getAccountsDomain = (): string => wsConfig.accountsDomain;

const getNetworkDomain = (): string => wsConfig.networkDomain;

const getApiPath = (): string => wsConfig.apiPath;

const getCCMSDomain = (): string => wsConfig.ccmsDomain;

const getFunctionsDomain = (): string =>wsConfig.functionsDomain;

const getSecretProject = (): string => wsConfig.secretsProject;

export {
  LOCAL,
  DEV,
  PROD,
  // configs
  wsConfig,
  firebaseConfig,
  _meta,
  //Environments,
  getCurrentEnv,
  getConfig,
  isLocal,
  isDev,
  isProd,
  isTest,
  shouldEmulateFirebase,
  // getters
  getRollbarToken,
  getRollbarForced,
  getNetworkDomain,
  getAccountsDomain,
  getApiPath,
  getCCMSDomain,
  getFunctionsDomain,
  getSecretProject,
};
