import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { GeoLocationAddress } from 'api/dataObjects/GeoLocationAddress';
import { Coordinates } from 'api/dataObjects/subObjects/Coodinates';
import { fetchGeoLocationByCoordinates } from 'api/geoSvc';
import { geoInbounds } from 'utils/geoInbounds';

type ICoordinates = {
  lat: number;
  lon: number;
};

type IinitialState = {
  geoAddress: GeoLocationAddress;
  searchAddressCoordinates: Coordinates;
  loading: boolean;
};

const initialState: IinitialState = {
  geoAddress: {},
  searchAddressCoordinates: { lat: 0, lon: 0 },
  loading: false,
};

// function actually call get for domain config data
export const getAddressByCoordinates = createAsyncThunk(
  'get/fetchGeoLocationByCoordinates',
  async (coordinates: ICoordinates, { rejectWithValue }) => {
    // coordinates provided by nathan
    // coordinates.lat = 41.99616;
    // coordinates.lon = -91.6398911;
    try {
      // below code when user reject location access
      // if(coordinates.lat === 0) {
      //   const result = await getLatLongByIpAddress();
      //   const response: Coordinates = {lat: result.lat, lon: result.lon}
      //   return JSON.stringify(response);
      // }
      if (geoInbounds(coordinates)) {
        const response = await fetchGeoLocationByCoordinates(coordinates.lat, coordinates.lon);
        return JSON.stringify(response);
      } else {
        throw Error('Location found outside of USA');
      }
    } catch (err: any) {
      return rejectWithValue({ error: err.message });
    }
  }
);

// function actually call get for domain config data
export const getCoordinatesByAddress = createAsyncThunk(
  'get/getCoordinatesByAddress',
  async (coordinates: Coordinates, { rejectWithValue }) => {
    try {
      // const response = await fetchCoordinatesByAddress(address);
      //  return JSON.stringify(response);
      return JSON.stringify(coordinates);
    } catch (err: any) {
      return rejectWithValue({ error: err.message });
    }
  }
);
export const updateAutocompleteAddress = createAsyncThunk(
  'set/updateAutocompleteAddress',
  async (googleAddressData: GeoLocationAddress, { rejectWithValue }) => {
    try {
      console.log('ppppp', googleAddressData);
      return JSON.stringify(googleAddressData);
    } catch (err: any) {
      return rejectWithValue({ error: err.message });
    }
  }
);

const geoSlice = createSlice({
  name: 'geo',
  initialState: initialState,
  extraReducers: (builder) => {
    builder.addCase(getAddressByCoordinates.pending, (state, action) => {
      state.loading = true;
    });

    builder.addCase(getAddressByCoordinates.fulfilled, (state, action) => {
      const addressByLatLon = JSON.parse(action.payload);
      state.geoAddress = addressByLatLon;
      state.loading = false;
    });

    builder.addCase(getAddressByCoordinates.rejected, (state, action) => {
      state.loading = false;
      state.geoAddress = {};
    });

    builder.addCase(getCoordinatesByAddress.pending, (state, action) => {
      state.loading = true;
    });

    builder.addCase(getCoordinatesByAddress.fulfilled, (state, action) => {
      const addressCoordinates = JSON.parse(action.payload);
      state.searchAddressCoordinates = addressCoordinates;
      state.loading = false;
    });

    builder.addCase(getCoordinatesByAddress.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(updateAutocompleteAddress.pending, (state, action) => {
      state.loading = true;
    });

    builder.addCase(updateAutocompleteAddress.fulfilled, (state, action) => {
      const addressCoordinates = JSON.parse(action.payload);
      state.geoAddress = { ...state.geoAddress, ...addressCoordinates };
      state.loading = false;
    });

    builder.addCase(updateAutocompleteAddress.rejected, (state, action) => {
      state.loading = false;
    });
  },
  reducers: {},
});

// Export reducer
export default geoSlice.reducer;
