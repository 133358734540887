import useAppLanguage from 'hooks/useAppLanguage';
interface FooterProps {
  isShowApplyButton?: boolean;
  isFixed?: boolean;
}

const Footer: React.FC<FooterProps> = ({ isShowApplyButton = false, isFixed = true }) => {
  const { translate } = useAppLanguage();
  return (
    <footer className={`${isFixed ? 'fixed' : ''} bg-white shadow bottom-0 w-full border-t border-zinc-100 z-20 `}>
      <div className="mx-auto max-w-7xl overflow-hidden text-center text-tail-text text-sm  border-t-tail-gray px-2.5 pt-2.5 pb-2.5">
        {!isShowApplyButton && <div>{translate('No time to find your perfect school?')} </div>}
        {isShowApplyButton && (
          <a
            href="parent"
            className="flex justify-center space-x-10 text-base bg-tail-blue rounded-lg py-3 text-white bolder font-bold"
          >
            {translate('Apply directly to our network')}
          </a>
        )}
        <div className="mt-1 leading-1">
          <a href="/parent" className="text-tail-blue">
            {translate('Apply directly to our network')}{' '}
          </a>
          {translate(`to get help from our childcare and program specialist.`)}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
