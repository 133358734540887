import {
  useColorMode as useColorModeOriginal,
  useColorModeValue,
} from '@chakra-ui/react';

// light mode, dark mode
const SCHEME_NAMES = {
  DARK: 'dark',
  LIGHT: 'light',
};

// specific colors for Wonderschool scheme
const WonderColors = {
  SoftBlack: '#484848',
  BlackNavy: '#1e2542',
  WhiteBlue: '#fff',
  MiddleGray1: '#9A9A9A',
  BlueGray: '#287BBB',
  BrightBlue: '#5196CB',
  Gray85:'#D9D9D9',
  LightBlue:'#3F54DE',
  GrayHeadingBg:'#ECEEFC',
  GrayBg:'#F1F1F1',
  grayMui: '#F5F5F5',
  grayMuiPaging: '#B6B6B6',
  grayMuiPagingSeleted: '#9E9E9E',
  btnSecText:'#2E8BD2',
  btnSecHover:'#F8F8F8',
  btnSecondary:'',
  btnSecHoverText:'',
  grayModal:'#6D6D6D',
  greenDark:'#167E42',
  grayLight:'#C1C1C1',
  btnBorderBlue: '#7EB0D6',
  btnBgDarkBlue: '#031A6B',
  lightGrey: '#FCFCFC',
  darkBlueText: '#2269AD',
  yellowTxtBg: '#FFF5CC',
  BlueLight:'#EAF5FE',
  softPurple:'#6576E5'
};

/**
 * @param {LightScheme | DarkScheme} scheme
 * @returns CSS color styles for background
 */
const makeBackgroundColors = (scheme) => {
  return {
    color: scheme.textMain,
    bg: scheme.bgMain,
  };
};
/**
 * @param {LightScheme | DarkScheme} scheme
 * @returns CSS color styles for form inputs
 */
const makeInputColors = (scheme) => {
  return {
    bg: scheme.inputBg,
    color:scheme.inputAccent,
    borderColor: scheme.inputBorder,
    _placeholder: {
      color: scheme.inputPlaceholder,
    },
    _focus: {
      color: scheme.inputAccent,
      borderColor: scheme.inputBorderAccent,
      _placeholder: { color: scheme.inputBorderAccent },
    },
    _hover: {
      borderColor: scheme.inputBorderAccent,
      _placeholder: {
        color: scheme.inputPlaceholderAccent,
      },
      _focus: {
        color: scheme.inputAccent,
        borderColor: scheme.inputBorderAccent,
        _placeholder: { color: scheme.inputBorderAccent },
      },
    },
    _autofill: {
      bg: scheme.inputBg + ' !important',
    },
    _disabled:{
      opacity: 1,
      bg: scheme.grayBg,
      color:scheme.inputPlaceholder,
    }
  };
};
/**
 * @param {LightScheme | DarkScheme} scheme
 * @returns CSS color styles for anchor links
 */
const makeLinkColors = (scheme) => {
  return {
    color: scheme.link,
    _hover: { color: scheme.linkHover },
  };
};
/**
 * @param {LightScheme | DarkScheme} scheme
 * @returns CSS color styles for button inputs
 */
const makeButtonColors = (scheme) => {
  return {
    bg: scheme.btnPrimary,
    color: scheme.inputBg,
    _hover: {
      bg: scheme.btnHover,
      color: scheme.btnHoverText,
      _disabled: {
        opacity: 0,
        bg: scheme.grayBg,
        color: scheme.grayMuiPaging,
        _hover:{
          cursor: 'not-allowed',
        }
      } 
    },
    _disabled: {opacity:'1 !important', bg:scheme.grayBg, color:scheme.grayMuiPaging, }
  };
};
/**
 * @param {LightScheme | DarkScheme} scheme
 * @returns CSS color styles for button inputs
 */
const makeSpinnerColors = (scheme) => {
  return {
    color: scheme.spinnerColor,
  };
};
/**
 * @param {LightScheme | DarkScheme} scheme
 * @param {string} optKey lets you move the color from text color to background, etc
 * @returns CSS color styles for error elements
 */
const makeErrorColors = (scheme, optKey = 'color') => {
  return {
    [optKey]: scheme.invalidColor,
  };
};
/**
 * @param {LightScheme | DarkScheme} scheme
 * @param {string} optKey lets you move the color from text color to background, etc
 * @returns CSS color styles for subtle, muted elements
 */
const makeSubtleColors = (scheme, optKey = 'color') => {
  return {
    [optKey]: scheme.textLight,
  };
};

/**
 * @param {LightScheme | DarkScheme} scheme
 * @param {string} optKey lets you move the background color from text color to background, etc
 * @returns CSS bacground styles for progress bar, muted elements
 */
const makeProgressBgColors = (scheme, optKey = 'bg') => {
  return {
    [optKey]: scheme.grayColor,
  };
};

/**
 * @param {LightScheme | DarkScheme} scheme
 * @param {string} optKey lets you move the color from text color to background, etc
 * @returns CSS color styles for Text, muted elements
 */
const makeTextColors = (scheme, optKey = 'color') => {
  return {
    [optKey]: scheme.textBlue,
  };
};

/**
 * @param {LightScheme | DarkScheme} scheme
 * @param {string} optKey lets you move the color from text color to background, etc
 * @param {string} type pass a case type and based on that return theme color
 * @returns color code for use with your any type of CSS
 */
const makeBackgroundAndColors = (scheme, optKey = 'color', type='heading') => {
  switch(type){
    case 'heading':
      return {
        [optKey]: scheme.grayHeadingBg,
      };
    case 'boxbg':
      return {
        [optKey]: scheme.grayBg,
      };
    case 'overlay':
      return {
        [optKey]: scheme.overlayBlack,
      };
    case 'subtext':
      return {
        [optKey]: scheme.SoftBlack,
      };
    case 'bluegray':
      return {
        [optKey]: scheme.btnPrimary,
      };
    case 'textmodal':
      return {
        [optKey]: scheme.inputText
      };
     case 'modalsubtext':
      return {
        [optKey]: scheme.grayModal,
      };
      case 'progressBg':
      return {
        [optKey]: scheme.btnSecHover,
      };
    default:
      return {
        [optKey]: scheme.textBlue,
      }
  }
};

/**
 * @param {LightScheme | DarkScheme} scheme
 * @param {string} type pass a case type and based on that return theme color
 * @returns CSS color styles for Text, muted elements
 */
const makeBackgroundAndColorsMUIGrid = (scheme, type='heading') => {
  switch(type){
    case 'heading':
      return  scheme.SoftBlack;
    case 'graycolor':
      return scheme.grayColor;
    case 'blueGray':
      return scheme.btnPrimary;
    case 'graymuibg':
      return scheme.grayMui;
    case 'graymuipagingitem':
      return scheme.grayMuiPaging;
    case 'graymuipagingseleted':
      return scheme.grayMuiPagingSeleted;
    default:
      return  scheme.textBlue;
  }
};

/**
 * @param {LightScheme | DarkScheme} scheme
 * @returns CSS color styles for secondary button inputs
 */
const makeSecondaryButtonColors = (scheme) => {
  return {
    bg: scheme.btnSecondary,
    color: scheme.btnSecText,
    border: '1px solid',
    borderColor:scheme.btnSecText,
    _hover: {
      bg: scheme.btnSecHover,
      color: scheme.btnSecHoverText,
    },
  };
};

/**
 * @param {LightScheme | DarkScheme} scheme
 * @param {string} type pass a case type and based on that return theme color
 * @returns color code to use with any CSS
 */
const makeBackgroundAndColorsReturn = (scheme, type='heading') => {
  switch(type){
    case 'greenDark':
      return  scheme.greenDark; 
    case 'bluegray':
      return scheme.inputBorderAccent;
    case 'graylight':
      return scheme.grayLight;
    case 'softblack':
      return scheme.SoftBlack;
    case 'grayModal':
        return scheme.grayModal;
    case 'grayBg':
        return scheme.grayBg;
    case 'whiteColor':
        return scheme.inputBg;
    case 'grayColor':
        return scheme.grayColor;
    case 'btnBorderBlue':
        return scheme.btnBorderBlue;
    case 'btnBgDarkBlue':
      return scheme.btnBgDarkBlue;
    case 'disableTxtColor':
        return scheme.grayMuiPaging;
    case 'bgFooterGrey':
        return scheme.btnSecHover;
    case 'lightGrey': 
        return scheme.lightGrey; 
    case 'darkBlueText':
      return scheme.darkBlueText;
    case 'lightBlue':
      return scheme.blueLight;
    case 'yellowTxtBg':
      return scheme.yellowTxtBg;
    case 'softPurple': 
      return scheme.softPurple; 
    default:
      return scheme.bgMain;
  }
};

// ----------------------------------------------------------------
// utility to add style builders to individual themes. use `curryFunctions` to
// curry with a specific theme and bind to the theme itself.
const functions = {
  makeBackgroundColors,
  makeInputColors,
  makeLinkColors,
  makeButtonColors,
  makeSpinnerColors,
  makeErrorColors,
  makeSubtleColors,
  makeProgressBgColors,
  makeTextColors,
  makeBackgroundAndColors,
  makeBackgroundAndColorsMUIGrid,
  makeSecondaryButtonColors,
  makeBackgroundAndColorsReturn,
};
const curryFunctions = (functions, scheme) => {
  const list = Object.entries(functions).map(([name, fn]) => {
    return [name, (...args) => fn(scheme, ...args)];
  });
  return list.reduce((prev, [name, fn]) => {
    return {
      ...prev,
      [name]: fn,
    };
  }, {});
};
// ----------------------------------------------------------------
// ****************************************************************
const LightScheme = {
  bgMain: 'white',
  bgAccent: 'gray.50',
  bgDir: 'tl',
  textMain: 'black',
  textLight: 'gray.400',
  inputBg: WonderColors.WhiteBlue,
  inputBorder: WonderColors.grayLight,
  inputText: WonderColors.MiddleGray1,
  inputPlaceholder: 'blackAlpha.400',
  inputAccent: WonderColors.SoftBlack,
  inputBorderAccent: WonderColors.BlueGray,
  inputPlaceholderAccent: 'black',
  link: 'blue.500',
  linkHover: 'blue.700',
  btnPrimary: WonderColors.BlueGray,
  btnText: 'gray.100',
  btnHover: WonderColors.BrightBlue,
  btnHoverText: 'white',
  spinnerColor: WonderColors.BlueGray,
  invalidColor: 'red.500',
  grayColor: WonderColors.Gray85,
  textBlue: WonderColors.LightBlue,
  grayHeadingBg: WonderColors.GrayHeadingBg,
  grayBg: WonderColors.GrayBg,
  overlayBlack: 'black',
  SoftBlack: WonderColors.SoftBlack,
  grayMui: WonderColors.grayMui,
  grayMuiPaging: WonderColors.grayMuiPaging,
  grayMuiPagingSeleted: WonderColors.grayMuiPagingSeleted,
  btnSecondary:WonderColors.btnSecondary,
  btnSecText:WonderColors.btnSecText,
  btnSecHover:WonderColors.btnSecHover,
  btnSecHoverText:WonderColors.btnSecHoverText,
  grayModal:WonderColors.grayModal,
  greenDark: WonderColors.greenDark,
  grayLight:WonderColors.grayLight,
  btnBorderBlue: WonderColors.btnBorderBlue,
  btnBgDarkBlue: WonderColors.btnBgDarkBlue,
  lightGrey: WonderColors.lightGrey,
  darkBlueText: WonderColors.darkBlueText,
  yellowTxtBg: WonderColors.yellowTxtBg,
  blueLight: WonderColors.BlueLight,
  softPurple: WonderColors.softPurple
};
Object.assign(LightScheme, curryFunctions(functions, LightScheme));

// ****************************************************************
const DarkScheme = {
  bgMain: 'gray.800',
  bgAccent: WonderColors.BlackNavy,
  bgDir: 'br',
  textMain: 'gray.100',
  textLight: 'whiteAlpha.500',
  inputBg: WonderColors.BlackNavy,
  inputBorder: 'whiteAlpha.500',
  inputText: 'whiteAlpha.900',
  inputPlaceholder: 'whiteAlpha.300',
  inputAccent: 'blue.500',
  inputBorderAccent: 'whiteAlpha.600',
  inputPlaceholderAccent: 'whiteAlpha.400',
  link: 'blue.300',
  linkHover: 'blue.100',
  btnPrimary: 'blue.600',
  btnText: 'gray.100',
  btnHover: 'blue.500',
  btnHoverText: 'gray.200',
  spinnerColor: 'blue.500',
  invalidColor: 'red.300',
  grayColor: WonderColors.Gray85,
  textBlue: WonderColors.LightBlue,
  grayHeadingBg: WonderColors.GrayHeadingBg,
  grayBg: WonderColors.GrayBg,
  overlayBlack: 'black',
  SoftBlack: WonderColors.SoftBlack,
  grayMui: WonderColors.grayMui,
  grayMuiPaging: WonderColors.grayMuiPaging,
  grayMuiPagingSeleted: WonderColors.grayMuiPagingSeleted,
  btnSecondary:WonderColors.btnSecondary,
  btnSecText:WonderColors.btnSecText,
  btnSecHover:WonderColors.btnSecHover,
  btnSecHoverText:WonderColors.btnSecHoverText,
  grayModal:WonderColors.grayModal,
  greenDark: WonderColors.greenDark,
  grayLight:WonderColors.grayLight,
  btnBorderBlue: WonderColors.btnBorderBlue,
  btnBgDarkBlue: WonderColors.btnBgDarkBlue,
  softPurple: WonderColors.softPurple,
};
Object.assign(DarkScheme, curryFunctions(functions, DarkScheme));
// ****************************************************************
const ColorSchemes = { Light: LightScheme, Dark: DarkScheme };
// ****************************************************************

/**
 * given the color function, determines whether to use light or dark mode, and returns the css styles
 * @example useColorScheme('makeButtonColors');
 * @param {string} schemeFnName name of function that generates the styles. one of the listed ones above.
 * @param  {...any} args additional args like `optKey`
 * @returns
 */
const useColorScheme = (schemeFnName, ...args) => {
  return useColorModeValue(
    LightScheme[schemeFnName](...args),
    DarkScheme[schemeFnName](...args)
  );
};

/**
 * use for figuring out which color mode/scheme is being used, light or dark.
 * @returns same as useColorMode (from Chakra) and adds isDark/isLight helpers
 */
const useColorMode = () => {
  const colorModeObj = useColorModeOriginal();
  return {
    ...colorModeObj,
    /**
     * @returns {boolean}
     */
    isDark: () => {
      return colorModeObj.colorMode === SCHEME_NAMES.DARK;
    },
    /**
     * @returns {boolean}
     */
    isLight: () => {
      return colorModeObj.colorMode === SCHEME_NAMES.LIGHT;
    },
  };
};

export { ColorSchemes, useColorScheme, useColorMode };
