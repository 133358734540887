import { Box, Image } from '@chakra-ui/react';
import { HEADER_SIZE } from 'theme/chakra/layout';

interface LeftLogoProps {
  networkLogo?: string;
}

const LeftLogo: React.FC<LeftLogoProps> = ({ networkLogo = '/main-logo.svg' }) => {
  return (
    <Box flex="1 1 auto" h={HEADER_SIZE}>
      <Image src={networkLogo} />
    </Box>
  );
};

export default LeftLogo;
