// search Bar
import { ReactComponent as IconSearch } from 'assets/images/iconSearch.svg';
import React, { useEffect, useRef } from 'react';
import { Box, Input, InputGroup, InputLeftElement, InputProps, InputRightElement, Stack } from '@chakra-ui/react';
import { useColorScheme } from 'theme/chakra/colors';
import { CloseIcon } from '@chakra-ui/icons';
import { useAppDispatch } from 'hooks/reduxHook';
import { getCoordinatesByAddress, updateAutocompleteAddress } from 'reduxApp/features/geoSlice';
import { Coordinates } from 'api/dataObjects/subObjects/Coodinates';
import '../Grid/SearchBar/SearchBar.css';
import './geoLocationSearchBar.css';

type IFormSearchProps = {
  setSearchValue: Function;
  placeHolderValue?: string;
  searchValue?: string;
  setSelectedSearch?: Function;
} & InputProps;

declare global {
  interface Window {
    google: any;
  }
}

const GeoLocationSearchBar: React.FC<IFormSearchProps> = ({
  setSearchValue,
  placeHolderValue,
  searchValue,
  setSelectedSearch,
  ...props
}) => {
  const blueBorder = useColorScheme('makeBackgroundAndColorsReturn', 'bluegray');
  const inputRef = useRef<HTMLInputElement>();
  const dispatch = useAppDispatch();

  const handleSearch = (e: any) => {
    setSearchValue(e.target.value);
  };

  const handleClose = () => {
    setSearchValue('');
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };
  const autoCompleteRef = useRef();
  useEffect(() => {
    const options = {
      componentRestrictions: { country: 'us' },
      fields: ['address_components', 'geometry', 'formatted_address'],
      //types: ['address'],
    };
    const autocomplete = new window.google.maps.places.Autocomplete(inputRef.current, options);
    autoCompleteRef.current = autocomplete;

    // reset if not selected
    if (setSelectedSearch) setSelectedSearch(false);
    //Add listener to detect autocomplete selection
    google.maps.event.addListener(autocomplete, 'place_changed', function () {
      try {
        let place = autocomplete.getPlace();
        let addressChoosed = inputRef?.current?.value || '';

        setSearchValue(addressChoosed || '');
        if (place.geometry) {
          const addressCoordinates: Coordinates = {
            lat: place.geometry.location.lat(),
            lon: place.geometry.location.lng(),
          };

          const fetchCoordinates = async () => {
            await dispatch(getCoordinatesByAddress(addressCoordinates));
            await dispatch(
              updateAutocompleteAddress({
                formattedAddress: addressChoosed,
                coordinates: addressCoordinates,
                searchRefresh: true,
              })
            );
            if (setSelectedSearch) setSelectedSearch(true);
          };
          fetchCoordinates();
        } else {
          dispatch(
            updateAutocompleteAddress({
              formattedAddress: addressChoosed || place?.name || '',
              searchRefresh: true,
              coordinates: { lat: 0, lon: 0 },
            })
          );
          if (setSelectedSearch) setSelectedSearch(true);
        }
      } catch (error) {
        console.log(error);
      }
      // center = new google.maps.LatLng(place.geometry.location.lat(),place.geometry.location.lng());
      // map.setCenter(center);
      // map.setZoom(5);
    });
  }, [setSelectedSearch, dispatch, setSearchValue]);

  /*  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      const autocomplete = inputRef.current;
      if (autocomplete) {
        console.log('99999', autocomplete);
        // setSelectedSearch && setSelectedSearch(true);
      }
    }
  }; */
  return (
    <Box {...props}>
      <Stack spacing={4} mt={11}>
        <InputGroup>
          <InputLeftElement
            pointerEvents="none"
            mt={'-3px'}
            children={<IconSearch stroke={blueBorder} style={{ marginTop: 7 }} />}
          />
          <Input
            onChange={handleSearch}
            ref={inputRef}
            type="text"
            placeholder={placeHolderValue || 'Search by name'}
            {...props}
            border={`1px solid ${blueBorder}`}
            _hover={{ borderColor: blueBorder }}
            value={searchValue}
            // onBlur={updateParentState}
            //onKeyDown={handleKeyDown}
          />
          {searchValue?.length && (
            <InputRightElement children={<CloseIcon onClick={handleClose} boxSize={3} color={blueBorder} />} />
          )}
        </InputGroup>
      </Stack>
    </Box>
  );
};

export default GeoLocationSearchBar;
