import { Expose } from 'class-transformer';

export class User {
  constructor(obj: object = {}) {
    Object.assign(this, obj);
  }

  @Expose({ name: 'user_id' })
  public userId?: string;

  @Expose({ name: 'active' })
  public active?: boolean;

  @Expose({ name: 'email' })
  public email: string = '';

  @Expose({ name: 'first_name' })
  public firstName: string = '';

  @Expose({ name: 'last_name' })
  public lastName: string = '';
}
