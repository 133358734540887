import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { ccProvidersByAddress } from 'api/ccProviderSvc';
import { iHit, iListing } from 'types/ccProvider';
import { setChildCareDistanceFilterAction } from './filterSlice';
import { updateAutocompleteAddress } from './geoSlice';

type IDistance = {
  submitted_miles: number;
  miles: number;
};
export interface ICCProvidersData {
  address: string;
  providers?: iHit[];
  loading: boolean;
  providerById?: {
    [key: string]: iListing;
  };
  distance: IDistance;
}

const initialState: ICCProvidersData = {
  providers: [],
  loading: false,
  providerById: {},
  address: '',
  distance: { submitted_miles: 5, miles: 5 },
};

export const fetchCCproviders = createAsyncThunk(
  'get/fetchCCproviders',
  async (data: { network_id: string; address: string; distance: { miles: number } }, { rejectWithValue, dispatch }) => {
    try {
      //const responses = await axios.get("posts");
      // console.log('inside Slice cc provider slice==> ', data)
      const response = await ccProvidersByAddress(data);
      /* Run this dispatch for update filter updated radius if api updated and for updating fitlerRefresh false */
      dispatch(setChildCareDistanceFilterAction(JSON.stringify({ max_miles: response?.distance?.miles || 5 })));
      /* Run this dispatch if we only send address not coordinates then update coordinates from api response for map*/
      dispatch(updateAutocompleteAddress({ coordinates: response.coordinates, searchRefresh: false }));
      return JSON.stringify({
        providers: response.result.hits,
        address: response.address,
        distance: response.distance,
      });
    } catch (err: any) {
      return rejectWithValue({ error: err.message });
    }
  }
);

const ccProviderSlice = createSlice({
  name: 'ccProviders',
  initialState: initialState,
  reducers: {
    resetCCProvider: (state) => {
      state.address = initialState.address;
      state.distance = initialState.distance;
      state.providers = initialState.providers;
      state.loading = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCCproviders.fulfilled, (state, action) => {
      const searchAPIResponse = JSON.parse(action.payload);

      state.address = searchAPIResponse.address;
      state.distance = searchAPIResponse.distance;
      state.providers = searchAPIResponse.providers;

      const providerById = {};
      searchAPIResponse.providers.forEach((p: iHit) => {
        //Object.assign(providerById, { [p._id]: p._source });
        Object.assign(providerById, { [p._id]: { sort: p.sort, ...p._source } });
      });
      state.providerById = providerById;

      state.loading = false;
    });
    builder.addCase(fetchCCproviders.pending, (state, action) => {
      // state.providers = [];
      state.loading = true;
    });
    builder.addCase(fetchCCproviders.rejected, (state, action) => {
      state.address = initialState.address;
      state.distance = initialState.distance;
      state.providers = initialState.providers;
      state.loading = false;
    });
  },
});

// Export actions
export const { resetCCProvider } = ccProviderSlice.actions;

// Export reducer
export default ccProviderSlice.reducer;
