import { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { ReactComponent as IconGlobeLang } from 'assets/images/globe_lng.svg';
import { ReactComponent as ChevronDownIcon } from 'assets/images/chevron-up.svg';
import useAppLanguage from 'hooks/useAppLanguage';

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

export default function LangaugeSubMenu() {
  const { languages, changeLanguage, currentLanguage } = useAppLanguage();
  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button className="inline-flex w-full justify-center gap-x-1.5">
          <div>
            <IconGlobeLang />
          </div>
          <div className="hidden sm:block">
            <div className="flex font-bold gap-1 items-center">
              <div>{currentLanguage.label}</div>
              <div>
                <ChevronDownIcon className="h-3 w-3 " />
              </div>
            </div>
          </div>
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-10 mt-2 w-36 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">
            {Object.entries(languages).map(([code, { label }]) => {
              return (
                <Menu.Item key={code}>
                  {({ active }) => (
                    <div
                      className={classNames(
                        currentLanguage.code === code ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                        'block px-4 py-2 text-sm'
                      )}
                      onClick={() => {
                        changeLanguage(code); // Language will be set here
                      }}
                    >
                      {label}
                    </div>
                  )}
                </Menu.Item>
              );
            })}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
