import { XMarkIcon, ChevronLeftIcon } from '@heroicons/react/24/solid';
import { FormProvider, useForm } from 'react-hook-form';
import SlideCheckbox from './SlideCheckbox';
import { Filters } from 'api/filters/Filters';
import {
  setChildCareAgeFilterAction,
  setChildCarePaymentFilterAction,
  setChildCareLanguageFilterAction,
} from 'reduxApp/features/filterSlice';
import { useAppSelector, useAppDispatch } from 'hooks/reduxHook';
import { Fragment } from 'react';
import { getKeysOfTrueValues } from 'utils/functions';
import { Age } from 'api/filters/Age';
import { Payment } from 'api/filters/Payment';
import { Language } from 'api/filters/Language';
import useAppLanguage from 'hooks/useAppLanguage';

interface FilterModalProps {
  onClose: Function;
  onToggle?: Function;
  filterData?: any;
  filterTitle: string;
  fieldName?: string;
}
const FilterModal: React.FC<FilterModalProps> = ({ onClose, onToggle, filterData, filterTitle, fieldName }) => {
  const dispatch = useAppDispatch();
  const { translate } = useAppLanguage();
  const filtersReduxData: Filters = useAppSelector(
    (state) => state?.childCareFilter?.childCareFinderFilter || new Filters()
  );
  const ageMethods = useForm<any>({
    mode: 'all',
    defaultValues: {
      age: getKeysOfTrueValues(filtersReduxData.age),
      payment: getKeysOfTrueValues(filtersReduxData.payment),
      language: getKeysOfTrueValues(filtersReduxData.language),
    },
  });
  let filters = filtersReduxData;
  const onSubmitAgeFilter = ageMethods.handleSubmit(async (data) => {
    console.log('filters', filters, fieldName);
    if (fieldName === 'age') {
      const choosedAgeFilter = data[fieldName].reduce((acc: any, cur: string) => {
        acc[cur] = true;
        return acc;
      }, {});
      await dispatch(setChildCareAgeFilterAction(JSON.stringify(choosedAgeFilter)));
    } else if (fieldName === 'payment') {
      const choosedPaymentFilter = data[fieldName].reduce((acc: any, cur: string) => {
        acc[cur] = true;
        return acc;
      }, {});
      await dispatch(setChildCarePaymentFilterAction(JSON.stringify(choosedPaymentFilter)));
    } else if (fieldName === 'language') {
      const choosedLanguageFilter = data[fieldName].reduce((acc: any, cur: string) => {
        acc[cur] = true;
        return acc;
      }, {});
      await dispatch(setChildCareLanguageFilterAction(JSON.stringify(choosedLanguageFilter)));
    }
    onClose();
  });

  const resetFilterForm = async () => {
    if (fieldName === 'age') {
      await dispatch(setChildCareAgeFilterAction(JSON.stringify(new Age())));
    } else if (fieldName === 'payment') {
      await dispatch(setChildCarePaymentFilterAction(JSON.stringify(new Payment())));
    } else if (fieldName === 'language') {
      await dispatch(setChildCareLanguageFilterAction(JSON.stringify(new Language())));
    }
    onClose();
  };

  return (
    <div className="relative bottom-0 flex flex-col flex-1 w-full bg-white focus:outline-none rounded-t-xl sm:rounded-xl text-start">
      {/* Slide-over header */}
      <div className="px-4 py-3 bg-white-500 flex flex-row justify-between">
        <div className="flex">
          {onToggle && <ChevronLeftIcon className="h-6 w-6 text-tail-text cursor-pointer" onClick={() => onToggle()} />}
          <div className=" ml-5 font-bold text-gray-800 text-xl leading-none">{filterTitle}</div>
        </div>
        <XMarkIcon className="h-6 w-6 text-tail-text cursor-pointer" onClick={() => onClose()} />
      </div>

      {/* Slide-over body */}

      <FormProvider {...ageMethods}>
        <form onSubmit={onSubmitAgeFilter}>
          {filterData &&
            filterData.map((checkboxData: any, key: number) => (
              <Fragment key={key}>
                <SlideCheckbox data={checkboxData} keyIndex={key} fieldName={fieldName || ''} />
              </Fragment>
            ))}

          {/* Slide-over footer */}
          <div className="px-4 py-3 flex justify-between text-sm">
            <button
              className="mx-1 px-8 pt-1 pb-1 flex justify-center space-x-10 border border-tail-blue rounded-lg text-tail-blue bolder font-bold "
              onClick={() => {
                resetFilterForm();
              }}
              type="reset"
            >
              {translate('Remove Filter')}
            </button>
            <button
              className="mx-1 px-8 pt-1 pb-1 flex justify-center space-x-10 bg-tail-blue rounded-lg text-white bolder font-bold"
              type="submit"
            >
              {translate('Apply Filters')}
            </button>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default FilterModal;
