// users.service.ts
import http from './httpService/http';
import { paths } from './paths';
import { ChildSchedule } from './dataObjects/ChildSchedule';
import { instanceToPlain, plainToInstance } from 'class-transformer';

const schedulePath = paths.byApplication.child_schedules;

export const fetchSchedules = async (appId: string): Promise<ChildSchedule[]> => {
  const response = await http.get<object, any>(schedulePath(appId));
  return plainToInstance(ChildSchedule, response.data as []) as ChildSchedule[];
};

export const writeSchedules = async (appId: string, schedules: ChildSchedule[]): Promise<ChildSchedule[]> => {
  const scheduleArr: ChildSchedule[] = [];
  schedules.forEach((schedule) => {
    // Hard set childScheduleId to childId;
    schedule.childScheduleId = schedule.childId;
    scheduleArr.push(new ChildSchedule(schedule));
  });
  const objArr = instanceToPlain(scheduleArr);
  const response = await http.put<object, any>(schedulePath(appId), objArr as object[]);
  return plainToInstance(ChildSchedule, response.data as []) as ChildSchedule[];
};
