import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { createAddress, fetchAddress, updateAddress } from 'api/addressSvc';
import { createAdults, fetchAdults, updateAdults } from 'api/adultsSvc';
import { fetchApplication, createApplication, updateApplication } from 'api/applicationSvc';
import { fetchSchedules, writeSchedules } from 'api/childrenScheduleSvc';
import { createChildren, fetchChildren, updateChildren } from 'api/childrenSvc';
import { Address } from 'api/dataObjects/Address';
import { Adult } from 'api/dataObjects/Adult';
import { Application } from 'api/dataObjects/Application';
import { Child } from 'api/dataObjects/Child';
import { childApiDataConvertForRedux, convertProfileData, convertScheduleData } from 'utils/functions';
import { ChildSchedule } from 'api/dataObjects/ChildSchedule';
import { IApplicantDetails, ISchedule, IweekSchedule, IChildFormType } from 'types/application';

interface IParentData {
  applicationDetails: Application;
  profile: IApplicantDetails;
  address: Address;
  child: IChildFormType[];
  schedules: ISchedule[];
  loading: boolean;
}

const initialState: IParentData = {
  applicationDetails: {} as Application,
  profile: {} as IApplicantDetails,
  address: {} as Address,
  child: [],
  schedules: [],
  loading: false,
};

const parentApplicationSlice = createSlice({
  name: 'parentApplication',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      /** get Application Details */
      .addCase(getApplicationDetails.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getApplicationDetails.fulfilled, (state, action) => {
        const appDetails = JSON.parse(action.payload);
        state.applicationDetails = appDetails;
        state.loading = false;
      })
      .addCase(getApplicationDetails.rejected, (state, action) => {
        state.applicationDetails = {} as Application;
        state.loading = false;
      })

      /** Post Application Details */
      .addCase(createApplicationDetails.pending, (state, action) => {
        state.loading = true;
        //TODO
      })
      .addCase(createApplicationDetails.fulfilled, (state, action) => {
        const appDetails = JSON.parse(action.payload);
        state.applicationDetails = appDetails;
        state.loading = false;
      })
      .addCase(createApplicationDetails.rejected, (state, action) => {
        state.loading = false;
      })

      /** Put Application Details */
      .addCase(updateApplicationDetails.pending, (state, action) => {
        state.loading = true;
        //TODO
      })
      .addCase(updateApplicationDetails.fulfilled, (state, action) => {
        state.applicationDetails = initialState.applicationDetails;
        state.profile = initialState.profile;
        state.address = initialState.address;
        state.child = initialState.child;
        state.schedules = initialState.schedules;
        state.loading = false;
      })
      .addCase(updateApplicationDetails.rejected, (state, action) => {
        state.applicationDetails = {} as Application;
        state.loading = false;
      })

      /** get Application Details */
      .addCase(getParentProfile.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getParentProfile.fulfilled, (state, action) => {
        const profile = JSON.parse(action.payload);
        state.profile = profile;
        state.loading = false;
      })
      .addCase(getParentProfile.rejected, (state, action) => {
        state.profile = {} as IApplicantDetails;
        state.loading = false;
      })

      /** Post Application Details */
      .addCase(createParentProfile.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(createParentProfile.fulfilled, (state, action) => {
        const profile = JSON.parse(action.payload);
        state.profile = profile;
        state.loading = false;
      })
      .addCase(createParentProfile.rejected, (state, action) => {
        state.profile = {} as IApplicantDetails;
        state.loading = false;
      })
      .addCase(updateParentProfile.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(updateParentProfile.fulfilled, (state, action) => {
        const profile = JSON.parse(action.payload);
        state.profile = profile;
        state.loading = false;
      })
      .addCase(updateParentProfile.rejected, (state, action) => {
        state.profile = {} as IApplicantDetails;
        state.loading = false;
      })

      /** Address Details reducers */
      .addCase(createProfileAddress.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(createProfileAddress.fulfilled, (state, action) => {
        const address = JSON.parse(action.payload);
        state.address = address;
        state.loading = false;
      })
      .addCase(createProfileAddress.rejected, (state, action) => {
        state.address = {} as Address;
        state.loading = false;
      })

      /** PUT Application address Details */
      .addCase(udpateProfileAddress.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(udpateProfileAddress.fulfilled, (state, action) => {
        const address = JSON.parse(action.payload);
        state.address = address;
        state.loading = false;
      })
      .addCase(udpateProfileAddress.rejected, (state, action) => {
        state.address = {} as Address;
        state.loading = false;
      })

      /** Get Application address Details */
      .addCase(getProfileAddress.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getProfileAddress.fulfilled, (state, action) => {
        let address = JSON.parse(action.payload);
        state.address = typeof address === 'object' ? address : {};
        state.loading = false;
      })
      .addCase(getProfileAddress.rejected, (state, action) => {
        state.address = {} as Address;
        state.loading = false;
      })

      /** get Child Details */
      .addCase(getChildAction.pending, (state, action) => {
        //TODO
      })
      .addCase(getChildAction.fulfilled, (state, action) => {
        const childResponseData = JSON.parse(action.payload);
        const changedChild = childApiDataConvertForRedux(childResponseData);
        state.child = changedChild;
      })
      .addCase(getChildAction.rejected, (state, action) => {
        //state.child = [];
      })

      /** Post Child Details */
      .addCase(createChildAction.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(createChildAction.fulfilled, (state, action) => {
        const childResponseData = JSON.parse(action.payload);
        const changedChild = childApiDataConvertForRedux(childResponseData);
        state.child = changedChild;
        state.loading = false;
      })
      .addCase(createChildAction.rejected, (state, action) => {
        state.loading = false;
      })

      /** put Child Details */
      .addCase(updateChildAction.pending, (state, action) => {
        //TODO
        state.loading = true;
      })
      .addCase(updateChildAction.fulfilled, (state, action) => {
        const childResponseData = JSON.parse(action.payload);
        const changedChild = childApiDataConvertForRedux(childResponseData);
        state.child = changedChild;
        state.loading = false;
      })
      .addCase(updateChildAction.rejected, (state, action) => {
        //state.child = [];
        state.loading = false;
      })

      /** schedules */
      .addCase(getChildrenSchedules.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getChildrenSchedules.fulfilled, (state, action) => {
        let scheduleArr = JSON.parse(action.payload);
        let childState = state.child;
        let isScheduleSelected = false;
        scheduleArr.forEach((sch: ISchedule, index: number) => {
          sch?.weekSchedule?.forEach((weekSchedule: IweekSchedule) => {
            if (weekSchedule?.dropOff?.value) {
              isScheduleSelected = true;
            }
          });
          if (sch.childId === childState[index].childId)
            return (scheduleArr[index].firstName = childState[index].firstName);
        });
        if (isScheduleSelected) {
          state.schedules = scheduleArr;
        }
        state.loading = false;
      })
      .addCase(getChildrenSchedules.rejected, (state, action) => {
        state.loading = false;
        state.schedules = [];
      })

      /** create schedules */
      .addCase(createChildrenSchedules.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(createChildrenSchedules.fulfilled, (state, action) => {
        state.schedules = JSON.parse(action.payload);
        state.loading = false;
      })
      .addCase(createChildrenSchedules.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

// Export reducer
export default parentApplicationSlice.reducer;

// get application ID
export const getApplicationDetails = createAsyncThunk(
  'get/applicationId',
  async (appId: string, { rejectWithValue }) => {
    try {
      const response = await fetchApplication(appId);
      return JSON.stringify(response);
    } catch (err: any) {
      return rejectWithValue({ error: err.message });
    }
  }
);

// post application ID
export const createApplicationDetails = createAsyncThunk(
  'post/createApplication',
  async (app: Application, { rejectWithValue }) => {
    try {
      const response = await createApplication(app);
      return JSON.stringify(response);
    } catch (err: any) {
      return rejectWithValue({ error: err.message });
    }
  }
);

// Put application ID
export const updateApplicationDetails = createAsyncThunk(
  'put/updateApplication',
  async (app: Application, { rejectWithValue }) => {
    try {
      const response = await updateApplication(app.applicationId || '', app);
      return JSON.stringify(response);
    } catch (err: any) {
      return rejectWithValue({ error: err.message });
    }
  }
);

// get Profile Details
export const getParentProfile = createAsyncThunk('get/getParentProfile', async (appId: string, { rejectWithValue }) => {
  try {
    const response = await fetchAdults(appId);
    if (response.length) return JSON.stringify(convertProfileData(response));
    return JSON.stringify(response);
  } catch (err: any) {
    return rejectWithValue({ error: err.message });
  }
});

export const createParentProfile = createAsyncThunk(
  'post/createParentProfile',
  async (payload: Adult[], { rejectWithValue }) => {
    try {
      const response = await createAdults(payload[0].applicationId, payload);
      return JSON.stringify(convertProfileData(response));
    } catch (err: any) {
      return rejectWithValue({ error: err.message });
    }
  }
);

export const updateParentProfile = createAsyncThunk(
  'put/updateParentProfile',
  async (payload: Adult[], { rejectWithValue }) => {
    try {
      const response = await updateAdults(payload[0].applicationId, payload);
      return JSON.stringify(convertProfileData(response));
    } catch (err: any) {
      return rejectWithValue({ error: err.message });
    }
  }
);

export const createProfileAddress = createAsyncThunk(
  'post/createProfileAddress',
  async (payload: Address, { rejectWithValue }) => {
    try {
      const response = await createAddress(payload.applicationId, payload);
      return JSON.stringify(response);
    } catch (err: any) {
      return rejectWithValue({ error: err.message });
    }
  }
);

export const udpateProfileAddress = createAsyncThunk(
  'put/updateProfileAddress',
  async (payload: Address, { rejectWithValue }) => {
    try {
      const response = await updateAddress(payload.applicationId, payload);
      return JSON.stringify(response);
    } catch (err: any) {
      return rejectWithValue({ error: err.message });
    }
  }
);

export const getProfileAddress = createAsyncThunk(
  'get/getProfileAddress',
  async (appId: string, { rejectWithValue }) => {
    try {
      const response = await fetchAddress(appId);
      return JSON.stringify(response);
    } catch (err: any) {
      return rejectWithValue({ error: err.message });
    }
  }
);
// get Child Details
export const getChildAction = createAsyncThunk('get/getChildAction', async (appId: string, { rejectWithValue }) => {
  try {
    const response: Child[] = await fetchChildren(appId);
    return JSON.stringify(response);
  } catch (err: any) {
    return rejectWithValue({ error: err.message });
  }
});

// Create Child
export const createChildAction = createAsyncThunk('post/createChildAction', async (data: any, { rejectWithValue }) => {
  try {
    const response: Child[] = await createChildren(data.applicationId, data.payload);
    return JSON.stringify(response);
  } catch (err: any) {
    return rejectWithValue({ error: err.message });
  }
});

// update Child
export const updateChildAction = createAsyncThunk('put/updateChildAction', async (data: any, { rejectWithValue }) => {
  try {
    const response: Child[] = await updateChildren(data.applicationId, data.payload);
    return JSON.stringify(response);
  } catch (err: any) {
    return rejectWithValue({ error: err.message });
  }
});

export const getChildrenSchedules = createAsyncThunk(
  'get/childrenSchedules',
  async (appId: string, { rejectWithValue }) => {
    try {
      const response: ChildSchedule[] = await fetchSchedules(appId);
      const result: ISchedule[] = convertScheduleData(response);
      return JSON.stringify(result);
    } catch (err: any) {
      return rejectWithValue({ error: err.message });
    }
  }
);

export const createChildrenSchedules = createAsyncThunk(
  'put/childrenSchedules',
  async (payload: any, { rejectWithValue }) => {
    try {
      const response: ChildSchedule[] = await writeSchedules(payload[0].applicationId, payload);
      const result: ISchedule[] = convertScheduleData(response);
      return JSON.stringify(result);
    } catch (err: any) {
      return rejectWithValue({ error: err.message });
    }
  }
);
