// users.service.ts
import http from './httpService/http';
import { paths } from './paths';
import { Application } from './dataObjects/Application';
import { instanceToPlain, plainToInstance } from 'class-transformer';

const appPath = paths.objects.application;
const networkApplicationPath = paths.byNetwork.applications;

export const fetchApplication = async (appId: string): Promise<Application> => {
  const response = await http.get<object, any>(appPath(appId));
  return plainToInstance(Application, response.data as {});
};

export const createApplication = async (app: Application): Promise<Application> => {
  const obj = instanceToPlain(new Application(app));
  const response = await http.post<object, any>(appPath(''), obj as object);
  return plainToInstance(Application, response.data as {}) as Application;
};

export const updateApplication = async (appId: string, app: Application): Promise<Application> => {
  const obj = instanceToPlain(new Application(app));
  const response = await http.put<object, any>(appPath(appId), obj as object);
  return plainToInstance(Application, response.data as {}) as Application;
};

//export const deleteAdults = async (appId: string, user: Adult[]): Promise<Adult> => {
//  return await http.delete<Adult[], any>(adultPath(appId));
//};

export const fetchApplicationsList = async (networkId: string): Promise<Application> => {
  const response = await http.get<object, any>(networkApplicationPath(networkId));
  return plainToInstance(Application, response.data as {});
};

export const updateAllApplication = async (appId: string, app: Application): Promise<Application> => {
  const obj = instanceToPlain(new Application(app));
  const response = await http.put<object, any>(appPath(`${appId}/all`), obj as object);
  return plainToInstance(Application, response.data as {}) as Application;
};
