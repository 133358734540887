import { toast } from 'react-toastify';
//import useAppLanguage from 'hooks/useAppLanguage';

export function getErrorMessage(error: unknown, fallBackMessage: string = 'Unknown Error') {
  let errorObject: Error = {
    message: fallBackMessage,
    name: fallBackMessage,
  };
  if (error instanceof Error) {
    errorObject = error;
  }

  return errorObject;
}

export const ReactToastify = () => {
  const AddToast = (msg: string, type: 'error' | 'info') => {
    toast(msg, { type, toastId: 'error' });
  };

  return { AddToast };
};
