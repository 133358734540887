import { Box, Divider } from '@chakra-ui/react';
import { iHit } from 'types/ccProvider';
import CCProvider from './CCProvider';

type iProviderList = {
  providers: iHit[];
  handleInfo?: Function;
};

const ChildCareProvidersList: React.FC<iProviderList> = ({ providers, handleInfo }) => {
  // display the list of provides. by passing
  return (
    <>
      {providers &&
        providers.map((pItem: iHit, index: React.Key) => {
          return (
            <Box key={index} cursor={'pointer'}>
              <CCProvider pItem={pItem} handleInfoF={handleInfo} />
              <Divider />
            </Box>
          );
        })}
    </>
  );
};

export default ChildCareProvidersList;
