import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { Age } from 'api/filters/Age';
import { Filters } from 'api/filters/Filters';
import { Language } from 'api/filters/Language';
import { Payment } from 'api/filters/Payment';

// Create Slice combine the actions, initialState, and reducer into 1 function
type IinitialState = {
  childCareFinderFilter: Filters;
  refreshFilter?: boolean;
  loading: boolean;
};

const initialState: IinitialState = {
  childCareFinderFilter: JSON.parse(JSON.stringify(new Filters() as Filters)),
  refreshFilter: false,
  loading: false,
};

export const setChildCareAgeFilterAction = createAsyncThunk(
  'set/childCareFinderAgeFilter',
  async (filters: string, { rejectWithValue }) => {
    try {
      return filters;
    } catch (err: any) {
      return rejectWithValue({ error: err.data });
    }
  }
);
export const setChildCarePaymentFilterAction = createAsyncThunk(
  'set/childCareFinderPaymentFilter',
  async (filters: string, { rejectWithValue }) => {
    try {
      return filters;
    } catch (err: any) {
      return rejectWithValue({ error: err.data });
    }
  }
);
export const setChildCareLanguageFilterAction = createAsyncThunk(
  'set/childCareFinderLanguageFilter',
  async (filters: string, { rejectWithValue }) => {
    try {
      return filters;
    } catch (err: any) {
      return rejectWithValue({ error: err.data });
    }
  }
);

export const setChildCareDistanceFilterAction = createAsyncThunk(
  'set/childCareFinderDistanceFilter',
  async (filters: string, { rejectWithValue }) => {
    try {
      return filters;
    } catch (err: any) {
      return rejectWithValue({ error: err.data });
    }
  }
);

export const clearChildCareFilterAction = createAsyncThunk(
  'clear/childCareFinderFilter',
  async (filters: string, { rejectWithValue }) => {
    try {
      return filters;
    } catch (err: any) {
      return rejectWithValue({ error: err.data });
    }
  }
);

export const clearOneChildCareAgeFilterAction = createAsyncThunk(
  'clear/oneChildCareAgeFilterAction',
  async (filters: string, { rejectWithValue }) => {
    try {
      return filters;
    } catch (err: any) {
      return rejectWithValue({ error: err.data });
    }
  }
);

export const clearOneChildCarePaymentFilterAction = createAsyncThunk(
  'clear/oneChildCarePaymentFilterAction',
  async (filters: string, { rejectWithValue }) => {
    try {
      return filters;
    } catch (err: any) {
      return rejectWithValue({ error: err.data });
    }
  }
);

export const clearOneChildCareLanguageFilterAction = createAsyncThunk(
  'clear/oneChildCareLanguageFilterAction',
  async (filters: string, { rejectWithValue }) => {
    try {
      return filters;
    } catch (err: any) {
      return rejectWithValue({ error: err.data });
    }
  }
);

export const setChildCareAvailabilityFilterAction = createAsyncThunk(
  'set/childCareFinderAvailabilityFilter',
  async (filters: string, { rejectWithValue }) => {
    try {
      return filters;
    } catch (err: any) {
      return rejectWithValue({ error: err.data });
    }
  }
);

const filterSlice = createSlice({
  name: 'applications',
  initialState: initialState,
  extraReducers: (builder) => {
    builder.addCase(setChildCareAgeFilterAction.fulfilled, (state, action) => {
      // both `state` and `action` are now correctly typed
      // based on the slice state and the `pending` action creator
      const filterData = JSON.parse(action.payload);
      const ageObj = new Age();
      state.childCareFinderFilter.age = { ...ageObj, ...filterData };
      state.refreshFilter = true;
    });
    builder.addCase(setChildCareAgeFilterAction.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(setChildCareAgeFilterAction.rejected, (state, action: any) => {
      state.loading = false;
    });

    builder.addCase(setChildCarePaymentFilterAction.fulfilled, (state, action) => {
      // both `state` and `action` are now correctly typed
      // based on the slice state and the `pending` action creator
      const filterData = JSON.parse(action.payload);
      const paymentObj = new Payment();
      state.childCareFinderFilter.payment = { ...paymentObj, ...filterData };
      state.refreshFilter = true;
    });
    builder.addCase(setChildCarePaymentFilterAction.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(setChildCarePaymentFilterAction.rejected, (state, action: any) => {
      state.loading = false;
    });

    builder.addCase(setChildCareLanguageFilterAction.fulfilled, (state, action) => {
      // both `state` and `action` are now correctly typed
      // based on the slice state and the `pending` action creator
      const filterData = JSON.parse(action.payload);
      const languageObj = new Language();
      state.childCareFinderFilter.language = { ...languageObj, ...filterData };
      state.refreshFilter = true;
    });
    builder.addCase(setChildCareLanguageFilterAction.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(setChildCareLanguageFilterAction.rejected, (state, action: any) => {
      state.loading = false;
    });

    builder.addCase(setChildCareDistanceFilterAction.fulfilled, (state, action) => {
      // both `state` and `action` are now correctly typed
      // based on the slice state and the `pending` action creator
      const { refreshFilter, ...filterData } = JSON.parse(action.payload);
      //const distanceObj = new Distance();
      state.childCareFinderFilter.distance = { ...state.childCareFinderFilter.distance, ...filterData };
      if (refreshFilter) {
        state.refreshFilter = true;
      } else {
        state.refreshFilter = false;
      }
    });
    builder.addCase(setChildCareDistanceFilterAction.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(setChildCareDistanceFilterAction.rejected, (state, action: any) => {
      state.loading = false;
    });

    builder.addCase(clearChildCareFilterAction.fulfilled, (state, action) => {
      // both `state` and `action` are now correctly typed
      // based on the slice state and the `pending` action creator
      let data = JSON.parse(JSON.stringify(new Filters() as Filters));
      state.childCareFinderFilter = data;
      state.refreshFilter = true;
    });
    builder.addCase(clearChildCareFilterAction.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(clearChildCareFilterAction.rejected, (state, action: any) => {
      state.loading = false;
    });

    builder.addCase(clearOneChildCareAgeFilterAction.fulfilled, (state, action) => {
      // both `state` and `action` are now correctly typed
      // based on the slice state and the `pending` action creator
      const filterData = JSON.parse(action.payload);
      state.childCareFinderFilter.age = { ...state.childCareFinderFilter.age, ...filterData };
      state.refreshFilter = true;
    });
    builder.addCase(clearOneChildCareAgeFilterAction.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(clearOneChildCareAgeFilterAction.rejected, (state, action: any) => {
      state.loading = false;
    });

    builder.addCase(clearOneChildCarePaymentFilterAction.fulfilled, (state, action) => {
      // both `state` and `action` are now correctly typed
      // based on the slice state and the `pending` action creator
      const filterData = JSON.parse(action.payload);
      state.childCareFinderFilter.payment = { ...state.childCareFinderFilter.payment, ...filterData };
      state.refreshFilter = true;
    });
    builder.addCase(clearOneChildCarePaymentFilterAction.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(clearOneChildCarePaymentFilterAction.rejected, (state, action: any) => {
      state.loading = false;
    });

    builder.addCase(clearOneChildCareLanguageFilterAction.fulfilled, (state, action) => {
      // both `state` and `action` are now correctly typed
      // based on the slice state and the `pending` action creator
      const filterData = JSON.parse(action.payload);
      state.childCareFinderFilter.language = { ...state.childCareFinderFilter.language, ...filterData };
      state.refreshFilter = true;
    });
    builder.addCase(clearOneChildCareLanguageFilterAction.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(clearOneChildCareLanguageFilterAction.rejected, (state, action: any) => {
      state.loading = false;
    });

    builder.addCase(setChildCareAvailabilityFilterAction.fulfilled, (state, action) => {
      // both `state` and `action` are now correctly typed
      // based on the slice state and the `pending` action creator
      const filterData = JSON.parse(action.payload);
      state.childCareFinderFilter.availability = { ...state.childCareFinderFilter.availability, ...filterData };
      state.refreshFilter = true;
    });
    builder.addCase(setChildCareAvailabilityFilterAction.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(setChildCareAvailabilityFilterAction.rejected, (state, action: any) => {
      state.loading = false;
    });
  },

  reducers: {},
});

// Export reducer
export default filterSlice.reducer;
