
export class Age {
  constructor(){
    this.infant = false;
    this.toddler = false;
    this.preschool = false;
    this.school_age = false;
  }

  public infant: boolean;

  public toddler: boolean;

  public preschool: boolean;

  public school_age: boolean;

  public ClearAll() {
    this.infant = false;
    this.toddler = false;
    this.preschool = false;
    this.school_age = false;
  }
}