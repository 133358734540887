import { Center, Spinner } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useColorScheme } from 'theme/chakra/colors';

/**
 * A simple spinner used mainly for indicating page loading
 * Probably too large to be used for smaller sub-components
 */
const LoadingSpinner = () => {
  const spinnerColors = useColorScheme('makeSpinnerColors');
  const [showSpinner, setShowSpinner] = useState(false);

  // delay after mount before showing spinner to prevent flickering
  useEffect(() => {
    const timer = setTimeout(() => {
      setShowSpinner(true);
    }, 200);
    return () => clearTimeout(timer);
  }, []);

  return (
    <Center>
      {showSpinner ? (
        <Spinner
          width="7rem"
          height="7rem"
          speed="1.0s"
          thickness="4px"
          {...spinnerColors}
        />
      ) : null}
    </Center>
  );
};

export default LoadingSpinner;
