import { Expose } from 'class-transformer';

export class Ethnicity {
  constructor(obj: object = {}) {
    Object.assign(this, obj);
  }

  @Expose({ name: 'hispanic' })
  public hispanic: boolean = false;
}
