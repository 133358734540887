import React, { useEffect, useState } from 'react';

// GOOGLE MAPS
import { GoogleMap } from '@react-google-maps/api';

interface MapProps {
  center: google.maps.LatLngLiteral;
  zoom: number;
  markerPosition: google.maps.LatLngLiteral;
  circleCenter?: google.maps.LatLngLiteral;
  circleRadius?: number;
  onBoundsChanged: (bounds: google.maps.LatLngBounds) => void;
  children?: React.ReactNode;
  height?: string | number;
  draggable?: boolean;
  zoomControl?: boolean;
}

const mapContainerStyle = ({ display, height = 324 }: any) => {
  return {
    width: '100%',
    height,
    borderRadius: 4,
    //marginBottom: 25,
    display: display,
  };
};

const Map: React.FC<MapProps> = ({
  center,
  zoom,
  markerPosition,
  circleCenter,
  circleRadius,
  children,
  onBoundsChanged,
  height,
  draggable,
  zoomControl,
}) => {
  const [map, setMap] = useState<google.maps.Map | null>(null);
  useEffect(() => {
    if (map && circleRadius && circleRadius > 0) {
      const bounds = new google.maps.LatLngBounds();

      // Extend bounds to include marker position and circle bounds
      bounds.extend(markerPosition);
      bounds.union(
        new google.maps.Circle({ center: circleCenter, radius: circleRadius }).getBounds() as google.maps.LatLngBounds
      );

      // Set new center and zoom level based on bounds
      map.fitBounds(bounds);
    }
  }, [map, circleRadius, circleCenter, markerPosition]);

  const handleLoad = (map: google.maps.Map) => {
    setMap(map);
  };

  useEffect(() => {
    if (map) {
      const listener = map.addListener('idle', () => {
        if (map.getBounds()) {
          onBoundsChanged(map.getBounds() as google.maps.LatLngBounds);
        }
        // let centerLatLng = map.getCenter();
        // console.log(centerLatLng?.lat(), centerLatLng?.lng());
      });
      return () => {
        google.maps.event.removeListener(listener);
      };
    }
  }, [map, onBoundsChanged]);

  return (
    <React.Fragment key={'map'}>
      {/* GOOGLE MAPS */}
      <GoogleMap
        mapContainerStyle={mapContainerStyle({ display: 'block', height })}
        center={center}
        zoom={zoom}
        onLoad={handleLoad}
        options={{
          // SOURCE: https://developers.google.com/maps/documentation/javascript/reference/map#MapOptions
          streetViewControl: false,
          scaleControl: false,
          mapTypeControl: false,
          zoomControl: !zoomControl ? false : true,
          fullscreenControl: false,
          isFractionalZoomEnabled: true,
          gestureHandling: !draggable ? 'none' : '',
          scrollwheel: true,
          disableDoubleClickZoom: true,
          disableDefaultUI: true,
          clickableIcons: false,
        }}
      >
        {children}
      </GoogleMap>
    </React.Fragment>
  );
};

export default Map;
