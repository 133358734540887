import { Expose, Type } from 'class-transformer';
import { Network } from './Network';
import { NetworkActorUser } from './NetworkActorUser';

export class NetworkActor {
  constructor(obj: object = {}) {
    Object.assign(this, obj);
  }

  @Expose({ name: 'network_actor_id' })
  public networkActorId?: string;

  @Expose({ name: 'network_id' })
  public networkId?: string;

  @Expose({ name: 'user_id' })
  public userId?: string;

  @Expose({ name: 'actor_role' })
  public actorRole?: string;

  @Expose({ name: 'added_date' })
  public addedDate?: string;

  @Expose({ name: 'added_by_user_id' })
  public addedByUserId?: string;

  @Type(() => Network)
  @Expose({ name: 'network' })
  public network: Network = new Network();

  @Type(() => NetworkActorUser)
  @Expose({ name: 'user' })
  public user: NetworkActorUser = new NetworkActorUser();
}
