import React, { useState } from 'react';
import { Transition } from '@headlessui/react';
import FilterModal from './FilterModal';
import { constant } from 'constants/common';
import { Distance } from 'api/filters/Distance';
import { Coordinates } from 'api/dataObjects/subObjects/Coodinates';
import useAppLanguage from 'hooks/useAppLanguage';
import { XMarkIcon, ChevronRightIcon, CheckIcon } from '@heroicons/react/24/solid';
import FilterDistance from './FilterDistance';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHook';
import { clearChildCareFilterAction } from 'reduxApp/features/filterSlice';

interface SlideOverProps {
  isOpen: boolean;
  onClick?: Function;
}

function SlideUpButton({ isOpen }: SlideOverProps) {
  const { translate } = useAppLanguage();
  const [isSlideOverOverlayOpen, setIsSlideOverOverlayOpen] = useState(isOpen);
  const [isSlideOverOpen, setIsSlideOverOpen] = useState(isOpen);
  const [isAgeSlideOverOpen, setIsAgeSlideOverOpen] = useState(isOpen);
  const [isPaymentSlideOverOpen, setIsPaymentSlideOverOpen] = useState(isOpen);
  const [isLanguageSlideOverOpen, setIsLanguageSlideOverOpen] = useState(isOpen);
  const [isDistanceSlideOverOpen, setIsDistanceSlideOverOpen] = useState(isOpen);

  const filtersDistanceReduxData: Distance = useAppSelector(
    (state) => state?.childCareFilter?.childCareFinderFilter.distance || new Distance()
  );
  const geoReduxData: Coordinates = useAppSelector(
    (state) => state?.geo?.geoAddress?.coordinates || { lat: 0, lon: 0 }
  );
  const dispatch = useAppDispatch();

  const toggleSlideOver = () => {
    setIsSlideOverOverlayOpen(true);
    setIsSlideOverOpen(!isSlideOverOpen);
    document.body.style.overflow = 'hidden'; // Hide body scroll
  };

  const closeSlideOver = () => {
    setIsSlideOverOpen(!isSlideOverOpen);
    document.body.style.overflow = 'scroll'; // Show body scroll
    setTimeout(() => setIsSlideOverOverlayOpen(false), 700);
  };

  const closeAgeSlideOver = () => {
    setIsAgeSlideOverOpen(!isAgeSlideOverOpen);
    setTimeout(() => setIsSlideOverOverlayOpen(false), 700);
    document.body.style.overflow = 'scroll'; // Show body scroll
  };

  const toggleAgeSlideOver = () => {
    toggleSlideOver();
    setIsSlideOverOpen(!isSlideOverOpen);
    setIsAgeSlideOverOpen(!isAgeSlideOverOpen);
    document.body.style.overflow = 'hidden'; // Hide body scroll
  };

  const togglePaymentSlideOver = () => {
    toggleSlideOver();
    setIsPaymentSlideOverOpen(!isPaymentSlideOverOpen);
    document.body.style.overflow = 'hidden'; // Hide body scroll
  };

  const closePaymentSlideOver = () => {
    setTimeout(() => setIsSlideOverOverlayOpen(false), 700);
    setIsPaymentSlideOverOpen(!isPaymentSlideOverOpen);
    document.body.style.overflow = 'scroll'; // Show body scroll
  };

  const closeLanguageSlideOver = () => {
    setTimeout(() => setIsSlideOverOverlayOpen(false), 700);
    setIsLanguageSlideOverOpen(!isLanguageSlideOverOpen);
    document.body.style.overflow = 'scroll'; // Show body scroll
  };

  const toggleLanguageSlideOver = () => {
    toggleSlideOver();
    setIsLanguageSlideOverOpen(!isLanguageSlideOverOpen);
    document.body.style.overflow = 'hidden'; // Hide body scroll
  };

  const closeDistanceSlideOver = () => {
    setTimeout(() => setIsSlideOverOverlayOpen(false), 700);
    setIsDistanceSlideOverOpen(!isDistanceSlideOverOpen);
    document.body.style.overflow = 'scroll'; // Show body scroll
  };

  const toggleDistanceSlideOver = () => {
    toggleSlideOver();
    setIsDistanceSlideOverOpen(!isDistanceSlideOverOpen);
    document.body.style.overflow = 'hidden'; // Hide body scroll
  };

  const closeOverlay = () => {
    setIsSlideOverOpen(false);
    setIsAgeSlideOverOpen(false);
    setIsPaymentSlideOverOpen(false);
    setIsLanguageSlideOverOpen(false);
    setIsDistanceSlideOverOpen(false);
    setTimeout(() => setIsSlideOverOverlayOpen(false), 700);
    document.body.style.overflow = 'scroll'; // Show body scroll
  };
  return (
    <div className="flex flex-col items-center justify-center">
      <div className="md:flex">
        <div className="md:rounded-sm md:mr-2 md:border border-gray-300"></div>

        <button
          onClick={toggleSlideOver}
          className="text-tail-blue md:border-2 md:pt-1 md:pb-1 md:px-8 rounded-lg md:font-bold border-tail-blue"
        >
          {translate('Filters')}
        </button>
      </div>

      <div className="fixed inset-x-0 bottom-0 sm:bottom-auto w-full z-[51]">
        <Transition.Root
          show={isSlideOverOpen}
          //afterLeave={() => isAgeSlideOverOpen && setIsAgeSlideOverOpen(true)}
        >
          {/* Slide-over content */}
          <div className="fixed bottom-0 overflow-hidden z-50 w-full sm:h-full sm:flex sm:justify-center sm:items-center">
            {isSlideOverOverlayOpen && (
              <div className="fixed inset-0 bg-black bg-opacity-50 z-50" onClick={closeOverlay}></div>
            )}
            <div className="relative z-50">
              <Transition.Child
                enter="transform transition ease-out duration-300 delay-300 sm:duration-[300] "
                enterFrom="translate-y-full  opacity-0"
                enterTo="translate-y-0  opacity-1"
                leave="transform transition ease-in duration-300 sm:duration-[300]"
                leaveFrom="translate-y-0 opacity-1"
                leaveTo="translate-y-full opacity-0"
              >
                <div className="relative bottom-0 flex flex-col flex-1 w-full sm:max-w-[480px] sm:min-w-[480px] bg-white focus:outline-none rounded-t-xl sm:rounded-xl text-start ">
                  {/* Slide-over header */}
                  <div className="px-4 py-3 bg-white-300 flex flex-row justify-between">
                    <div className=" font-bold text-gray-800 text-xl">{translate('Child care search filters')}</div>
                    <XMarkIcon className="h-6 w-6 text-tail-text cursor-pointer" onClick={closeSlideOver} />
                  </div>

                  {/* Slide-over body */}
                  <div className="flex-1 overflow-y-auto px-4 py-2">
                    <div className="mb-4 cursor-pointer" onClick={toggleDistanceSlideOver}>
                      <div className="flex justify-between text-tail-blue font-bold ">
                        <div className="">{translate('Distance radius')}</div>
                        <div className="flex gap-4">
                          <div className="flex w-fit justify-end text-gray-300">
                            {filtersDistanceReduxData?.max_miles || 5} {translate('Miles')}
                            <CheckIcon className="h-6 w-6 " />
                          </div>
                          <ChevronRightIcon className="h-6 w-6 " />
                        </div>
                      </div>
                      <p className="text-sm mt-2">
                        {translate(
                          'By using your position we can show you child care services within a specified distance.'
                        )}
                      </p>
                    </div>
                    <div className="mb-4 cursor-pointer" onClick={toggleAgeSlideOver}>
                      <div className="flex justify-between text-tail-blue font-bold ">
                        <div className="">{translate('Age group')}</div>
                        <ChevronRightIcon className="h-6 w-6 " />
                      </div>
                      <p className="text-sm mt-2">
                        {translate('Find care that is not only quality but specific to the age of your children.')}
                      </p>
                    </div>
                    <div className="my-4 cursor-pointer" onClick={togglePaymentSlideOver}>
                      <div className="flex justify-between text-tail-blue font-bold">
                        <div>{translate('Payment options')}</div>
                        <ChevronRightIcon className="h-6 w-6 " />
                      </div>
                      <p className="text-sm mt-2">
                        {translate(
                          'Child care can be expensive, different payment options and subsidies help everyone afford quality child care.'
                        )}
                      </p>
                    </div>
                    <div className="my-4 cursor-pointer" onClick={toggleLanguageSlideOver}>
                      <div className="flex justify-between text-tail-blue font-bold">
                        <div>{translate('Languages spoken')}</div>
                        <ChevronRightIcon className="h-6 w-6 " />
                      </div>
                      <p className="text-sm mt-2">
                        {translate('Looking for a program that speaks your language? We can help you find that.')}
                      </p>
                    </div>
                  </div>

                  {/* Slide-over footer */}
                  <div
                    className="m-2.5 py-1 flex justify-center space-x-10 bg-tail-blue rounded-lg text-white bolder font-bold"
                    onClick={() => {
                      dispatch(clearChildCareFilterAction('clear'));
                      closeSlideOver();
                    }}
                  >
                    <button>{translate('Clear all filters')}</button>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </div>
        </Transition.Root>
      </div>

      {/* Age Slide-over container */}
      <div className="fixed inset-x-0 bottom-0 sm:bottom-auto w-full  z-[51] ">
        <Transition.Root show={isAgeSlideOverOpen}>
          {/* Slide-over content */}
          <div className="fixed bottom-0 overflow-hidden z-50 w-full sm:h-full sm:flex sm:justify-center sm:items-center">
            {isSlideOverOverlayOpen && (
              <div className="fixed inset-0 bg-black bg-opacity-50 z-50" onClick={closeOverlay}></div>
            )}
            <div className="relative z-50">
              <Transition.Child
                enter="transform transition ease-out duration-300 delay-300"
                enterFrom="translate-x-full opacity-0"
                enterTo="translate-x-0 opacity-1"
                leave="transform transition ease-in duration-300"
                leaveFrom="translate-x-0 opacity-1"
                leaveTo="translate-x-full opacity-0"
              >
                <FilterModal
                  onClose={closeAgeSlideOver}
                  onToggle={toggleAgeSlideOver}
                  filterData={constant.ageFilterData}
                  filterTitle={'Age group'}
                  fieldName="age"
                />
              </Transition.Child>
            </div>
          </div>
        </Transition.Root>
      </div>

      {/* Payment Slide-over container */}
      <div className="fixed inset-x-0 bottom-0 sm:bottom-auto  w-full  z-[51] ">
        <Transition.Root show={isPaymentSlideOverOpen}>
          <div className="fixed bottom-0 overflow-hidden z-50 w-full sm:h-full sm:flex sm:justify-center sm:items-center">
            {/* Slide-over content */}
            {isSlideOverOverlayOpen && (
              <div className="fixed inset-0 bg-black bg-opacity-50 z-50" onClick={closeOverlay}></div>
            )}
            <div className="relative z-50">
              <Transition.Child
                enter="transform transition ease-out duration-300 delay-300"
                enterFrom="translate-x-full  opacity-0"
                enterTo="translate-x-0 opacity-1"
                leave="transform transition ease-in  duration-300 "
                leaveFrom="translate-x-0 opacity-1"
                leaveTo="translate-x-full opacity-0"
              >
                <FilterModal
                  onClose={closePaymentSlideOver}
                  onToggle={togglePaymentSlideOver}
                  filterData={constant.paymentFilterData}
                  filterTitle={translate('Payment options')}
                  fieldName="payment"
                />
              </Transition.Child>
            </div>
          </div>
        </Transition.Root>
      </div>

      {/* Language Slide-over container */}
      <div className="fixed inset-x-0 bottom-0 sm:bottom-auto w-full z-[51] ">
        <Transition.Root show={isLanguageSlideOverOpen}>
          <div className="fixed bottom-0 overflow-hidden z-50 w-full sm:h-full sm:flex sm:justify-center sm:items-center">
            {/* Slide-over content */}
            {isSlideOverOverlayOpen && (
              <div className="fixed inset-0 bg-black bg-opacity-50 z-50" onClick={closeOverlay}></div>
            )}
            <div className="relative z-50">
              <Transition.Child
                enter="transform transition ease-out  duration-300 delay-300"
                enterFrom="translate-x-full opacity-0"
                enterTo="translate-x-0 opacity-1"
                leave="transform transition ease-in  duration-300 "
                leaveFrom="translate-x-0 opacity-1"
                leaveTo="translate-x-full opacity-0"
              >
                <FilterModal
                  onClose={closeLanguageSlideOver}
                  onToggle={toggleLanguageSlideOver}
                  filterData={constant.languageFilterData}
                  filterTitle={translate('Languages spoken')}
                  fieldName="language"
                />
              </Transition.Child>
            </div>
          </div>
        </Transition.Root>
      </div>

      {/* Distance Slide-over container */}
      <div className="fixed inset-x-0 bottom-0 sm:bottom-auto  w-full  z-[51] ">
        <Transition.Root show={isDistanceSlideOverOpen}>
          <div className="fixed bottom-0 overflow-hidden z-50 w-full sm:h-full sm:flex sm:justify-center sm:items-center">
            {/* Slide-over content */}
            {isSlideOverOverlayOpen && (
              <div className="fixed inset-0 bg-black bg-opacity-50 z-50" onClick={closeOverlay}></div>
            )}
            <div className="relative z-50">
              <Transition.Child
                enter="transform transition ease-out duration-300 delay-300 sm:duration-300"
                enterFrom="translate-x-full opacity-0"
                enterTo="translate-x-0 opacity-1"
                leave="transform transition ease-in duration-300 sm:duration-300"
                leaveFrom="translate-x-0 opacity-1"
                leaveTo="translate-x-full opacity-0"
              >
                <FilterDistance
                  onClose={closeDistanceSlideOver}
                  onToggle={toggleDistanceSlideOver}
                  center={
                    geoReduxData.lon && geoReduxData.lon !== 0
                      ? { lat: geoReduxData.lat, lng: geoReduxData.lon }
                      : constant.centerLatLng
                  }
                />
              </Transition.Child>
            </div>
          </div>
        </Transition.Root>
      </div>
    </div>
  );
}

export default SlideUpButton;
