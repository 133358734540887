import { Box, Center } from '@chakra-ui/react';
import { Coordinates } from 'api/dataObjects/subObjects/Coodinates';
import { fetchGeoLocationByIP } from 'api/geoSvc';
import InternalLink from 'components/Links/InternalLink';
import Footer from 'components/PageLayout/Footer';
import LeftLogo from 'components/PageLayout/Header/LeftLogo';
import FormButton from 'components/Parts/FormButton';
import { useAppDispatch } from 'hooks/reduxHook';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAddressByCoordinates } from 'reduxApp/features/geoSlice';
import useAppLanguage from 'hooks/useAppLanguage';
import LangaugeSubMenu from 'components/PageLayout/Header/LanguageSubMenu';
import { constant } from 'constants/common';

const Splash = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { translate } = useAppLanguage();

  const [loading, setLoading] = useState<boolean>(false);

  const clickHandler = async () => {
    // if user not selected within time then location will be denined by default after time expiration.
    // const options = {
    //   enableHighAccuracy: true,
    //   timeout: 10000,
    //   maximumAge: 0,
    // };

    //check user location changes in navigator
    if (window.navigator.permissions && window.navigator.permissions.query) {
      window.navigator.permissions.query({ name: 'geolocation' }).then((permissionStatus) => {
        setLoading(true);
        //console.log('permissionStatus', permissionStatus);
        // navigator.geolocation.getCurrentPosition(success, error, options);
        navigator.geolocation.getCurrentPosition(success, error);
      });
    } else {
      try {
        const IPCoordinates = await fetchGeoLocationByIP();
        const crd: Coordinates = IPCoordinates?.coordinates || { lat: 0, lon: 0 };
        const getIPCoordinates = async () => {
          await logJSONData(crd.lat, crd.lon);
        };
        await getIPCoordinates();
        setLoading(false);
        console.warn(`ERROR()`);
        sendToSearchPage();
      } catch (err: any) {
        await logJSONData(constant.centerLatLng.lat, constant.centerLatLng.lng);
        setLoading(false);

        sendToSearchPage();
      }
    }
  };

  function success(pos: any) {
    const crd = pos.coords;
    // console.log(`More or less ${crd.accuracy} meters.`);
    logJSONData(crd.latitude, crd.longitude);
    setLoading(false);
    sendToSearchPage();
  }

  const error = async (err: any) => {
    try {
      const IPCoordinates = await fetchGeoLocationByIP();
      const crd: Coordinates = IPCoordinates?.coordinates || { lat: 0, lon: 0 };
      const getIPCoordinates = async () => {
        await logJSONData(crd.lat, crd.lon);
      };
      await getIPCoordinates();
      setLoading(false);
      sendToSearchPage();
    } catch (err: any) {
      await logJSONData(constant.centerLatLng.lat, constant.centerLatLng.lng);
      setLoading(false);
      sendToSearchPage();
    }
  };

  function sendToSearchPage() {
    navigate('/child-care-finder/search', {
      replace: true,
    });
  }

  const logJSONData = async (lat: number, lon: number) => {
    const coordinates = { lat, lon };
    const response = await dispatch(getAddressByCoordinates(coordinates));
    return response;
  };

  return (
    <>
      <div className="flex gap-3 justify-end">
        <LangaugeSubMenu />
      </div>

      <Center h={'20vh'}>
        <InternalLink to="/child-care-finder">
          <LeftLogo />
        </InternalLink>
      </Center>

      <Center>
        <Box h={'10vh'} p={1} width={['100%', '50%']}>
          <Box pl={5} pr={5} textAlign={'left'}>
            {translate(
              'Searching for suitable child care can be overwhelming and confusing. Our child care search was designed to simplify the process so that you can focus on what matters.'
            )}
          </Box>

          <FormButton onClick={clickHandler} mt={6} fontWeight={700} height={'48px'} isLoading={loading}>
            {translate('Begin Your Search')}
          </FormButton>
        </Box>
      </Center>

      <Center>
        <Footer />
      </Center>
    </>
  );
};

export default Splash;
