// import Rollbar from 'rollbar';
import {Level} from 'rollbar';
import {getRollbarConfig, isRollbarEnabled, getRollbar} from './config';
import type { rollbarContextObj} from './config';

let logger: Function | undefined = undefined;

const getLogger = ():Function => {
  if(!logger) {

    // Set to console.log first, then override if Rollbar is enabled.
    logger = (message: string, error: string, context:rollbarContextObj) => {
      const errorLevelStr = context.logLevel?.toString() ? context.logLevel : 'Unknown';
      logLocal(message, error, errorLevelStr, context);
    };

    // If rollbar enabled, override console.log
    if(isRollbarEnabled()){
      logger = (message: string, error: string, context:rollbarContextObj) => {
        const rollbar = getRollbar();
        const errorLevel: Level = context.logLevel ? context.logLevel : 'debug';
        const config = getRollbarConfig(errorLevel);
        rollbar.configure(config);
        rollbar.log(message, new Error(error), context);

        console.log('rollbar message sent');
      }
    }

    if(typeof logger !== 'function') { throw new Error('Error initializing Logging feature.')};
  }
  return logger;
};


// local console logging
const logLocal = (message: string, error: string, errorLevelStr: string, context: rollbarContextObj ): void => {
  console.log(`${errorLevelStr}: ${error} - ${message}`, `Context: ${context}`);
};

export { getLogger };