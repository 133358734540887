import {
  createUserWithEmailAndPassword,
  signInWithCustomToken,
  signInWithEmailAndPassword,
  signInAnonymously as signInAnon,
  signOut as signOutUser,
  User,
  onAuthStateChanged,
  NextOrObserver,
  ErrorFn,
  CompleteFn,
} from 'firebase/auth';
import { auth } from '.';
import { logError } from '../../providers/rollbar/api';

export function startAuthListener(
  callback: NextOrObserver<User>,
  errorCallback?: ErrorFn | undefined,
  completeCallback?: CompleteFn | undefined
) {
  return onAuthStateChanged(auth, callback, errorCallback, completeCallback);
}

export function signUp(email: string, password: string) {
  return createUserWithEmailAndPassword(auth, email, password);
}

export function signInWithEmail(email: string, password: string) {
  return signInWithEmailAndPassword(auth, email, password);
}

export function signInWithToken(token: string) {
  return signInWithCustomToken(auth, token);
}

export function signInAnonymously() {
  return signInAnon(auth);
}

export function signOut() {
  return signOutUser(auth);
}

/**
 * ### Async / Promise
 * Gets the fully-hydrated id token. This will contain richer identifying information,
 * but will not be able to re-authenticate in another firebase client.
 * This is useful for legacy apps with their own authentication systems, where the app
 * just needs a way to associate a verified user identity. (ex: lego og mobile app);
 * @param {boolean?} forceRefresh
 * @returns {Promise<string | undefined>}
 * if undefined, an error occurred.
 * otherwise, returns encoded (jwt) id token.
 */
export const getIdToken = async (forceRefresh: boolean = false) => {
  try {
    if (!auth.currentUser) {
      throw new Error('Must be logged in');
    }
    const idToken = await auth.currentUser.getIdToken(forceRefresh);
    if (idToken) {
      return idToken;
    } else {
      throw new Error('Could not fetch firebase id token');
    }
  } catch (err: any) {
    logError(err, `Could not get id token: ${err.message}`);
    //, {
    //  err,
    //  uid: authUser.uid,
    //  forceRefresh,
    //}
    //);
    return; // signals failure
  }
};

export const currentUser = auth.currentUser;

export default auth;
