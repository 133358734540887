export interface iListingRate {
  days_per_week: number | null;
  monthly_amount: number | null;
  weekly_amount: number | null;
}

export interface iListingSchedule {
  age_months_max: number | null;
  age_months_min: number | null;
  hours_end: string | null;
  hours_start: string | null;
  rates: iListingRate[];
  title: string;
}

const getCostRange = (schedules: iListingSchedule[]): string => {
  let highVal: number = 0;
  let lowVal: number = 9999;
  if (Array.isArray(schedules) && schedules.length > 0) {
    schedules.forEach((schedule) => {
      schedule.rates.forEach((rate) => {
        if (rate.monthly_amount && rate.monthly_amount < lowVal) lowVal = rate.monthly_amount;
        if (rate.monthly_amount && rate.monthly_amount > highVal) highVal = rate.monthly_amount;
      });
    });
  }
  if (lowVal === 9999 || highVal === 0) {
    return '';
  }
  const rtn = `$${lowVal}-$${highVal}/month`;
  return rtn;
};

export default getCostRange;
