import { Expose, Type } from 'class-transformer';

import { Address } from './Address';
import { Adult } from './Adult';
import { Child } from './Child';
import { Comment } from './Comment';
import { User } from './User';

export class Application {
  constructor(obj: object = {}) {
    Object.assign(this, obj);
  }

  // type: uuid
  @Expose({ name: 'application_id' })
  public applicationId?: string;

  // type: uuid
  @Expose({ name: 'network_id' })
  public networkId?: string;

  // type: uuid
  @Expose({ name: 'applicant_name' })
  public applicantName?: string;

  // type: FB userId
  @Expose({ name: 'assigned_user_id' })
  public assignedUserId?: string | null = null;

  @Expose({ name: 'status' })
  public status?: string = 'DRAFT';

  // type: uuid, reference: provider.id
  @Expose({ name: 'provider_one_id' })
  public providerOneId?: string | null = null;

  // type: uuid, reference: provider.id
  @Expose({ name: 'provider_two_id' })
  public providerTwoId?: string | null = null;

  // type: uuid, reference: provider.id
  @Expose({ name: 'provider_three_id' })
  public providerThreeId?: string | null = null;

  @Expose({ name: 'subsidy_interest' })
  public subsidyInterest?: boolean = false;

  // No Client Set
  @Expose({ name: 'created_date' })
  public createdDate?: Date;

  // References userId, No Client Set
  @Expose({ name: 'created_by_user_id' })
  public createdByUserId?: string;

  // No Client Set
  @Expose({ name: 'updated_date' })
  public updatedDate?: Date;

  // References userId, No Client Set
  @Expose({ name: 'updated_by_user_id' })
  public updatedByUserId?: string;

  // Address Object
  @Type(() => Address)
  @Expose({ name: 'addresses' })
  public addresses?: Address[];

  // Adults Array
  @Type(() => Adult)
  @Expose({ name: 'adults' })
  public adults?: Adult[];

  // Children Array
  @Type(() => Child)
  @Expose({ name: 'children' })
  public children?: Child[];

  // Comments Array
  @Type(() => Comment)
  @Expose({ name: 'comments' })
  public comments?: Comment[];

  // AssignedUser Object
  @Type(() => User)
  @Expose({ name: 'assigned_user' })
  public assignedUser?: User;

  // AssignedUser Object
  @Type(() => User)
  @Expose({ name: 'updated_by_user' })
  public updatedByUser?: User;

  // AssignedUser Object
  @Type(() => User)
  @Expose({ name: 'created_by_user' })
  public createdByUser?: User;
}
