//
// This file has all the paths to the endpoints
// All endpoints should have the same path for all methods
//  ^Create can pass empty string for id, cause id is omitted for post
//

export const paths = {
  landing: '/landing',
  parent: {
    index: '/parent',
    status: '/parent/status',
    statusWithId: '/parent/status/:appId',

    application: {
      adults: '/parent/create-profile',
      children: '/parent/child-information',
      childSchedules: '/parent/child-care-schedule',
      providers: '/parent/providers',
    },
  },
  childCareFinder: {
    index: '/child-care-finder',
    search: '/child-care-finder/search',
    providers: '/child-care-finder/search/providers'
  },
  network: {
    index: '/organization',
    application: '/organization/application',
    applicationWithId: '/organization/application/:appId',
  },
};

export const redirects = {
  anonymous: '/anonymous',
  signIn: '/sign-in',
  signOut: '/sign-out',
};
