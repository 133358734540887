import { Expose, Type } from 'class-transformer';
import { Network } from './subObjects/Network';
import { NetworkActor } from './subObjects/NetworkActor';

export class DomainConfig {
  constructor(obj: object = {}) {
    Object.assign(this, obj);
  }

  @Expose({ name: 'domain_id' })
  public domainId?: string;

  @Expose({ name: 'network_id' })
  public networkId?: boolean;

  @Expose({ name: 'host' })
  public host?: string;

  @Expose({ name: 'network_logo' })
  public networkLogo?: string;

  @Type(() => Network)
  @Expose({ name: 'network' })
  public network?: Network = new Network();

  @Type(() => NetworkActor)
  @Expose({ name: 'network_actors' })
  public networkActors?: Array<NetworkActor> = [new NetworkActor()];
}
