import { Expose } from 'class-transformer';

export class Race {
  constructor(obj: object = {}) {
    Object.assign(this, obj);
  }

  @Expose({ name: 'american_indian_alaska_native' })
  public americanIndianAlaskaNative: boolean = false;

  @Expose({ name: 'asian' })
  public asian: boolean = false;

  @Expose({ name: 'black_african_american' })
  public blackAfricanAmerican: boolean = false;

  @Expose({ name: 'native_hawaiian_pacific_islander' })
  public nativeHawaiianPacificIslander: boolean = false;

  @Expose({ name: 'white' })
  public white: boolean = false;
}
