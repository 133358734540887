import { Box, Flex, Text } from '@chakra-ui/react';
import { Coordinates } from 'api/dataObjects/subObjects/Coodinates';
import { constant } from 'constants/common';
import { useAppSelector } from 'hooks/reduxHook';
import { useColorScheme } from 'theme/chakra/colors';
import { iHit } from 'types/ccProvider';
import { time } from 'utils/time';
import { stringTransform } from 'utils/transform';
import getDistanceBetweenCoordinates from 'utils/distanceBetweenCoordinates';
import getCostRange from 'utils/getCCCost';
import useAppLanguage from 'hooks/useAppLanguage';
type iCCCard = {
  pItem: iHit;
  handleInfoF?: Function;
};

const CCProvider: React.FC<iCCCard> = ({ pItem, handleInfoF }) => {
  const blueColor = useColorScheme('makeBackgroundAndColorsReturn', 'bluegray');
  const greyText = useColorScheme('makeBackgroundAndColorsReturn', 'lightGrey');
  const F1Grey = useColorScheme('makeBackgroundAndColorsReturn', 'grayBg');
  const btnBlue = useColorScheme('makeBackgroundAndColors', 'color', 'bluegray');
  const purpleColor = useColorScheme('makeBackgroundAndColorsReturn', 'softPurple');

  const searchAddressCoordinates: Coordinates = useAppSelector(
    (state) => state?.geo?.geoAddress?.coordinates || { lat: 0, lon: 0 }
  );
  // keeping this code as it will be used in future.
  const { translate } = useAppLanguage();
  return (
    <Box onClick={() => handleInfoF && handleInfoF(pItem?._id)}>
      <Box as="span" flex="1" textAlign="left">
        <Flex>
          <Box p={4}>
            <Box fontWeight={700} {...btnBlue} fontSize={'20px'}>
              {pItem?._source.name}
            </Box>
            <Box fontSize={'14px'}>
              {searchAddressCoordinates &&
                // getDistanceBetweenCoordinates(searchAddressCoordinates, pItem?._source?.coordinates)
                Math.round(
                  pItem?.sort
                    ? pItem?.sort[0] * 10
                    : getDistanceBetweenCoordinates(searchAddressCoordinates, pItem?._source?.coordinates)
                ) / 10}{' '}
              {translate('miles away')} | {pItem?._source?.location_city}, {pItem?._source?.location_state}{' '}
              {pItem?._source?.location_postal_code}
            </Box>

            <Box pt={5}>
              <Flex gap={1}>
                <Box
                  w={'24px'}
                  h={'20px'}
                  backgroundColor={
                    pItem?._source?.days_open.includes(constant.WEEKDAYS[6].key.toLowerCase()) ? purpleColor : F1Grey
                  }
                  borderRadius={'4px'}
                  padding={0}
                  lineHeight={1}
                  textAlign={'center'}
                >
                  <Text
                    pt={'1px'}
                    color={
                      pItem?._source?.days_open.includes(constant.WEEKDAYS[6].key.toLowerCase()) ? greyText : blueColor
                    }
                  >
                    {translate('SUNDAY_ABBR')}
                  </Text>
                </Box>
                <Box
                  w={'24px'}
                  h={'20px'}
                  backgroundColor={
                    pItem?._source?.days_open.includes(constant.WEEKDAYS[0].key.toLowerCase()) ? purpleColor : F1Grey
                  }
                  borderRadius={'4px'}
                  padding={0}
                  lineHeight={1}
                  textAlign={'center'}
                >
                  <Text
                    pt={'1px'}
                    color={
                      pItem?._source?.days_open.includes(constant.WEEKDAYS[0].key.toLowerCase()) ? greyText : blueColor
                    }
                  >
                    {translate('MONDAY_ABBR')}
                  </Text>
                </Box>
                <Box
                  w={'24px'}
                  h={'20px'}
                  backgroundColor={
                    pItem?._source?.days_open.includes(constant.WEEKDAYS[1].key.toLowerCase()) ? purpleColor : F1Grey
                  }
                  borderRadius={'4px'}
                  padding={0}
                  lineHeight={1}
                  textAlign={'center'}
                >
                  <Text
                    pt={'1px'}
                    color={
                      pItem?._source?.days_open.includes(constant.WEEKDAYS[1].key.toLowerCase()) ? greyText : blueColor
                    }
                  >
                    {translate('TUESDAY_ABBR')}
                  </Text>
                </Box>
                <Box
                  w={'24px'}
                  h={'20px'}
                  backgroundColor={
                    pItem?._source?.days_open.includes(constant.WEEKDAYS[2].key.toLowerCase()) ? purpleColor : F1Grey
                  }
                  borderRadius={'4px'}
                  padding={0}
                  lineHeight={1}
                  textAlign={'center'}
                >
                  <Text
                    pt={'1px'}
                    color={
                      pItem?._source?.days_open.includes(constant.WEEKDAYS[2].key.toLowerCase()) ? greyText : blueColor
                    }
                  >
                    {translate('WEDNESDAY_ABBR')}
                  </Text>
                </Box>
                <Box
                  w={'24px'}
                  h={'20px'}
                  backgroundColor={
                    pItem?._source?.days_open.includes(constant.WEEKDAYS[3].key.toLowerCase()) ? purpleColor : F1Grey
                  }
                  borderRadius={'4px'}
                  padding={0}
                  lineHeight={1}
                  textAlign={'center'}
                >
                  <Text
                    pt={'1px'}
                    color={
                      pItem?._source?.days_open.includes(constant.WEEKDAYS[3].key.toLowerCase()) ? greyText : blueColor
                    }
                  >
                    {translate('THURSDAY_ABBR')}
                  </Text>
                </Box>
                <Box
                  w={'24px'}
                  h={'20px'}
                  backgroundColor={
                    pItem?._source?.days_open.includes(constant.WEEKDAYS[4].key.toLowerCase()) ? purpleColor : F1Grey
                  }
                  borderRadius={'4px'}
                  padding={0}
                  lineHeight={1}
                  textAlign={'center'}
                >
                  <Text
                    pt={'1px'}
                    color={
                      pItem?._source?.days_open.includes(constant.WEEKDAYS[4].key.toLowerCase()) ? greyText : blueColor
                    }
                  >
                    {translate('FRIDAY_ABBR')}
                  </Text>
                </Box>
                <Box
                  w={'24px'}
                  h={'20px'}
                  backgroundColor={
                    pItem?._source?.days_open.includes(constant.WEEKDAYS[5].key.toLowerCase()) ? purpleColor : F1Grey
                  }
                  borderRadius={'4px'}
                  padding={0}
                  lineHeight={1}
                  textAlign={'center'}
                >
                  <Text
                    pt={'1px'}
                    color={
                      pItem?._source?.days_open.includes(constant.WEEKDAYS[5].key.toLowerCase()) ? greyText : blueColor
                    }
                  >
                    {translate('SATURDAY_ABBR')}
                  </Text>
                </Box>
              </Flex>
            </Box>
            <Box pt={2.5} fontSize={'14px'}>
              {pItem?._source?.program_type === 'fcc'
                ? 'Family child care'
                : stringTransform.capitalizeFirst(pItem?._source?.program_type)}{' '}
              |
              {time
                .ageRangeServed(pItem?._source?.age_months_min)
                .replace('months', translate('months'))
                .replace('years', translate('years'))}{' '}
              -
              {time
                .ageRangeServed(pItem?._source?.age_months_max)
                .replace('months', translate('months'))
                .replace('years', translate('years'))}
            </Box>
            <Box pt={2.5} fontSize={'14px'}>
              <Box>
                <span>{getCostRange(pItem?._source?.schedules)}</span>
                {pItem?._source?.subsidized_care_accepted ? (
                  <span>
                    {getCostRange(pItem?._source?.schedules) !== '' && ' | '}
                    <span style={{ fontWeight: 700 }}>{translate('Accepts Subsidies')}</span>
                  </span>
                ) : (
                  ''
                )}
              </Box>
            </Box>
          </Box>
          {/*  <Spacer /> */}
        </Flex>
      </Box>
    </Box>
  );
};

export default CCProvider;
