import { Expose } from 'class-transformer';

export class Network {
  constructor(obj: object = {}) {
    Object.assign(this, obj);
  }

  @Expose({ name: 'network_id' })
  public networkId?: string;

  @Expose({ name: 'name' })
  public name?: string;
}
