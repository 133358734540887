

export type UserContext = {
  id?: string;
  email?: string;
}

let userContext: UserContext = {id: '', email: ''};

const setUserContext = (id?: string, email?: string) => {
  userContext = {};
  if (id) userContext.id = id;
  if (email) userContext.email = email;
  return userContext;
};

const getUserContext = (): UserContext => {return userContext};

export {setUserContext, getUserContext};