import { Link } from '@chakra-ui/react';
import { Link as ReactRouterLink } from 'react-router-dom';
import { useColorScheme } from 'theme/chakra/colors';
import React from 'react';

interface InternalLinkProps {
  children: React.ReactNode;
  text?: string;
  to: string;
}

const InternalLink: React.FC<InternalLinkProps> = ({
  children /** @type {React.Children} */,
  text /** @type {string} text to display, overridden by children */,
  to /** @type {string} internal route */,
}) => {
  const linkColors = useColorScheme('makeLinkColors');
  return (
    <Link {...linkColors} fontWeight="medium" as={ReactRouterLink} to={to}>
      {children ? children : text}
    </Link>
  );
};

export default InternalLink;
