// Initializes Firebase
import { Provider, ErrorBoundary } from '@rollbar/react';
import { Provider as ReduxProvider } from 'react-redux';
import { getRollbarConfig } from './providers/rollbar/config';
import ApplicationProvider from 'providers/ApplicationProvider';
import AppRoutes from 'routes';
import { persistor, store } from 'reduxApp/store';
import { PersistGate } from 'redux-persist/integration/react';
import './api/firebase';
import LoadingSpinner from 'components/LoadingSpinner';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'index.css';

import { isBrowserOutdated } from 'components/PageLayout/BrowserDetection';

export const App = () => {
  if (isBrowserOutdated()) {
    return (
      <div className="bg-red-500 text-white p-4">
        <p className="text-center">Please use a modern browser to access this website for the best experience.</p>
      </div>
    );
  } else {
    return (
      <ReduxProvider store={store}>
        <Provider config={getRollbarConfig()}>
          <PersistGate loading={<LoadingSpinner />} persistor={persistor}>
            <ErrorBoundary>
              <ApplicationProvider>
                <ToastContainer
                  position="top-center"
                  autoClose={3000}
                  hideProgressBar={false}
                  newestOnTop={false}
                  closeOnClick
                  rtl={false}
                  pauseOnFocusLoss
                  draggable
                  pauseOnHover
                  theme="colored"
                />
                <AppRoutes />
              </ApplicationProvider>
            </ErrorBoundary>
          </PersistGate>
        </Provider>
      </ReduxProvider>
    );
  }
};
