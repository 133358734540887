import { Expose, Type } from 'class-transformer';
import { Ethnicity } from './subObjects/Ethnicity';
import { Race } from './subObjects/Race';

export class Adult {
  constructor(obj: object = {}) {
    Object.assign(this, obj);
  }

  // type: uuid, No client set
  @Expose({ name: 'adult_id' })
  public adultId?: string;

  // type: uuid, !!!Default set to empty uuid
  @Expose({ name: 'application_id' })
  public applicationId: string = '00000000-0000-0000-0000-000000000000';

  @Expose({ name: 'adult_index' })
  public adultIndex: number = -1;

  @Expose({ name: 'user_id' })
  public userId: string | null = null;

  @Expose({ name: 'first_name' })
  public firstName: string = '';

  @Expose({ name: 'middle_name' })
  public middleName: string = '';

  @Expose({ name: 'last_name' })
  public lastName: string = '';

  @Expose({ name: 'suffix' })
  public suffix: string = '';

  @Expose({ name: 'assigned_sex' })
  public assignedSex: string = 'Prefer not to answer';

  @Expose({ name: 'birthdate' })
  public birthdate: string | null = null;

  @Type(() => Ethnicity)
  @Expose({ name: 'ethnicity' })
  public ethnicity: Ethnicity = new Ethnicity();

  @Type(() => Race)
  @Expose({ name: 'race' })
  public race: Race = new Race();

  @Expose({ name: 'email' })
  public email?: string;

  @Expose({ name: 'phone' })
  public phone?: string;
}
