import Rollbar from 'rollbar';
import type {Level} from 'rollbar';
import { getRollbarToken, getRollbarForced, getCurrentEnv, isLocal } from '../../config/env';
import { isRollbarFlagEnabled } from '../featureFlags';
import {getErrorLevel} from './api';
import {getUserContext} from './UserContext';
import type {UserContext} from './UserContext';


//  Constants for config
const CaptureUncaught = true;
const CaptureUnhandledRejections = true;

interface clientObj {
  javascript: javascriptClientObj;
}
interface javascriptClientObj {
  code_version: string;
  source_map_enabled: boolean;
  guess_uncaught_frames: boolean;
};
interface rollbarPayloadObj {
  environment: string;
  code_version: string;
  user_context?: UserContext;
  custom_context?: any;
  client: clientObj;
}

export type rollbarConfigObj = {
  accessToken: string;
  captureUncaught?: boolean;
  captureUnhandledRejections?: boolean;
  logLevel?: Level;
};
export interface rollbarContextObj extends rollbarConfigObj {
  payload?: rollbarPayloadObj;
};

const getAccessToken = (): string => {
  return getRollbarToken() || '';
};

const getEnvironment = (): string => {
  return getCurrentEnv() || 'UNKNOWN_ENVIRONMENT';
};

const getCodeVersion = (): string => {
  return process.env?.REACT_APP_GIT_SHA || 'UNKNOWN_VERSION';
};

export const getContext = (config: rollbarConfigObj): rollbarContextObj => {
  if(!config) config = getRollbarConfig();
  const context: rollbarContextObj = config;
  context.payload = getInitialPayload();
  return context;
};

const getInitialPayload = (): rollbarPayloadObj => {
  return {
    environment: getEnvironment(),
    code_version: getCodeVersion(),
    user_context: getUserContext(),
    custom_context: {},
    client: {
      javascript: {
        code_version: getCodeVersion(),
        source_map_enabled: true,
        guess_uncaught_frames: true,
      }
    }
  }
};

const getRollbarConfig = (errorLevel: string = 'debug'): rollbarConfigObj => {
  return {
    accessToken: getAccessToken(),
    captureUncaught: CaptureUncaught,
    captureUnhandledRejections: CaptureUnhandledRejections,
    logLevel: getErrorLevel(errorLevel),
  }
};

const isRollbarEnabled = (): boolean => {
  // getForceRollbar in wsConfig is to force rollbar from local
  return (!isLocal() && isRollbarFlagEnabled()) || getRollbarForced();
};

const initRollbar = (): typeof window.Rollbar => {
  if(!window.Rollbar){
    window.Rollbar = window.Rollbar || new Rollbar();
  }
  return window.Rollbar;
};

const getRollbar = ():Rollbar => initRollbar();

export { getRollbarConfig, getRollbar, isRollbarEnabled, getCodeVersion, getInitialPayload };