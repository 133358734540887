import { Adult } from 'api/dataObjects/Adult';
import { ChildSchedule } from 'api/dataObjects/ChildSchedule';
import { Child } from 'api/dataObjects/Child';
import { Ethnicity } from 'api/dataObjects/subObjects/Ethnicity';
import { Race } from 'api/dataObjects/subObjects/Race';
import { constant } from 'constants/common';
import { IAdditionalAdult } from 'types/application';
import { IDropDownValue } from 'types/forms';
import { store, RootState } from 'reduxApp/store';
import { time } from 'utils/time';
import { NetworkActor } from 'api/dataObjects/subObjects/NetworkActor';

export const convertToValueLableObjectArr = (param: any, options: IDropDownValue[]) => {
  return options.filter((item: any) => param[item.value]);
};

export const convertStrToValueLabel = (param: string, options: IDropDownValue[]) => {
  return options.filter((item: IDropDownValue) => item.value === param);
};

const anotherAdult = (adults: Adult[] & IAdditionalAdult[]) => {
  let additionalAdult: IAdditionalAdult[] = [];
  adults.map((adult: IAdditionalAdult, index: number) => {
    if (index) {
      adult.assignedSex = convertStrToValueLabel(adult.assignedSex as string, constant.GENDER_OPTIONS)[0];
      adult.race = convertToValueLableObjectArr(adult.race, constant.RACES_OPTIONS);
      adult.ethnicity = makeChildEthinicityDropDownData(adult.ethnicity);
      additionalAdult.push(adult);
    }
    return 0;
  });
  return additionalAdult;
};

//
export const convertProfileData = (response: Adult[]) => {
  const { ...applicantObj } = response;
  const profileObj = {
    applicationId: applicantObj[0].applicationId,
    adultId: applicantObj[0].adultId,
    AdultIndex: applicantObj[0].adultIndex,
    firstName: applicantObj[0].firstName,
    middleName: applicantObj[0].middleName,
    lastName: applicantObj[0].lastName,
    suffix: applicantObj[0].suffix,
    birthdate: applicantObj[0].birthdate,
    assignedSex: convertStrToValueLabel(applicantObj[0].assignedSex, constant.GENDER_OPTIONS)[0],
    race: convertToValueLableObjectArr(applicantObj[0].race, constant.RACES_OPTIONS),
    ethnicity: makeChildEthinicityDropDownData(applicantObj[0].ethnicity),
    phone: applicantObj[0].phone,
    email: applicantObj[0].email,
    adults: anotherAdult(response as []),
  };
  return profileObj;
};

export const convertScheduleData = (response: ChildSchedule[]) => {
  return response.map((item: any, index: number) => {
    return {
      weekSchedule: [
        {
          day: 'MON',
          dropOff: item[constant.SCHELD_WEEK_COLLECTION.mondayDropoff]
            ? time.shedulesConvertToAMPM(item[constant.SCHELD_WEEK_COLLECTION.mondayDropoff])
            : constant.DEFAULT_SCHEDULE_DROP_OFF,
          pickUp: item[constant.SCHELD_WEEK_COLLECTION.mondayPickup]
            ? time.shedulesConvertToAMPM(item[constant.SCHELD_WEEK_COLLECTION.mondayPickup])
            : constant.DEFAULT_SCHEDULE_PICK_UP,
        },
        {
          day: 'TUE',
          dropOff: item[constant.SCHELD_WEEK_COLLECTION.tuesdayDropoff]
            ? time.shedulesConvertToAMPM(item[constant.SCHELD_WEEK_COLLECTION.tuesdayDropoff])
            : constant.DEFAULT_SCHEDULE_DROP_OFF,
          pickUp: item[constant.SCHELD_WEEK_COLLECTION.tuesdayPickup]
            ? time.shedulesConvertToAMPM(item[constant.SCHELD_WEEK_COLLECTION.tuesdayPickup])
            : constant.DEFAULT_SCHEDULE_PICK_UP,
        },
        {
          day: 'WED',
          dropOff: item[constant.SCHELD_WEEK_COLLECTION.wednesdayDropoff]
            ? time.shedulesConvertToAMPM(item[constant.SCHELD_WEEK_COLLECTION.wednesdayDropoff])
            : constant.DEFAULT_SCHEDULE_DROP_OFF,
          pickUp: item[constant.SCHELD_WEEK_COLLECTION.wednesdayPickup]
            ? time.shedulesConvertToAMPM(item[constant.SCHELD_WEEK_COLLECTION.wednesdayPickup])
            : constant.DEFAULT_SCHEDULE_PICK_UP,
        },
        {
          day: 'THU',
          dropOff: item[constant.SCHELD_WEEK_COLLECTION.thursdayDropoff]
            ? time.shedulesConvertToAMPM(item[constant.SCHELD_WEEK_COLLECTION.thursdayDropoff])
            : constant.DEFAULT_SCHEDULE_DROP_OFF,
          pickUp: item[constant.SCHELD_WEEK_COLLECTION.thursdayPickup]
            ? time.shedulesConvertToAMPM(item[constant.SCHELD_WEEK_COLLECTION.thursdayPickup])
            : constant.DEFAULT_SCHEDULE_PICK_UP,
        },
        {
          day: 'FRI',
          dropOff: item[constant.SCHELD_WEEK_COLLECTION.fridayDropoff]
            ? time.shedulesConvertToAMPM(item[constant.SCHELD_WEEK_COLLECTION.fridayDropoff])
            : constant.DEFAULT_SCHEDULE_DROP_OFF,
          pickUp: item[constant.SCHELD_WEEK_COLLECTION.fridayPickup]
            ? time.shedulesConvertToAMPM(item[constant.SCHELD_WEEK_COLLECTION.fridayPickup])
            : constant.DEFAULT_SCHEDULE_PICK_UP,
        },
        {
          day: 'SAT',
          dropOff: item[constant.SCHELD_WEEK_COLLECTION.saturdayDropoff]
            ? time.shedulesConvertToAMPM(item[constant.SCHELD_WEEK_COLLECTION.saturdayDropoff])
            : constant.DEFAULT_SCHEDULE_DROP_OFF,
          pickUp: item[constant.SCHELD_WEEK_COLLECTION.saturdayPickup]
            ? time.shedulesConvertToAMPM(item[constant.SCHELD_WEEK_COLLECTION.saturdayPickup])
            : constant.DEFAULT_SCHEDULE_PICK_UP,
        },
        {
          day: 'SUN',
          dropOff: item[constant.SCHELD_WEEK_COLLECTION.sundayDropoff]
            ? time.shedulesConvertToAMPM(item[constant.SCHELD_WEEK_COLLECTION.sundayDropoff])
            : constant.DEFAULT_SCHEDULE_DROP_OFF,
          pickUp: item[constant.SCHELD_WEEK_COLLECTION.sundayPickup]
            ? time.shedulesConvertToAMPM(item[constant.SCHELD_WEEK_COLLECTION.sundayPickup])
            : constant.DEFAULT_SCHEDULE_PICK_UP,
        },
      ],
      overNightCare: item?.overnightCare,
      beforeAfterSchoolcare: item?.beforeAfterSchool,
      childId: item?.childId,
      applicationId: item?.applicationId,
      childIndex: item?.childIndex,
    };
  });
};

export const raceConvertForApi = (race: IDropDownValue[]) => {
  return Object.assign(
    new Race(),
    race !== undefined ? race.reduce((obj: any, item: any) => Object.assign(obj, { [item.value]: true }), {}) : {}
  );
};

export const ethnicityConvertForApi = (ethnicity: IDropDownValue) => {
  return Object.assign(
    new Ethnicity(),
    ethnicity?.value !== undefined
      ? [ethnicity].reduce((obj: any, item: any) => Object.assign(obj, { [item.value]: true }), {})
      : {}
  );
};

export const childApiDataConvertForRedux = (response: Child[]): any => {
  const responseData = response.map((child: Child, i: number) => {
    return {
      ...child,
      earlyIntervention: child?.earlyIntervention ? 'Yes' : 'No',
      headStart: child?.headStart ? 'Yes' : 'No',
      developConcerns: child?.developConcerns ? 'Yes' : 'No',
      assignedSex: { value: child?.assignedSex || '', label: child?.assignedSex || '' },
      ethnicity: makeChildEthinicityDropDownData(child?.ethnicity),
      race: makeChildRaceDropDownData(child?.race),
    };
  });
  return responseData;
};

const makeChildEthinicityDropDownData = (object: any) => {
  if (object === undefined) {
    return { value: '', label: '' };
  } else {
    if (object['hispanic']) return constant.ETHNICITY_OPTIONS.filter((val) => object[val.value])[0];
    else return constant.ETHNICITY_OPTIONS.filter((val) => val.value === 'non_hispanic')[0];
  }
};
const makeChildRaceDropDownData = (object: any) => {
  return constant.RACES_OPTIONS.filter((val) => object[val.value]);
};

const anotherAdultDetails = (adults: Adult[] & IAdditionalAdult[]) => {
  let additionalAdult: IAdditionalAdult[] = [];
  adults.map((adult: IAdditionalAdult, index: number) => {
    if (index) {
      adult.assignedSex = convertStrToValueLabel(adult.assignedSex as string, constant.GENDER_OPTIONS)[0];
      adult.race = makeChildRaceDropDownData(adult.race);
      adult.ethnicity = makeChildEthinicityDropDownData(adult?.ethnicity);
      additionalAdult.push(adult);
    }
    return 0;
  });
  return additionalAdult;
};

//
export const convertProfileDataDetails = (response: Adult[]) => {
  const { ...applicantObj } = response;
  if (response.length) {
    const profileObj = {
      applicationId: applicantObj[0].applicationId,
      adultId: applicantObj[0].adultId,
      AdultIndex: applicantObj[0].adultIndex,
      firstName: applicantObj[0].firstName,
      middleName: applicantObj[0].middleName,
      lastName: applicantObj[0].lastName,
      suffix: applicantObj[0].suffix,
      birthdate: applicantObj[0].birthdate,
      assignedSex: convertStrToValueLabel(applicantObj[0].assignedSex ?? '', constant.GENDER_OPTIONS)[0],
      race: makeChildRaceDropDownData(applicantObj[0].race ?? []),
      ethnicity: makeChildEthinicityDropDownData(applicantObj[0]?.ethnicity ?? ''),
      phone: applicantObj[0].phone,
      email: applicantObj[0].email,
      adults: anotherAdultDetails(response as []),
    };
    return profileObj;
  }
  return {} as Adult;
};

export const convertAddressDataDeails = (addresses: any) => {
  if (addresses.length > 0) {
    let address = addresses[0];
    return {
      addressId: address?.addressId,
      addressLine1: address.addressLine1 ?? '',
      addressLine2: address.addressLine2,
      city: address.city,
      state: convertStrToValueLabel(address.state, constant.STATE_LIST)[0],
      zipcode: address.zipcode,
    };
  }
  return {};
};
export const childApiDataConvertForApplicationDetails = (response: Child[]): any => {
  const state: RootState = store.getState();
  const providerData = state?.provider?.providerById || false;
  const responseData = response.map((child: Child, i: number) => {
    let label: any = '';
    if (providerData && child?.providerId !== null && child?.providerId !== undefined) {
      let providerId = child?.providerId.toString();
      label = (providerData as any)[providerId].name;
    }
    return {
      ...child,
      earlyIntervention: child?.earlyIntervention ? 'Yes' : 'No',
      headStart: child?.headStart ? 'Yes' : 'No',
      developConcerns: child?.developConcerns ? 'Yes' : 'No',
      assignedSex: { value: child?.assignedSex || '', label: child?.assignedSex || '' },
      ethnicity: makeChildEthinicityDropDownData(child?.ethnicity),
      race: makeChildRaceDropDownData(child?.race),
      childSchedule: convertScheduleSingleDate(child.childSchedule as ChildSchedule),
      providerId: { value: child?.providerId ?? '', label },
    };
  });
  return responseData;
};

export const getStatusDropDownValue = (value: string): IDropDownValue => {
  return constant.APPLICATION_STATUS_OPTIONS.filter((status) => status.value === value)[0];
};
export const convertScheduleSingleDate = (response: ChildSchedule) => {
  return {
    weekSchedule: [
      {
        day: 'MON',
        dropOff: response['mondayDropoff']
          ? time.shedulesConvertToAMPM(response['mondayDropoff'])
          : constant.DEFAULT_SCHEDULE_DROP_OFF,
        pickUp: response['mondayPickup']
          ? time.shedulesConvertToAMPM(response['mondayPickup'])
          : constant.DEFAULT_SCHEDULE_PICK_UP,
      },
      {
        day: 'TUE',
        dropOff: response['tuesdayDropoff']
          ? time.shedulesConvertToAMPM(response['tuesdayDropoff'])
          : constant.DEFAULT_SCHEDULE_DROP_OFF,
        pickUp: response['tuesdayPickup']
          ? time.shedulesConvertToAMPM(response['tuesdayPickup'])
          : constant.DEFAULT_SCHEDULE_PICK_UP,
      },
      {
        day: 'WED',
        dropOff: response['wednesdayDropoff']
          ? time.shedulesConvertToAMPM(response['wednesdayDropoff'])
          : constant.DEFAULT_SCHEDULE_DROP_OFF,
        pickUp: response['wednesdayPickup']
          ? time.shedulesConvertToAMPM(response['wednesdayPickup'])
          : constant.DEFAULT_SCHEDULE_PICK_UP,
      },
      {
        day: 'THU',
        dropOff: response['thursdayDropoff']
          ? time.shedulesConvertToAMPM(response['thursdayDropoff'])
          : constant.DEFAULT_SCHEDULE_DROP_OFF,
        pickUp: response['thursdayPickup']
          ? time.shedulesConvertToAMPM(response['thursdayPickup'])
          : constant.DEFAULT_SCHEDULE_PICK_UP,
      },
      {
        day: 'FRI',
        dropOff: response['fridayDropoff']
          ? time.shedulesConvertToAMPM(response['fridayDropoff'])
          : constant.DEFAULT_SCHEDULE_DROP_OFF,
        pickUp: response['fridayPickup']
          ? time.shedulesConvertToAMPM(response['fridayPickup'])
          : constant.DEFAULT_SCHEDULE_PICK_UP,
      },
      {
        day: 'SAT',
        dropOff: response['saturdayDropoff']
          ? time.shedulesConvertToAMPM(response['saturdayDropoff'])
          : constant.DEFAULT_SCHEDULE_DROP_OFF,
        pickUp: response['saturdayPickup']
          ? time.shedulesConvertToAMPM(response['saturdayPickup'])
          : constant.DEFAULT_SCHEDULE_PICK_UP,
      },
      {
        day: 'SUN',
        dropOff: response['sundayDropoff']
          ? time.shedulesConvertToAMPM(response['sundayDropoff'])
          : constant.DEFAULT_SCHEDULE_DROP_OFF,
        pickUp: response['sundayPickup']
          ? time.shedulesConvertToAMPM(response['sundayPickup'])
          : constant.DEFAULT_SCHEDULE_PICK_UP,
      },
    ],
    overNightCare: response?.overnightCare,
    beforeAfterSchoolcare: response?.beforeAfterSchool,
    childId: response?.childId,
    childScheduleId: response?.childScheduleId,
    //childIndex: response?.childIndex,
  };
};

export const getCaseManagerFromDomainConfig: any = (networkActors: NetworkActor[]) => {
  let caseManagers: IDropDownValue[] = [];
  networkActors &&
    networkActors.forEach((actor: NetworkActor) => {
      caseManagers = [
        ...caseManagers,
        { value: actor?.user?.userId, label: `${actor?.user?.firstName} ${actor?.user?.lastName}` },
      ];
    });
  return caseManagers as IDropDownValue[];
};

export function getKeysOfTrueValues(obj: any) {
  const trueKeys = [];
  for (const key in obj) {
    if (obj[key]) {
      trueKeys.push(key);
    }
  }
  return trueKeys;
}

export const converKmToMile: any = (km = 5): any => {
  return km * 1609.34;
};
