import { Expose } from 'class-transformer';

export class Address {
  constructor(obj: object = {}) {
    Object.assign(this, obj);
  }

  @Expose({ name: 'address_id' })
  public addressId?: string;

  // type: uuid, !!!Default set to empty uuid, fk so will fail
  @Expose({ name: 'application_id' })
  public applicationId: string = '00000000-0000-0000-0000-000000000000';

  // Extra Flag not in UI, ignore
  @Expose({ name: 'address_label' })
  public addressLabel: string = 'Primary Address';

  @Expose({ name: 'address_line1' })
  public addressLine1: string = '';

  @Expose({ name: 'address_line2' })
  public addressLine2: string | null = null;

  @Expose({ name: 'city' })
  public city: string = '';

  @Expose({ name: 'state' })
  public state: string = '';

  @Expose({ name: 'zipcode' })
  public zipcode: string = '';
}
