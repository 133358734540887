import { Marker } from '@react-google-maps/api';
import customMarkerIcon from 'assets/images/Center.png';
import React from 'react';

interface MarkerProps {
  uniqueKey?: string;
  position: google.maps.LatLngLiteral;
  icon?: Icon;
  handleInfo?: Function;
}

interface Icon {
  url: string;
  scaledSize?: google.maps.Size;
  origin?: google.maps.Point;
  anchor?: google.maps.Point;
}

let customIcon: Icon = {
  url: customMarkerIcon,
};

window.onload = () => {
  if (window.google && window.google.maps) {
    customIcon = {
      url: customMarkerIcon,
      scaledSize: new window.google.maps.Size(12, 12),
      origin: new window.google.maps.Point(0, 0),
      anchor: new window.google.maps.Point(6, 6),
    };
    // Use the size object as needed
  } else {
    // Handle the case when Google Maps API is not available
  }
};

const MarkerComponent: React.FC<MarkerProps> = ({ uniqueKey = '123', position, icon = customIcon, handleInfo }) => {
  //const map = React.useRef<google.maps.Map>();
  const marker = React.useRef<google.maps.Marker>();
  const onLoad = React.useCallback(
    function callback(markerInstance: google.maps.Marker) {
      // Set marker position
      markerInstance.setPosition(position);

      // Set marker icon
      if (icon) {
        markerInstance.setIcon(icon);
      }

      marker.current = markerInstance;
    },
    [icon, position]
  );

  const onUnmount = React.useCallback(function callback() {
    if (marker.current) {
      marker.current.setMap(null);
    }
  }, []);

  const getDistance = (point1: google.maps.LatLngLiteral, point2: google.maps.LatLngLiteral) => {
    const lat1 = point1.lat;
    const lon1 = point1.lng;
    const lat2 = point2.lat;
    const lon2 = point2.lng;

    const R = 6371e3; // Radius of the Earth in meters
    const φ1 = (lat1 * Math.PI) / 180; // Convert to radians
    const φ2 = (lat2 * Math.PI) / 180; // Convert to radians
    const Δφ = ((lat2 - lat1) * Math.PI) / 180; // Difference in radians
    const Δλ = ((lon2 - lon1) * Math.PI) / 180; // Difference in radians

    const a = Math.sin(Δφ / 2) * Math.sin(Δφ / 2) + Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    const distance = R * c; // Distance in meters

    return distance;
  };
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const isMarkerInsideRadius = (markerPosition: google.maps.LatLngLiteral) => {
    // Calculate the distance between the marker position and the center position
    const distance = getDistance(markerPosition, { lat: 37.7749, lng: -122.4194 });

    return distance <= 500000;
  };

  return (
    <Marker
      key={uniqueKey}
      position={position}
      onLoad={onLoad}
      onUnmount={onUnmount}
      //icon={isMarkerInsideRadius(position) ? icon : icon}
      onClick={(position) => handleInfo && handleInfo(uniqueKey)}
    />
  );
};

export default MarkerComponent;
