import auth, {
  signInWithEmail,
  signInWithToken,
  signInAnonymously,
  signOut,
  startAuthListener,
} from 'api/firebase/auth';
import { createContext, useCallback, useEffect, useReducer } from 'react';
import { AuthError, AuthType, CustomClaimType, UserType } from 'types/firebase';
import { getErrorMessage } from 'utils/error';
import { getAccountsDomain } from 'config/env';

type State = {
  user: UserType;
  isAuthenticating: boolean;
  isAuthenticated: boolean;
  authError: AuthError;
};

type Action =
  | { type: 'SIGN_IN' }
  | { type: 'SET_USER'; payload: UserType }
  | { type: 'SET_ERROR'; payload: AuthError }
  | { type: 'SIGN_OUT' };

const initialState: State = {
  user: null,
  isAuthenticating: false,
  isAuthenticated: false,
  authError: null,
};

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'SIGN_IN':
      return {
        ...state,
        isAuthenticated: false,
        isAuthenticating: true,
        authError: null,
      };

    case 'SET_USER':
      return {
        ...state,
        user: action.payload,
        isAuthenticating: false,
        isAuthenticated: true,
        authError: null,
      };
    case 'SET_ERROR':
      return {
        ...state,
        authError: action.payload,
        isAuthenticating: false,
        isAuthenticated: false,
      };
    case 'SIGN_OUT':
      return initialState;
    default:
      return state;
  }
};

type AuthContextProps = {
  currentUser: UserType;
  startAuthListener: AuthType['startAuthListener'];
  signInWithEmail: AuthType['signInWithEmail'];
  signInWithToken: AuthType['signInWithToken'];
  signInAnonymously: AuthType['signInAnonymously'];
  signOut: AuthType['signOut'];
} & State;

export const AuthContext = createContext<AuthContextProps>(
  {} as AuthContextProps
);

interface AuthProviderProps {
  children: React.ReactNode;
}

export default function AuthProvider({ children }: AuthProviderProps) {
  const [{ user, isAuthenticating, isAuthenticated, authError }, dispatch] =
    useReducer(reducer, initialState);

  useEffect(() => {
    dispatch({ type: 'SIGN_IN' });
    const unsubscribe = startAuthListener(
      async (user) => {
        if (user) {
          const claims = (await auth?.currentUser?.getIdTokenResult())
            ?.claims as CustomClaimType;

          dispatch({
            type: 'SET_USER',
            payload: { ...user, claims },
          });
        } else {
          dispatch({ type: 'SIGN_OUT' });
        }
      },
      (error) => {
        dispatch({
          type: 'SET_ERROR',
          payload: getErrorMessage(
            error,
            'Some error occurred in AuthChangeListener'
          ),
        });
      }
    );
    return unsubscribe;
  }, []);

  const signInWithEmailFn = useCallback(
    async (email: string, password: string) => {
      try {
        dispatch({ type: 'SIGN_IN' });
        const signedInUser = await signInWithEmail(email, password);
        return signedInUser;
      } catch (error) {
        const { message } = getErrorMessage(
          error,
          'Unable to Sign in, invalid username and/or password.'
        );

        dispatch({
          type: 'SET_ERROR',
          payload: {
            message,
          },
        });
        throw new Error(message);
      }
    },
    []
  );

  const signInWithTokenFn = useCallback(async (token: string) => {
    try {
      dispatch({ type: 'SIGN_IN' });
      const signedInUser = await signInWithToken(token);
      return signedInUser;
    } catch (error) {
      let { message } = getErrorMessage(
        error,
        'Unable to Sign in, invalid token.'
      );

      dispatch({
        type: 'SET_ERROR',
        payload: {
          message,
        },
      });
      throw new Error(message);
    }
  }, []);

  const signInAnonymouslyFn = useCallback(async () => {
    try {
      dispatch({ type: 'SIGN_IN' });
      const signedInUser = await signInAnonymously();
      return signedInUser;
    } catch (error) {
      let { message } = getErrorMessage(
        error,
        'Unable to Sign in, invalid token.'
      );

      dispatch({
        type: 'SET_ERROR',
        payload: {
          message,
        },
      });
      throw new Error(message);
    }
  }, []);

  const signOutFunction = useCallback(async () => {
    try {
      await signOut();
      // redirect to sign out page
      let acctsDomain = getAccountsDomain() + "/signout";
      console.log("acctsDomain: ", acctsDomain);
      window.location.href = acctsDomain; //`${acctsDomain}/signout`;
    } catch (error) {
      let { message } = getErrorMessage(error, 'Problem signing out');
      dispatch({
        type: 'SET_ERROR',
        payload: { message },
      });
      throw new Error(message);
    }
  }, []);

  return (
    <AuthContext.Provider
      value={{
        user,
        isAuthenticating,
        isAuthenticated,
        authError,
        currentUser: auth.currentUser,
        startAuthListener,
        signInWithEmail: signInWithEmailFn,
        signInWithToken: signInWithTokenFn,
        signInAnonymously: signInAnonymouslyFn,
        signOut: signOutFunction,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
