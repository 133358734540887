import { Expose, Type } from 'class-transformer';
import { Ethnicity } from './subObjects/Ethnicity';
import { Race } from './subObjects/Race';
import { ExternalIdentifier } from './subObjects/ExternalIdentifier';
import { ChildSchedule } from './ChildSchedule';

export class Child {
  constructor(obj: object = {}) {
    Object.assign(this, obj);
  }

  // type: uuid
  @Expose({ name: 'child_id' })
  public childId?: string;

  // type: uuid, !!!Default set to empty uuid
  @Expose({ name: 'application_id' })
  public applicationId: string = '00000000-0000-0000-0000-000000000000';

  @Expose({ name: 'child_index' })
  public childIndex: number = -1;

  // Ignore for now
  @Expose({ name: 'external_ids' })
  public externalIds: ExternalIdentifier[] = [];

  // type: uuid
  @Expose({ name: 'provider_id' })
  public providerId?: string | null;

  @Expose({ name: 'needs_care' })
  public needsCare: boolean = true;

  @Expose({ name: 'first_name' })
  public firstName: string = '';

  @Expose({ name: 'middle_name' })
  public middleName: string = '';

  @Expose({ name: 'last_name' })
  public lastName: string = '';

  @Expose({ name: 'suffix' })
  public suffix: string = '';

  @Expose({ name: 'assigned_sex' })
  public assignedSex: string = 'Prefer to not answer';

  //  This is not nullable so not setting will fail server
  @Expose({ name: 'birthdate' })
  public birthdate?: string; //string = new Date(0).toISOString().split('T')[0];

  @Type(() => Ethnicity)
  @Expose({ name: 'ethnicity' })
  public ethnicity: Ethnicity = new Ethnicity();

  @Type(() => Race)
  @Expose({ name: 'race' })
  public race: Race = new Race();

  @Expose({ name: 'develop_concerns' })
  public developConcerns: boolean = false;

  @Expose({ name: 'develop_note' })
  public developNote?: string | null = null;

  @Expose({ name: 'early_intervention' })
  public earlyIntervention: boolean = false;

  @Expose({ name: 'early_intervent_note' })
  public earlyInterventNote?: string | null = null;

  @Expose({ name: 'head_start' })
  public headStart: boolean = false;

  // Children Array
  @Type(() => ChildSchedule)
  @Expose({ name: 'child_schedule' })
  public childSchedule?: ChildSchedule;
}
