import bowser from 'bowser';

interface MinimumVersions {
  [key: string]: number;
}

// Function to check if the browser is outdated
export function isBrowserOutdated(): boolean {
  const browser = bowser.getParser(window.navigator.userAgent);
  const minimumVersions: MinimumVersions = {
    chrome: 83, // Adjust the minimum version for Chrome
    Chrome: 83, // Adjust the minimum version for Chrome
    Firefox: 63, // Adjust the minimum version for Firefox
    Safari: 13, // Adjust the minimum version for Safari
    // Add more browsers and their minimum versions if needed
  };

  const browserName = browser.getBrowserName();
  const browserVersion = parseInt(browser.getBrowserVersion(), 10);
  console.log('----', browserName, browserVersion);
  return browserName in minimumVersions && browserVersion < minimumVersions[browserName];
}
