import { constant } from 'constants/common';
import moment from 'moment';

const shedulesConvertToAMPM = (time: string) => {
  return constant.TIME_OPTIONS.find((obj) => obj.value === time.slice(0, 5));
};

const convertTo24 = (time: string) => {};
const convertToAMPM = (time: string = '') => {
  const [hourString, minute] = time.split(':');
  const hour = +hourString % 24;
  return (hour % 12 || 12) + ':' + minute + (hour < 12 ? 'AM' : 'PM');
};

const ageRangeServed = (month: number = 0) => {
  return month % 12 === 0 && month !== 0 ? ` ${month / 12} years ` : ` ${month} months `;
};

const getDiffernceTwoDate = (oldDate: string = '', newDate: string = '') => {
  const [date] = oldDate.split('T');
  const date1 = moment(date);
  const date2 = moment(newDate);
  // const diffTime = Math.abs(date2 - date1);
  //const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  return date2.diff(date1, 'days');
};

const dateFormate = (date = new Date(), format = 'MM/DD/YYYY') => {
  return moment(date).format(format);
};
export const time = {
  convertTo24,
  convertToAMPM,
  ageRangeServed,
  shedulesConvertToAMPM,
  getDiffernceTwoDate,
  dateFormate,
};
