import { Button, ButtonProps } from '@chakra-ui/react';
import { forwardRef, useEffect, useState } from 'react';
import { useColorScheme } from 'theme/chakra/colors';
import { INPUT_RADIUS, PADDING_INPUT } from 'theme/chakra/layout';
import { CloseIcon } from '@chakra-ui/icons';
import { SvgIconProps } from '@mui/material';

type FilterCCButtonProps = {
  children?: React.ReactChild;
  title?: string;
  isFilter?: boolean;
  isDisabled?: boolean;
  buttonType?: string;
  filterType?: string;
  removeFilter?: Function;
  icon?: SvgIconProps;
  xIconRemoveButtonActive?: boolean;
} & ButtonProps;

const FilterCCButton = forwardRef<ButtonProps, FilterCCButtonProps>(
  (
    {
      /** @type {React.Children} react element to display in button */
      children,
      /** @type {string} text to display in button, overridden by children */
      title,
      /** @type {boolean} whether to display a loading indicator */
      isFilter,
      /** @type {string} primary and secondary design button show */
      buttonType = 'primary',
      filterType,
      removeFilter,
      xIconRemoveButtonActive,
      icon,
      ...props /** rest of props, forwarded to Chakra <Button> */
    },
    ref
  ) => {
    const subtextColor = useColorScheme('makeBackgroundAndColors', 'color', 'bluegray');
    const greyText = useColorScheme('makeBackgroundAndColorsReturn', 'lightGrey');
    const darkBlueText = useColorScheme('makeBackgroundAndColorsReturn', 'darkBlueText');

    const [filterIsActive, setFilterIsActive] = useState<boolean>(false);

    useEffect(() => {
      if (isFilter) setFilterIsActive(true);
      else setFilterIsActive(false);
    }, [isFilter]);

    return (
      <Button
        ref={ref}
        variant="solid"
        borderRadius={INPUT_RADIUS}
        padding={PADDING_INPUT}
        {...props}
        rightIcon={
          filterIsActive && (
            <span>
              | <CloseIcon boxSize={2.5} onClick={() => removeFilter && removeFilter(filterType)} />
            </span>
          )
        }
        {...subtextColor}
        fontWeight={400}
        isActive={filterIsActive || xIconRemoveButtonActive}
        _active={{ color: greyText, background: darkBlueText }}
        height={'28px'}
        fontSize={'16px'}
      >
        {children ? children : title}
      </Button>
    );
  }
);

export default FilterCCButton;
