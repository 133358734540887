interface SlideUpOverlayProps {
  isOpen: boolean;
  onClose: () => void;
  className?: string;
  children?: React.ReactNode;
}

const SlideUpOverlay: React.FC<SlideUpOverlayProps> = ({ isOpen, onClose, children, className }) => {
  return (
    <>
      {isOpen && <div className="fixed inset-0 bg-black bg-opacity-50 z-50" onClick={onClose}></div>}

      {children}
    </>
  );
};

export default SlideUpOverlay;
