// users.service.ts
import http from './httpService/http';
import { paths } from './paths';
import { Address } from './dataObjects/Address';
import { instanceToPlain, plainToInstance } from 'class-transformer';

const addressPath = paths.byApplication.address;

export const fetchAddress = async (appId: string): Promise<Address> => {
  const response = await http.get<Address, any>(addressPath(appId));
  return plainToInstance(Address, response.data as {}) as Address;
};

export const createAddress = async (appId: string, address: Address): Promise<Address> => {
  const obj = instanceToPlain(new Address(address));
  const response = await http.post<object, any>(addressPath(appId), obj as object);
  return plainToInstance(Address, response.data as {}) as Address;
};

export const updateAddress = async (appId: string, address: Address): Promise<Address> => {
  const obj = instanceToPlain(new Address(address));
  const response = await http.put<object, any>(addressPath(appId), obj as object);
  return plainToInstance(Address, response.data as {}) as Address;
};

//export const deleteAdults = async (appId: string, user: Adult[]): Promise<Adult> => {
//  return await http.delete<Adult[], any>(adultPath(appId));
//};
