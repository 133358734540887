import { Age } from './Age';
import { Availability } from './Availability';
import { Distance } from './Distance';
import { Language } from './Language';
import { Payment } from './Payment';

export class Filters {
  constructor() {
    this.age = new Age();
    this.distance = new Distance();
    this.language = new Language();
    this.payment = new Payment();
    this.availability = new Availability();
  }

  public age: Age;

  public distance: Distance;

  public language: Language;

  public payment: Payment;

  public availability: Availability;
}
