import { Circle } from '@react-google-maps/api';
import { useColorScheme } from 'theme/chakra/colors';

interface CircleProps {
  circleCenter: google.maps.LatLngLiteral;
  circleRadius: number;
}

const CircleComponent: React.FC<CircleProps> = ({ circleCenter, circleRadius }) => {
  const darkBlue = useColorScheme('makeBackgroundAndColorsReturn', 'darkBlueText');
  const lightBlue = useColorScheme('makeBackgroundAndColorsReturn', 'lightBlue');

  const circleOptions = {
    fillColor: lightBlue,
    fillOpacity: 0.6,
    strokeWeight: 1,
    strokeColor: darkBlue,
    clickable: false,
    editable: false,
    zIndex: 1,
  };
  return (
    <Circle
      center={circleCenter}
      radius={circleRadius}
      options={circleOptions}
      //onCenterChanged={() => console.log('onCenterChanged')}
      //onRadiusChanged={() => console.log('onRadiusChanged')}
    />
  );
};

export default CircleComponent;
