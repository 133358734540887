/**
 * constants for different font sizes
 * for specific font familes and weights, see ./baseFont.js
 * for colors, see ./colors.js
 */

const TITLE_FONT = {
  base: '2.4em',
  lg: '2.0em',
  xl: '2.8em',
  '2xl': '2.8em',
};
const MEDIUM_FONT = {
  base: '1.2em',
  lg: '1.2em',
  xl: '1.8em',
  '2xl': '1.8em',
};
const SUBTITLE_FONT = {
  base: '1.0em',
  lg: '1.0em',
  xl: '1.1em',
  '2xl': '1.2em',
};

const INPUT_FONT = {
  base: '14px',
  md: '1.0em',
  sm: '1.0em',
  lg: '14px',
  xl: '1.0em',
  '2xl': '1.0em',
};

const SMALLER_FONT = {
  base: '0.8em',
  lg: '1.0em',
  xl: '1.0em',
  '2xl': '1.2em',
};

const SMALL_LBL_BTN_FONT ={
  base: '12px',
  lg: '16px'
}

const HEADER_INPUT_FONT = {
  base: '14px',
  md: '1.0em',
  sm: '1.0em',
  lg: '16px',
  xl: '20px',
  '2xl': '20px',
};

const SCHEDULE_BTN = {
  base: '40px',
}


export { TITLE_FONT, MEDIUM_FONT, SUBTITLE_FONT, INPUT_FONT, SMALLER_FONT, SMALL_LBL_BTN_FONT, HEADER_INPUT_FONT, SCHEDULE_BTN };
