import { Expose } from 'class-transformer';

export class ChildSchedule {
  constructor(obj: object = {}) {
    Object.assign(this, obj);
  }

  // type: uuid, primary key
  @Expose({ name: 'child_schedule_id' })
  public childScheduleId: string = '00000000-0000-0000-0000-000000000000';

  // type: uuid, nullable: false, forgiegn key to child.child_id
  @Expose({ name: 'child_id' })
  public childId: string = '00000000-0000-0000-0000-000000000000';

  // type: "boolean", nullable: false, default: false
  @Expose({ name: 'overnight_care' })
  public overnightCare?: boolean = false;

  // type: "boolean", nullable: false, default: false
  @Expose({ name: 'before_after_school' })
  public beforeAfterSchool?: boolean = false;

  // type: "time without time zone", nullable: true, default: null
  @Expose({ name: 'monday_dropoff' })
  public mondayDropoff?: string | null = null;

  // type: "time without time zone", nullable: true, default: null
  @Expose({ name: 'monday_pickup' })
  public mondayPickup?: string | null = null;

  // type: "time without time zone", nullable: true, default: null
  @Expose({ name: 'tuesday_dropoff' })
  public tuesdayDropoff?: string | null = null;

  // type: "time without time zone", nullable: true, default: null
  @Expose({ name: 'tuesday_pickup' })
  public tuesdayPickup?: string | null = null;

  // type: "time without time zone", nullable: true, default: null
  @Expose({ name: 'wednesday_dropoff' })
  public wednesdayDropoff?: string | null = null;

  // type: "time without time zone", nullable: true, default: null
  @Expose({ name: 'wednesday_pickup' })
  public wednesdayPickup?: string | null = null;

  // type: "time without time zone", nullable: true, default: null
  @Expose({ name: 'thursday_dropoff' })
  public thursdayDropoff?: string | null = null;

  // type: "time without time zone", nullable: true, default: null
  @Expose({ name: 'thursday_pickup' })
  public thursdayPickup?: string | null = null;

  // type: "time without time zone", nullable: true, default: null
  @Expose({ name: 'friday_dropoff' })
  public fridayDropoff?: string | null = null;

  // type: "time without time zone", nullable: true, default: null
  @Expose({ name: 'friday_pickup' })
  public fridayPickup?: string | null = null;

  // type: "time without time zone", nullable: true, default: null
  @Expose({ name: 'saturday_dropoff' })
  public saturdayDropoff?: string | null = null;

  // type: "time without time zone", nullable: true, default: null
  @Expose({ name: 'saturday_pickup' })
  public saturdayPickup?: string | null = null;

  // type: "time without time zone", nullable: true, default: null
  @Expose({ name: 'sunday_dropoff' })
  public sundayDropoff?: string | null = null;

  // type: "time without time zone", nullable: true, default: null
  @Expose({ name: 'sunday_pickup' })
  public sundayPickup?: string | null = null;
}
