import { Stack, Box, Center, Divider } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useColorScheme } from 'theme/chakra/colors';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHook';
import { GeoLocationAddress } from 'api/dataObjects/GeoLocationAddress';
import { useNavigate } from 'react-router-dom';
import Footer from 'components/PageLayout/Footer';
import InternalLink from 'components/Links/InternalLink';
import LeftLogo from 'components/PageLayout/Header/LeftLogo';
import GeoLocationWithButtons from 'components/WrapperComponents/GeoLocationWithButtons';
import { setChildCareAvailabilityFilterAction } from 'reduxApp/features/filterSlice';
import { resetCCProvider } from 'reduxApp/features/ccProviderSlice';
import LangaugeSubMenu from 'components/PageLayout/Header/LanguageSubMenu';
import useAppLanguage from 'hooks/useAppLanguage';

const SearchProviders = () => {
  const dispatch = useAppDispatch();
  const { translate } = useAppLanguage();
  const geoAddressReduxData: GeoLocationAddress = useAppSelector((state) => state?.geo?.geoAddress);
  const getCCFFilterReduxData: any = useAppSelector((state) => state?.childCareFilter);
  const warningBg = useColorScheme('makeBackgroundAndColorsReturn', 'yellowTxtBg');

  const [selectedSearch, setSelectedSearch] = useState<boolean>(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (selectedSearch) {
      if (window.location.pathname === '/child-care-finder/search') {
        navigate('/child-care-finder/search/providers', {
          replace: true,
        });
      }
    }
    dispatch(resetCCProvider());
  }, [geoAddressReduxData, selectedSearch, navigate, dispatch]);

  const [isAvailability, setIsAvailability] = useState<boolean>(
    getCCFFilterReduxData?.childCareFinderFilter?.availability?.availability || false
  );
  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;
    setIsAvailability(isChecked);
    dispatch(setChildCareAvailabilityFilterAction(JSON.stringify({ availability: isChecked })));
  };
  return (
    <>
      <div>
        <div className="flex gap-3 justify-end">
          <LangaugeSubMenu />
        </div>
        <Center h={'20vh'}>
          <InternalLink to="/child-care-finder">
            <LeftLogo />
          </InternalLink>
        </Center>

        <Box p={1}>
          <Stack direction={['column', 'row']}>
            {geoAddressReduxData.formattedAddress ? (
              ''
            ) : (
              <Box
                bg={warningBg}
                w="100%"
                p={2}
                mt={-12}
                fontSize={'14px'}
                textAlign={'left'}
                fontFamily={'Moderat'}
                borderRadius={'8px'}
                visibility={true ? 'visible' : 'hidden'}
              >
                {translate(
                  'It looks like you’re currently located outside of the United States, but you can still search programs located in the US.'
                )}
              </Box>
            )}
          </Stack>

          <GeoLocationWithButtons setSelectedSearch={setSelectedSearch} />

          <div className="flex justify-start gap-3 mt-3">
            <div>
              <input
                type="checkbox"
                className="form-checkbox h-5 w-5 text-tail-blue"
                onChange={handleCheckboxChange}
                checked={isAvailability}
              />
            </div>
            <div className="text-sm text-start">{translate('Only show schools with open availability')}</div>
          </div>

          <Box>
            <Divider m={'35px 0px'} opacity={1} />
            <Center>
              <Footer isShowApplyButton={false}></Footer>
            </Center>
          </Box>
        </Box>
      </div>
    </>
  );
};

export default SearchProviders;
