import { Expose } from 'class-transformer';
import { Coordinates } from './subObjects/Coodinates';

export class GeoLocationAddress {
  constructor(obj: Object = {}) {
    Object.assign(this, obj);
  }
  @Expose({ name: 'street' })
  public street?: string;

  @Expose({ name: 'city' })
  public city?: string;

  @Expose({ name: 'county' })
  public county?: string;

  @Expose({ name: 'state' })
  public state?: string;

  @Expose({ name: 'country' })
  public country?: string;

  @Expose({ name: 'postal_code' })
  public postalCode?: string;

  @Expose({ name: 'formatted_address' })
  public formattedAddress?: string;

  @Expose({ name: 'coordinates' })
  public coordinates?: Coordinates;

  @Expose({ name: 'search_refresh' })
  public searchRefresh?: boolean;
}
