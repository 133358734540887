// users.service.ts
import http from './httpService/http';
import { paths } from './paths';
import { Adult } from './dataObjects/Adult';
import { instanceToPlain, plainToInstance } from 'class-transformer';

const adultPath = paths.byApplication.adults;

export const fetchAdults = async (appId: string): Promise<Adult[]> => {
  const response = await http.get<object, any>(adultPath(appId));
  return plainToInstance(Adult, response.data as []) as Adult[];
};

export const createAdults = async (appId: string, adults: Adult[]): Promise<Adult[]> => {
  const adultArr: Adult[] = [];
  adults.forEach((adult) => adultArr.push(new Adult(adult)));
  const objArr = instanceToPlain(adultArr);
  const response = await http.post<object, any>(adultPath(appId), objArr);
  const rtn = plainToInstance(Adult, response.data as []) as Adult[];
  return rtn;
};

export const updateAdults = async (appId: string, adults: Adult[]): Promise<Adult[]> => {
  const adultArr: Adult[] = [];
  adults.forEach((adult) => adultArr.push(new Adult(adult)));
  const objArr = instanceToPlain(adultArr);
  const response = await http.put<object, any>(adultPath(appId), objArr);
  return plainToInstance(Adult, response.data as []) as Adult[];
};

//export const deleteAdults = async (appId: string, user: Adult[]): Promise<Adult> => {
//  return await http.delete<Adult[], any>(adultPath(appId));
//};
