//
// This file has all the paths to the endpoints
// All endpoints should have the same path for all methods
//  ^Create can pass empty string for id, cause id is omitted for post
//

export const paths = {
  objects: {
    application: (applicationId: string) => `/api/application/${applicationId}`,
    address: (addressId: string) => `/api/address/${addressId}`,
    comment: (commentId: string) => (commentId ? `/api/comment/${commentId}` : 'comment'),
  },
  byApplication: {
    address: (applicationId: string) => `/api/application/${applicationId}/address`,
    adults: (applicationId: string) => `/api/application/${applicationId}/adults`,
    children: (applicationId: string) => `/api/application/${applicationId}/children`,
    child_schedules: (applicationId: string) => `/api/application/${applicationId}/children_schedules`,
    comments: (applicationId: string) => `/api/application/${applicationId}/comments`,
  },
  byNetwork: {
    applications: (networkId: string): string => `/api/network/${networkId}/applications`,
    provider_by_network: (networkId: string): string => `/api/network/${networkId}/providers`,
  },
  list: {
    providers: () => `/api/search/providers`,
  },
  search: {
    providers: () => `/api/search/providers`,
  },
  anonymous: {
    domain: () => `/anonymous/api/domain/${window.location.hostname}`,
    providerListing: () => `/anonymous/api/search/listings`,
    location_by_ip: () => '/anonymous/api/ip_coordinates',
    address_by_coordinates: (lat: number, lon: number) => `/anonymous/api/geo/${lat}/${lon}`,
  },
};
