import { Expose } from 'class-transformer';
import { Application } from './Application';

export class Comment {
  constructor(obj: Object = {}) {
    Object.assign(this, obj);
  }
  @Expose({ name: 'comment_id' })
  public commentId?: string;

  @Expose({ name: 'application_id' })
  public applicationId?: string;

  @Expose({ name: 'user_id' })
  public userId?: string;

  @Expose({ name: 'commented_at' })
  public commentedAt?: string;

  @Expose({ name: 'comment' })
  public comment?: string;

  // Relationship Mappings
  @Expose({ name: 'application' })
  public application?: Application;
}
