import { Listbox, Transition } from '@headlessui/react';
import { useAppSelector, useAppDispatch } from 'hooks/reduxHook';
import ChevronDownIcon from '@heroicons/react/24/solid/ChevronDownIcon';
import ChevronLeftIcon from '@heroicons/react/24/solid/ChevronLeftIcon';
import XMarkIcon from '@heroicons/react/24/solid/XMarkIcon';
import CircleComponent from 'components/Map/CircleComponent';
import LazyMapComponent from 'components/Map/Map';
import MarkerComponent from 'components/Map/MarkerComponent';
import React, { Fragment, useEffect, useState } from 'react';
import { converKmToMile } from 'utils/functions';
import { Distance } from 'api/filters/Distance';
import { setChildCareDistanceFilterAction } from 'reduxApp/features/filterSlice';
import { constant } from 'constants/common';
import { ICCProvidersData } from 'reduxApp/features/ccProviderSlice';
import useAppLanguage from 'hooks/useAppLanguage';

interface FilterDistanceModalProps {
  onClose: Function;
  onToggle?: Function;
  center: google.maps.LatLngLiteral;
}

const radiusOptions = constant.radiusOptions;

const FilterDistance: React.FC<FilterDistanceModalProps> = ({ onClose, onToggle, center }) => {
  const [selected, setSelected] = useState(radiusOptions[0]);
  const dispatch = useAppDispatch();
  const { translate } = useAppLanguage();
  const filtersDistanceReduxData: Distance = useAppSelector(
    (state) => state?.childCareFilter?.childCareFinderFilter.distance || new Distance()
  );
  const getCCProvidersReduxData: ICCProvidersData = useAppSelector((state) => state?.ccProviders);

  useEffect(() => {
    let chooseRadiusIndex = radiusOptions.findIndex((option) => option.miles === filtersDistanceReduxData.max_miles);
    setSelected(radiusOptions[chooseRadiusIndex]);
  }, [filtersDistanceReduxData]);

  function classNames(...classes: any) {
    return classes.filter(Boolean).join(' ');
  }

  const applyFilter = async () => {
    await dispatch(
      setChildCareDistanceFilterAction(JSON.stringify({ max_miles: selected.miles || 5, refreshFilter: true }))
    );
    onClose();
  };

  const resetFilter = async () => {
    await dispatch(setChildCareDistanceFilterAction(JSON.stringify({ max_miles: 5, refreshFilter: true })));
    onClose();
  };

  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    // Simulate a delay to allow transitions to complete
    const timeout = setTimeout(() => {
      setIsLoaded(true); // Set map as loaded after transition
    }, 300); // Adjust the delay based on your transition duration

    return () => {
      clearTimeout(timeout); // Clear the timeout if the component unmounts
    };
  }, []);
  return (
    <div className="relative bottom-0 flex flex-col flex-1 w-full bg-white focus:outline-none rounded-t-xl sm:rounded-xl text-start">
      {/* Slide-over header */}
      <div className="px-4 py-3 bg-white-500 flex flex-row justify-between">
        <div className="flex">
          {onToggle && <ChevronLeftIcon className="h-6 w-6 text-tail-text cursor-pointer" onClick={() => onToggle()} />}
          <div className=" ml-5 font-bold text-gray-800 text-xl leading-none">{translate('Distance radius')}</div>
        </div>
        <XMarkIcon className="h-6 w-6 text-tail-text cursor-pointer" onClick={() => onClose()} />
      </div>
      <div>
        <div className="text-4xl align-middle text-center">{getCCProvidersReduxData?.providers?.length || 0}</div>
        <div className="align-middle text-center text-sm">{translate('Child Care Locations')}</div>
      </div>
      <div className="mx-4 my-2">
        {isLoaded && (
          <LazyMapComponent
            center={center}
            zoom={11}
            markerPosition={center}
            circleCenter={center}
            onBoundsChanged={(bound) => console.log('chnage bound', bound)}
            circleRadius={converKmToMile(selected?.miles)}
          >
            <CircleComponent circleCenter={center} circleRadius={converKmToMile(selected?.miles)} />
            <MarkerComponent position={center} />
          </LazyMapComponent>
        )}
        <div className="px-3 py-2 bg-gray-50 rounded-lg">
          <Listbox value={selected} onChange={setSelected}>
            {({ open }) => (
              <>
                <div className="relative mt-2">
                  <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
                    <span className="block truncate">{selected.label}</span>
                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                      <ChevronDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                    </span>
                  </Listbox.Button>

                  <Transition
                    show={open}
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <Listbox.Options className="absolute z-10 mt-1 max-h-20 w-full overflow-auto rounded-md bg-white py-1 text-base ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                      {radiusOptions.map((option) => (
                        <Listbox.Option
                          key={option.miles}
                          className={({ active }) =>
                            classNames(
                              active ? 'bg-indigo-600 text-white' : 'text-gray-900',
                              'relative cursor-default select-none py-2 pl-3 pr-9'
                            )
                          }
                          value={option}
                        >
                          {({ selected }) => (
                            <>
                              <span
                                className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}
                              >
                                {translate(option.label)}
                              </span>
                            </>
                          )}
                        </Listbox.Option>
                      ))}
                    </Listbox.Options>
                  </Transition>
                </div>
              </>
            )}
          </Listbox>
          <div className="text-tail-text align-middle text-center text-sm">
            {translate('Search radius based on zip code or approximate location')}
          </div>
        </div>
      </div>
      {/* Slide-over footer */}
      <div className="px-4 py-3 flex justify-between text-sm">
        <button
          className="mx-1 px-8 pt-1 pb-1 flex justify-center space-x-10 border border-tail-blue rounded-lg text-tail-blue bolder font-bold"
          onClick={resetFilter}
          type="reset"
        >
          {translate('Remove Filter')}
        </button>
        <button
          className="mx-1 px-8 pt-1 pb-1 flex justify-center space-x-10 bg-tail-blue rounded-lg text-white bolder font-bold"
          onClick={applyFilter}
        >
          {translate('Apply Filters')}
        </button>
      </div>
    </div>
  );
};

export default FilterDistance;
