import http from './httpService/http';
import { paths } from './paths';
import { GeoLocationAddress } from './dataObjects/GeoLocationAddress';
import { plainToInstance } from 'class-transformer';

const geoCoordinates = paths.anonymous.address_by_coordinates;
const locationByIP = paths.anonymous.location_by_ip;

export const fetchGeoLocationByCoordinates = async (lat: number, lon: number): Promise<GeoLocationAddress> => {
  const response = await http.get<object, any>(geoCoordinates(lat, lon));
  return plainToInstance(GeoLocationAddress, response.data as {});
};

export const fetchGeoLocationByIP = async (): Promise<any> => {
  try {
    const response = await http.get<object, any>(locationByIP());
    return response.data;
  } catch (error) {
    throw error;
  }
};
