import { Coordinates } from 'api/dataObjects/subObjects/Coodinates';

// function to to find corodinates in the range of USA only
export function geoInbounds(point: Coordinates) {
  // Bounds of USA  used by search : https://gist.github.com/jsundram/1251783,
  const top = 49.382808; //# north lat
  const left = -124.7844079; //# west long
  const right = -66.9513812; //# east long
  const bottom = 24.7433195; //# south lat

  var eastBound = point.lon < right;
  var westBound = point.lon > left;
  var inLong;

  if (right < left) {
    inLong = eastBound || westBound;
  } else {
    inLong = eastBound && westBound;
  }

  var inLat = point.lat > bottom && point.lat < top;
  return inLat && inLong;
}
