import { XMarkIcon } from '@heroicons/react/24/solid';
import providerAvatar from 'assets/images/providerAvatar.png';
import CircleComponent from 'components/Map/CircleComponent';
import Map from 'components/Map/Map';
import { constant } from 'constants/common';
import useAppLanguage from 'hooks/useAppLanguage';

import { ReactComponent as MapPin } from 'assets/images/map-pin.svg';
import programTypeIcon from 'assets/images/programType.svg';
import licenceIcon from 'assets/images/licence.svg';
import backgroundCheckIcon from 'assets/images/backgroundCheck.svg';
import childIcon from 'assets/images/child.svg';
import hoursOperationIcon from 'assets/images/hoursOperation.svg';
import daysIcon from 'assets/images/days.svg';
import payIcon from 'assets/images/pay.svg';
import mealsIcon from 'assets/images/meals.svg';
import snackIcon from 'assets/images/snack.svg';
import pottyIcon from 'assets/images/potty.svg';
import subsidizedIcon from 'assets/images/subsidized.svg';
import { stringTransform } from 'utils/transform';
import { useEffect, useState } from 'react';
import { time } from 'utils/time';
import { useAppSelector } from 'hooks/reduxHook';
import { Coordinates } from 'api/dataObjects/subObjects/Coodinates';
import getCostRange from 'utils/getCCCost';

import './styles.css'; // Import your Tailwind CSS styles
import getDistanceBetweenCoordinates from 'utils/distanceBetweenCoordinates';

interface FilterModalProps {
  onClose: Function;
  isOpen: boolean;
  data?: any;
}
const MapInfoProvider: React.FC<FilterModalProps> = ({ onClose, isOpen, data }) => {
  const [showMore, setShowMore] = useState<boolean>(false);
  const [bounceTimeOut, setBounceTimeOut] = useState<boolean>(false);
  const searchAddressCoordinates: Coordinates = useAppSelector((state) => state?.geo?.searchAddressCoordinates);
  const isMobile = window.innerWidth <= 768;
  const { translate } = useAppLanguage();

  const translateMultipleValues = (valueToTranslate: string) => {
    return valueToTranslate
      .split(',')
      .map((item) => ' ' + translate(item))
      .toString();
  };

  useEffect(() => {
    setShowMore(false);
    const timer = setTimeout(() => {
      setBounceTimeOut(true);
    }, 1000);
    return () => {
      clearTimeout(timer);
      setBounceTimeOut(false);
    };
  }, [data]);

  const showMoreFun = () => {
    setBounceTimeOut(true);
    setShowMore(!showMore);
  };
  return (
    <div
      className={`w-full z-10 fixed bottom-20 sm:bottom-12 right-0 sm:w-96 sm:flex sm:flex-col border rounded-xl ${
        !showMore ? 'bg-white ' : ''
      }`}
    >
      <div
        className={`w-full z-10 bottom-20 sm:bottom-16 right-0 transform ${
          isOpen ? 'translate-y-0' : 'translate-y-full'
        } transition-transform ease-in-out duration-300 sm:w-96  sm:flex sm:flex-col  ${
          bounceTimeOut ? '' : 'animate-bounce-once '
        } `}
        onMouseEnter={() => {
          !showMore && setBounceTimeOut(false);

          setTimeout(() => {
            setBounceTimeOut(true);
          }, 1000);
        }}
        onMouseLeave={() => {
          !showMore && setBounceTimeOut(true);
        }}
      >
        <div
          className={`relative flex ${
            showMore ? '' : 'bottom-0'
          } flex-col flex-1 w-full bg-white focus:outline-none rounded-t-2xl shadow-top text-start ${
            isMobile ? 't-2 max-h-80vh overflow-scroll' : ''
          }`}
        >
          {/* Slide-over header */}
          <div className="px-4 py-2 bg-white-500 flex flex-row justify-end" onClick={showMoreFun}>
            <XMarkIcon className="h-6 w-6 text-tail-text cursor-pointer" onClick={() => onClose()} />
          </div>

          {/* Slide-over body */}
          <div className="flex gap-2 px-3 mb-6 cursor-pointer" onClick={showMoreFun}>
            <div>
              {data?.image_url && (
                <img
                  className="inline-block h-22 w-22 rounded-full"
                  src={data?.image_url || providerAvatar}
                  alt="Provider"
                  width={'88px'}
                  height={'88px'}
                />
              )}
            </div>
            <div>
              <div className="text-xl">{data?.name}</div>
              <div className="text-xs text-tail-gray-550">
                {Math.round(
                  data?.sort
                    ? data?.sort[0] * 10
                    : getDistanceBetweenCoordinates(searchAddressCoordinates, data?.coordinates)
                ) / 10}{' '}
                {translate('miles away')}
              </div>

              <div className="text-sm pt-2 text-tail-gray-550">
                <div>
                  {data?.program_type === 'fcc'
                    ? 'Family child care'
                    : stringTransform.capitalizeFirst(data?.program_type)}{' '}
                  |
                  {time
                    .ageRangeServed(data?.age_months_min)
                    .replace('months', translate('months'))
                    .replace('years', translate('years'))}{' '}
                  -
                  {time
                    .ageRangeServed(data?.age_months_max)
                    .replace('months', translate('months'))
                    .replace('years', translate('years'))}
                </div>
                <div>
                  <span>{getCostRange(data?.schedules)} </span>
                  <span className=" font-bold">
                    {getCostRange(data?.schedules) !== '' && data?.subsidized_care_accepted && ' | '}
                    {data?.subsidized_care_accepted ? `${translate('Accepts Subsidies')}` : ''}
                  </span>
                </div>
              </div>
            </div>
          </div>
          {showMore && (
            <div className={`px-4 mt-2`}>
              <div className={`${isMobile ? '' : 'max-h-70vh overflow-scroll'}`}>
                <div className="text-xl">{translate('Location')}</div>
                <div className="mt-5 text-base flex gap-3 mb-3">
                  <MapPin />
                  <div>
                    {' '}
                    {`${data?.location_city || ''}, ${data?.location_state || ''}
                            ${data?.location_postal_code || ''}`}
                  </div>
                </div>
                <div>
                  <Map
                    center={{ lat: data?.coordinates?.lat, lng: data?.coordinates?.lon } ?? constant.centerLatLng}
                    zoom={1}
                    markerPosition={{ lat: data?.coordinates?.lat, lng: data?.coordinates?.lon }}
                    onBoundsChanged={() => console.log('bound change mapInfoComponent')}
                    height={120}
                    circleCenter={{ lat: data?.coordinates?.lat, lng: data?.coordinates?.lon }}
                    circleRadius={100}
                  >
                    <CircleComponent
                      circleCenter={{ lat: data?.coordinates?.lat, lng: data?.coordinates?.lon }}
                      circleRadius={100}
                    />
                  </Map>
                </div>
                <div>
                  <div className="flex p-4 ">
                    <div className="flex w-full">
                      <div>
                        <img src={programTypeIcon} alt="" />
                      </div>
                      <div className="ml-2">
                        <div className="text-base font-bold text-tail-gray-550">{translate('Program type')}</div>
                        <div className="text-xs text-tail-text">
                          {data?.program_type === 'fcc'
                            ? 'Family child care'
                            : stringTransform.capitalizeFirst(data?.program_type)}
                        </div>
                      </div>
                    </div>
                    {data?.license_number && (
                      <div className="w-full flex">
                        <div>
                          <img src={licenceIcon} alt="" />
                        </div>
                        <div className="ml-2">
                          <div className="text-base font-bold text-tail-gray-550">{translate('License Number')}</div>
                          <div className="text-xs text-tail-text">{data?.license_number || '-'}</div>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="bg-gray-50 p-4 rounded-xl">
                    <div className="grid grid-cols-2 py-4 gap-4">
                      <div className="flex w-full">
                        <div>
                          <img src={childIcon} alt="" />
                        </div>
                        <div className="ml-2">
                          <div className="text-base font-bold text-tail-gray-550">{translate('Child Age Range')}</div>
                          <div className="text-xs text-tail-text">
                            {time
                              .ageRangeServed(data?.age_months_min)
                              .replace('months', translate('months'))
                              .replace('years', translate('years'))}{' '}
                            -
                            {time
                              .ageRangeServed(data?.age_months_max)
                              .replace('months', translate('months'))
                              .replace('years', translate('years'))}
                          </div>
                        </div>
                      </div>
                      <div className="w-full flex">
                        <div>
                          <img src={hoursOperationIcon} alt="" />
                        </div>
                        <div className="ml-2">
                          <div className="text-base font-bold text-tail-gray-550">
                            {translate('Hours of Operation')}
                          </div>
                          <div className="text-xs text-tail-text">
                            {`${time.convertToAMPM(data?.hours_start || 'NA')} - ${time.convertToAMPM(
                              data?.hours_end
                            )}`}
                          </div>
                        </div>
                      </div>

                      {data?.days_open.length > 0 && (
                        <div className="flex w-full">
                          <div>
                            <img src={daysIcon} alt="" />
                          </div>
                          <div className="ml-2">
                            <div className="text-base font-bold text-tail-gray-550">{translate('Days')}</div>
                            <div className="text-xs text-tail-text">
                              {translateMultipleValues(stringTransform.capitalizeArrayFirst(data?.days_open, ','))}
                            </div>
                          </div>
                        </div>
                      )}
                      {data?.pay_schedules.length > 0 && (
                        <div className="w-full flex">
                          <div>
                            <img src={payIcon} alt="" />
                          </div>
                          <div className="ml-2">
                            <div className="text-base font-bold text-tail-gray-550">{translate('Pay Schedules')}</div>
                            <div className="text-xs text-tail-text">
                              {data?.pay_schedules &&
                                translateMultipleValues(stringTransform.capitalizeArrayFirst(data?.pay_schedules, ','))}
                            </div>
                          </div>
                        </div>
                      )}

                      {data?.meals_provided_per_day.length > 0 && (
                        <div className="flex w-full">
                          <div>
                            <img src={mealsIcon} alt="" />
                          </div>
                          <div className="ml-2">
                            <div className="text-base font-bold text-tail-gray-550">{translate('Meals Provided')}</div>
                            <div className="text-xs text-tail-text">
                              {data?.meals_provided_per_day &&
                                translateMultipleValues(
                                  stringTransform.capitalizeArrayFirst(data?.meals_provided_per_day, ',')
                                )}
                            </div>
                          </div>
                        </div>
                      )}
                      {data?.snacks_provided_per_day && (
                        <div className="w-full flex">
                          <div>
                            <img src={snackIcon} alt="" />
                          </div>
                          <div className="ml-2">
                            <div className="text-base font-bold text-tail-gray-550">{translate('Snacks Provided')}</div>
                            <div className="text-xs text-tail-text">
                              {`${data?.snacks_provided_per_day} ` ?? '-'} {translate('per day')}
                            </div>
                          </div>
                        </div>
                      )}

                      {data?.potty_training_required && (
                        <div className="flex w-full">
                          <div>
                            <img src={pottyIcon} alt="" />
                          </div>
                          <div className="ml-2">
                            <div className="text-base font-bold text-tail-gray-550">{translate('Potty Training')}</div>
                            <div className="text-xs text-tail-text">
                              {data?.potty_training_required ?? '-'}
                              {translate('per day')}
                            </div>
                          </div>
                        </div>
                      )}
                      {data?.spoken_languages && (
                        <div className="w-full flex">
                          <div>
                            <img src={backgroundCheckIcon} alt="" />
                          </div>
                          <div className="ml-2">
                            <div className="text-base font-bold text-tail-gray-550">
                              {translate('Supported Language(s)')}
                            </div>
                            <div className="text-xs text-tail-text">
                              {data?.spoken_languages &&
                                translateMultipleValues(
                                  stringTransform.capitalizeArrayFirst(data?.spoken_languages, ',')
                                )}
                            </div>
                          </div>
                        </div>
                      )}

                      <div className="flex w-full">
                        <div>
                          <img src={subsidizedIcon} alt="" />
                        </div>
                        <div className="ml-2">
                          <div className="text-base font-bold text-tail-gray-550">{translate('Subsidized Care')}</div>
                          <div className="text-xs text-tail-text">
                            {translate(`${data.subsidized_care_accepted ? 'Accepted' : 'Not accepted'}`)}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* Slide-over footer */}
        </div>
      </div>
    </div>
  );
};

export default MapInfoProvider;
