// users.service.ts
import http from './httpService/http';
import { paths } from './paths';
import { Child } from './dataObjects/Child';
import { instanceToPlain, plainToInstance } from 'class-transformer';

const childrenPath = paths.byApplication.children;

export const fetchChildren = async (appId: string): Promise<Child[]> => {
  const response = await http.get<object, any>(childrenPath(appId));
  return plainToInstance(Child, response.data as []) as Child[];
};

export const createChildren = async (appId: string, childs: Child[]): Promise<Child[]> => {
  const childArr: Child[] = [];
  childs.forEach((child) => childArr.push(new Child(child)));
  const objArr = instanceToPlain(childArr);
  const response = await http.post<object, any>(childrenPath(appId), objArr as object[]);
  return plainToInstance(Child, response.data as []) as Child[];
};

export const updateChildren = async (appId: string, childs: Child[]): Promise<Child[]> => {
  const childArr: Child[] = [];
  childs.forEach((child) => childArr.push(new Child(child)));
  const objArr = instanceToPlain(childArr);
  const response = await http.put<object, any>(childrenPath(appId), objArr as object[]);
  return plainToInstance(Child, response.data as []) as Child[];
};

//export const deleteAdults = async (appId: string, user: Adult[]): Promise<Adult> => {
//  return await http.delete<Adult[], any>(adultPath(appId));
//};
